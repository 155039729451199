import { getAxiosClientWithToken } from './index';

export const getScheduledMeetings = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/company/scheduled/meetings');
};

export const getScheduledMeetingEvaluations = (type: number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/company/scheduled/meetings/evaluations/' + type);
};

export const addEvaluationMeeting = (evaluatedItems: FormData) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/company/scheduled/meetings/evaluations', evaluatedItems, {
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
};
