import React from 'react';
import { Button, Col, Row, Tag } from 'antd';
import moment from 'moment';
import { Link } from "react-router-dom";

export const ApplicationType = {
    FUND: 1,
    POLICY: 2,
};

type Props = {
    title: string,
    date: string,
    uuid: string,
    type: number,
}
const ReadNotification = (props: Props) => {
    const { title, date, uuid, type } = props;
    const formatDate = (toFormatDate: string) => {
        return moment(toFormatDate).format('YYYY/MM/DD');
    };
    const redirectionLink = type === ApplicationType.FUND ? `/dashboard/kouteki/consultation/${uuid}/details` : `/dashboard/working-regulation/meetings/${uuid}`;
    return (
        <>
            <div className='notification-container notification-container_read'>
                <Row gutter={16}>
                    <Col className='gutter-row' md={3} xs={24}>
                        <Tag color='#006BB5'>{formatDate(date)}</Tag>
                    </Col>
                    <Col className='gutter-row' md={2} xs={24}>
                        <span className='notification-status-read'>既読</span>
                    </Col>
                    <Col className='gutter-row' md={15} xs={24}>
                        <div className='notification-title'>{title}</div>
                    </Col>
                    <Col className='gutter-row' md={4} xs={24}>
                        <Link to={redirectionLink}>
                            <Button className='notification-btn'>詳細</Button>
                        </Link>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default ReadNotification;
