import React, { useState } from 'react';
import { Button, Card, Col, Image, Row } from 'antd';
import { useAppDispatch } from 'app/hooks';
import ProfilePreviewModal from 'components/consultation/ProfilePreviewModal';
import {
    ApplicationDetails,
    getSpecialistProfile,
    Schedule,
    Specialist,
    SpecialistProfileData,
} from 'app/slice/applicationDetailsSlice';
import { ReviewDetails } from 'app/slice/meetingSlice';
import avatar from 'assets/images/avatardefault.png';
import ScheduleSelection from 'components/consultation/details/ScheduleSelection';

type Props = {
    application?: ApplicationDetails,
    hasProfileButton?: Boolean,
    meetingDetails?: React.ReactNode,
    schedules?: Schedule[],
    displaySpecialist?: Specialist,
    reviewData?: ReviewDetails
    isKoteki?: boolean
}

const SpecialistDetails = (props: Props) => {
    const {
        application,
        hasProfileButton = false,
        meetingDetails,
        schedules,
        displaySpecialist,
        reviewData,
        isKoteki,
    } = props;

    const specialist = displaySpecialist || application?.specialist || reviewData?.specialist || null;

    const [profileData, setProfileData] = useState({} as SpecialistProfileData);
    const dispatch = useAppDispatch();


    const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
    const onCloseProfileModalModal = () => setIsProfileModalVisible(false);
    const onOpenProfileModalModal = async () => {
        await setProfileData(await dispatch(getSpecialistProfile({
            uuid: application!.uuid,
            specialist_uuid: specialist!.uuid,
        })).unwrap() as SpecialistProfileData);

        await setIsProfileModalVisible(true);
    };

    return (
        <>
            <div className='consultation-specialist-card'>
                <Card>
                    {(specialist) ? (
                        <Row gutter={16}>
                            <Col className='text-center' md={3}>
                                <Image
                                    className='profile-image'
                                    src={specialist.profileImageUrl ?? avatar}
                                />
                            </Col>
                            {isKoteki ? (
                                <>
                                    <Col md={9}>
                                        <Row gutter={10}>
                                            <Col className='label-text' md={7}>専門家氏名</Col>
                                            <Col md={17}>{specialist.name}</Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col className='label-text' md={7}>事務所名</Col>
                                            <Col md={17}>{specialist.officeName}</Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col className='label-text' md={7}>住所</Col>
                                            <Col md={17}>{specialist.address}</Col>
                                        </Row>
                                    </Col>
                                    <Col md={9}>
                                        {specialist.phone && (
                                            <Row gutter={10}>
                                                <Col className='label-text' md={7}>TEL</Col>
                                                <Col md={17}>{specialist.phone}</Col>
                                            </Row>
                                        )}
                                        {specialist.email && (
                                            <Row gutter={10}>
                                                <Col className='label-text' md={7}>メール</Col>
                                                <Col md={17}>{specialist.email}</Col>
                                            </Row>
                                        )}

                                    </Col>
                                    <Col md={3}>
                                        {hasProfileButton &&
                                            <Button className='edit-profile' type='primary'
                                                    onClick={onOpenProfileModalModal}
                                            >
                                                プロフィール<br />を確認
                                            </Button>
                                        }
                                    </Col>
                                </>
                            ) : (
                                <div>中企団社会保険労務士事務所</div>
                            )}
                        </Row>

                    ) : (
                        <Row gutter={16}>
                            <Col className='text-center' md={3}>
                                <Image
                                    className='profile-image'
                                    src={avatar}
                                />
                            </Col>
                            <Col className='label-text' md={9}>
                                中企団相談窓口
                            </Col>
                        </Row>
                    )}
                    {meetingDetails}
                    {schedules && <ScheduleSelection schedules={schedules} />}
                </Card>
            </div>

            <ProfilePreviewModal isVisible={isProfileModalVisible} onClose={onCloseProfileModalModal}
                                 profileData={profileData}
            />
        </>
    );
};

export default SpecialistDetails;
