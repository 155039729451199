import React from 'react';
import { Button, Card, Col, Collapse, Image, Row } from 'antd';
import { Schedule, Specialist } from 'app/slice/applicationDetailsSlice';
import { Link } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';

type Props = {
    specialists: Specialist[] | null
}

const SpecialistProfiles = (props: Props) => {
    const { specialists } = props;
    const { Panel } = Collapse;

    const profileHeader = (specialist: Specialist) => {
        return (
            <div>
                <Row gutter={16} align='middle'>
                    <Col md={3}>
                        <Image
                            className='profile-image'
                            src={specialist.profileImageUrl}
                        />
                    </Col>
                    <Col md={13}>
                        <Row gutter={10}>
                            <Col className='label-text' md={7} xs={8}>社労士</Col>
                            <Col md={17} xs={16}>{specialist.name}</Col>
                        </Row>
                        <Row gutter={10}>
                            <Col className='label-text' md={7} xs={8}>事務所名</Col>
                            <Col md={17} xs={16}>{specialist.officeName}</Col>
                        </Row>
                        <Row gutter={10}>
                            <Col className='label-text' md={7} xs={8}>住所</Col>
                            <Col md={17} xs={16}>{specialist.address}</Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }

    const profileSchedules = (schedule: Schedule, index: number) => {
        switch(index) {
            case 0:
                return <li key={index}><strong>第一希望：</strong>{schedule.content}</li>;
            case 1:
                return <li key={index}><strong>第二希望：</strong>{schedule.content}</li>;
            case 2:
                return <li key={index}><strong>第三希望：</strong>{schedule.content}</li>;
            case 3:
                return <li key={index}><strong>第四希望：</strong>{schedule.content}</li>;
            case 4:
                return <li key={index}><strong>第五希望：</strong>{schedule.content}</li>;
            default:
                return '';
        }
    }

    const actionButtons = (uuid: string) => {
        return (
            <div className='profile-card-buttons'>
                <Button danger>非表示</Button>
                <Link to={`${uuid}/schedule`}>
                    <Button>詳細・申込</Button>
                </Link>
            </div>
        );
    }

    return (
        <div className='consultation-specialist-card'>
            {specialists?.map((specialist: Specialist) => (
                <Card className='profile-card' key={specialist.uuid}>
                    <Collapse defaultActiveKey={['1']} expandIconPosition={'right'} ghost
                              expandIcon={({isActive}) => isActive ? actionButtons(specialist.uuid) : <DownOutlined/>}>
                        <Panel header={profileHeader(specialist)} key='1'>
                            <hr/>
                            <span className='profile-card-title'>日程</span>
                            <div className='profile-card-selection'>
                                <Row>
                                    <Col span={24}>
                                        <ul>
                                            {specialist?.schedules?.map((schedule: Schedule, index) => {
                                                return profileSchedules(schedule, index);
                                            })}
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </Panel>
                    </Collapse>
                </Card>
            ))}
        </div>
    );

}

export default SpecialistProfiles;
