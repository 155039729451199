import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Radio, Row, Select, Spin } from 'antd';
import { useAppSelector } from 'app/hooks';
import { selectProfile, UPDATE_PROFILE } from 'app/slice/profileSlice';
import { ProfileFieldData, Rules } from 'types';
import ChangePasswordModal from 'components/dashboard/ChangePasswordModal';
import { selectResources } from 'app/slice/resourcesSlice';
import { flatten, values } from 'lodash';
import { RuleObject } from 'antd/lib/form';
import { scrollToTop } from 'lib/utils';
import ValidationErrorModal from 'components/common/ValidationErrorModal';

type Props = {
    onCancel: () => void,
    onFinish: (values: any) => void
}

const inputRules: Rules = {
    companyName: [
        { required: true, whitespace: true, message: '企業名をご入力ください。' },
        { max: 100, message: '100文字以内ご入力ください。' },
    ],
    companyNameFurigana: [
        { required: true, whitespace: true, message: '企業名フリガナをご入力ください。' },
        { pattern: new RegExp(/^([\sァ-ヶー]+)$/), message: '企業名を全角フリガナでご入力ください。' },
        { max: 200, message: '200文字以内ご入力ください。' },
    ],
    companyRepresentativePosition: [
        { whitespace: true, message: '担当者を入力してください。' },
        { max: 50, message: '50文字以内ご入力ください。' },
    ],
    companyBuildingName: [
        { whitespace: true, message: '建物名を入力してください。' },
        { max: 200, message: '200文字以内ご入力ください。' },
    ],
    email: [
        { required: true, message: 'メールアドレスをご入力ください。' },
        { type: 'email', message: ' 有効なメールアドレスを入力してください。' },
    ],
    lastName: [
        { required: true, whitespace: true, message: '担当者さま姓をご入力ください。' },
        { max: 50, message: '50文字以内ご入力ください。' },
    ],
    firstName: [
        { required: true, whitespace: true, message: '担当者さま名をご入力ください。' },
        { max: 50, message: '50文字以内ご入力ください。' },
    ],
    lastNameFurigana: [
        { required: true, whitespace: true, message: '担当者さまフリガナ（セイ）をご入力ください。' },
        { pattern: new RegExp(/^([\sァ-ヶー]+)$/), message: '担当者さま（セイ）を全角フリガナでご入力ください。' },
        { max: 100, message: '100文字以内ご入力ください。' },
    ],
    firstNameFurigana: [
        { required: true, whitespace: true, message: '担当者さまフリガナ（メイ）をご入力ください。' },
        { pattern: new RegExp(/^([\sァ-ヶー]+)$/), message: '担当者さま（メイ）を全角フリガナでご入力ください。' },
        { max: 100, message: '100文字以内ご入力ください。' },
    ],
    tel: [
        { required: true, whitespace: true, message: 'TELをご入力ください。' },
        { pattern: new RegExp(/^[0-9]{1,3}[-\s/0-9]*$/g), message: '形式が間違っています。' },
    ],
    postalCode: [
        { required: true, whitespace: true, message: '郵便番号をご入力ください。' },
        { pattern: new RegExp(/^[0-9]{3}[-\s]?[0-9]{4}/g), message: '形式が間違っています。' },
    ],
    prefectureCode: [{ required: true, message: '都道府県をご入力ください。' }],
    companyAddress: [
        { required: true, whitespace: true, message: '住所をご入力ください。' },
        { max: 200, message: '200文字以内ご入力ください。' },
    ],
    industryCode: [{ required: true, message: '業種をご入力ください。' }],
    parttimeEmployeeCount: [
        { required: true, message: 'うち、パート／アルバイト数をご入力ください。' },
        { pattern: new RegExp(/^[0-9]+$/), message: '数字を入力してください。' },
    ],
    employeeCountRangeCode: [{ required: true, message: '従業員s数をご入力ください。' }],
    laborRegulationsCompliant: [{ required: true, message: '就業規則の有無をご入力ください。' }],
    allowSharoushi: [{ required: true, message: '顧問社労士の有無をご入力ください。' }],
    allowShindanshi: [{ required: true, message: '顧問診断士の有無をご入力ください。' }],
};

const DashboardEditProfile = (props: Props) => {
    const { onCancel, onFinish } = props;
    const [form] = Form.useForm();
    const profile = useAppSelector(selectProfile);
    const {
        prefectures,
        industries,
        insurancePlans,
        employeeCountRanges,
    } = useAppSelector(selectResources);

    const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
    const onCloseChangePasswordModal = () => setIsChangePasswordVisible(false);
    const onOpenChangePasswordModal = () => setIsChangePasswordVisible(true);
    const [emailError, setEmailError] = useState(false);
    const changeEmailRules = () => {
        const ruleValues = flatten(values(inputRules.email)) as RuleObject[];
        if (form.getFieldValue(['user[email]'])?.match(/[\u0080-\uffff]/g)) {
            let typeRuleIndex = ruleValues.findIndex(item => item.pattern);
            if (typeRuleIndex !== 0) {
                inputRules.email.splice(typeRuleIndex, 1);
            }
            inputRules.email.push({
                pattern: new RegExp(/^[\\xFF01-\\xFF5E0-9._%+-@]+$/i), message: ' メールアドレスを半角でご入力ください。'
            });
        } else {
            let typeRuleIndex = ruleValues.findIndex(item => item.type === 'email');
            if (typeRuleIndex !== 0) {
                inputRules.email.splice(typeRuleIndex, 1);
            }
            inputRules.email.push({
                type: 'email', message: ' 有効なメールアドレスを入力してください。'
            })
        }
    }
    
    const resetEmailError = () => {
        setEmailError(false);
        changeEmailRules();
    };
    const [isValidationErrorModalVisible, setIsValidationErrorModalVisible] = useState(false);
    const [hasRequiredError, setHasRequiredError] = useState(false);
    const [hasPatternError, setHasPatternError] = useState(false);

    const onOpenValidationErrorModal = () => setIsValidationErrorModalVisible(true);
    const onCloseValidationErrorModal = () => setIsValidationErrorModalVisible(false);

    const onFinishFailed = () => {
        let requiredError = false;
        let patternError = false;

        const errorFields = form.getFieldsError();
        const ruleValues = flatten(values(inputRules)) as RuleObject[];
        const requiredMessages = ruleValues.filter(rule => rule.required).map(rule => rule.message);
        const patternMessages = ruleValues.filter(rule => rule.pattern).map(rule => rule.message);

        for (let errorField of errorFields) {
            for (let error of errorField.errors) {
                if (requiredMessages.includes(error)) {
                    requiredError = true;
                } else if (patternMessages.includes(error)) {
                    patternError = true;
                }
            }
        }

        scrollToTop();
        setHasRequiredError(requiredError);
        setHasPatternError(patternError);
        onOpenValidationErrorModal();
    };

    useEffect(() => {
        if (!profile?.success && !profile?.loading && profile.errors.hasOwnProperty('user.email')) {
            //can also be used for other fields but for now email is the only one needed for fetching
            setEmailError(true);
        }
    }, [profile?.errors, profile?.success, profile?.loading]);

    useEffect(() => {
        form.validateFields(['user[email]']);
    }, [emailError, form]);

    const [fields, setFields] = useState<ProfileFieldData>({
        'company[name]': profile.data.company.name,
        'company[name_furigana]': profile.data.company.nameFurigana,
        'company[representative_position]': profile.data.company.representativePosition,
        'profile[lastname]': profile.data.profile.lastname,
        'profile[firstname]': profile.data.profile.firstname,
        'profile[lastname_furigana]': profile.data.profile.lastnameFurigana,
        'profile[firstname_furigana]': profile.data.profile.firstnameFurigana,
        'profile[phone]': profile.data.profile.phone,
        'user[email]': profile.data.user.email,
        'company[postal_code]': profile.data.company.postalCode,
        'company[prefecture_code]': profile.data.company.prefectureCode,
        'company[address]': profile.data.company.address,
        'company[building_name]': profile.data.company.buildingName,
        'company[industry_code]': profile.data.company.industryCode,
        'company[parttime_employee_count]': profile.data.company.parttimeEmployeeCount,
        'company[employee_count_range_code]': profile.data.company.employeeCountRangeCode,
        'insurance_plans[]': profile.data.insurancePlans.map(insurance => insurance.code),
        'company[labor_regulations_compliant]': profile.data.company.laborRegulationsCompliant,
        'company[allow_sharoushi]': profile.data.company.allowSharoushi,
        'company[allow_shindanshi]': profile.data.company.allowShindanshi,
    });

    const isLoading = () => profile.type === UPDATE_PROFILE && profile.loading;

    const emailValidation = () => {
        if (emailError) {
            return Promise.reject('すでに使用済みのメールアドレスです。');
        }
        return Promise.resolve();
    };

    return (
        <>
            <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={fields}
                onValuesChange={(_, allFields) => setFields(allFields)}
                autoComplete='off'
                className='m-15px w-auto'
            >
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name='company[name]'
                            label='企業名'
                            rules={inputRules.companyName}
                            extra='※100文字以内'
                        >
                            <Input placeholder='かんぱに株式会社' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name='company[name_furigana]'
                            label='企業名フリガナ（全角）'
                            rules={inputRules.companyNameFurigana}
                            extra='※200文字以内'
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name='company[representative_position]'
                            label='担当者さま役職'
                            rules={inputRules.companyRepresentativePosition}
                            extra='※50文字以内'
                        >
                            <Input placeholder='総務課長' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} />
                    <Col xs={24} md={12}>
                        <Row gutter={[16, { xs: 16, md: 0 }]}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='profile[lastname]'
                                    label='担当者さま'
                                    rules={inputRules.lastName}
                                    extra='※50文字以内'
                                >
                                    <Input placeholder='姓' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='profile[firstname]'
                                    label=' '
                                    required={false}
                                    rules={inputRules.firstName}
                                >
                                    <Input placeholder='名' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} md={12}>
                        <Row gutter={[16, { xs: 16, md: 0 }]}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='profile[lastname_furigana]'
                                    label='担当者さまフリガナ（全角）'
                                    rules={inputRules.lastNameFurigana}
                                    extra='※100文字以内'
                                >
                                    <Input placeholder='セイ' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    name='profile[firstname_furigana]'
                                    label=' '
                                    required={false}
                                    rules={inputRules.firstNameFurigana}
                                >
                                    <Input placeholder='メイ' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name='profile[phone]'
                            label='TEL'
                            rules={inputRules.tel}
                            extra='※「000-000-0000」の形式（半角英数字、半角ハイフン）'
                        >
                            <Input placeholder='038-567-2345' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name='user[email]'
                            label='メールアドレス'
                            rules={[
                                ...inputRules.email,
                                { validator: emailValidation },
                            ]}
                            extra='※ひとつ目の文字はアルファベットまたは数字のみ'
                        >
                            <Input onChange={resetEmailError} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name='company[postal_code]'
                            label='〒'
                            rules={inputRules.postalCode}
                            extra='※「000-0000」の形式（半角英数字、半角ハイフン）'
                        >
                            <Input placeholder='900-0002' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name='company[prefecture_code]'
                            label='都道府県'
                            rules={inputRules.prefectureCode}
                        >
                            <Select placeholder='都道府県'>
                                {prefectures.map((row) => (
                                    <Select.Option key={row.code}
                                                   value={row.code}
                                    >{row.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name='company[address]'
                            label='住所'
                            rules={inputRules.companyAddress}
                            extra='※200文字以内'
                        >
                            <Input placeholder='住所' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name='company[building_name]'
                            label='ビル名'
                            rules={inputRules.companyBuildingName}
                            extra='※200文字以内'
                        >
                            <Input placeholder='かんぱにビル' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name='company[employee_count_range_code]'
                            label='従業員数'
                            rules={inputRules.employeeCountRangeCode}
                        >
                            <Select placeholder='1人-100人'>
                                {employeeCountRanges.map((row) => (
                                    <Select.Option key={row.code} value={row.code}>{row.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name='company[parttime_employee_count]'
                            label='うち、パート／アルバイト数'
                            rules={inputRules.parttimeEmployeeCount}
                            extra='0人の場合、0を入力してください。'
                        >
                            <Input placeholder='15' />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name='insurance_plans[]'
                            label='社会保険加入状況'
                            required
                        >
                            <Checkbox.Group>
                                <Row gutter={[10, 10]}>
                                    {insurancePlans.map((row) => (
                                        <Col span={24} key={row.code}>
                                            <Checkbox value={row.code}>{row.name}</Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                        <Form.Item
                            name='company[industry_code]'
                            label='業種'
                            rules={inputRules.industryCode}
                        >
                            <Select placeholder='農林漁業'>
                                {industries.map((row) => (
                                    <Select.Option key={row.code} value={row.code}>{row.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name='company[labor_regulations_compliant]'
                            label='就業規則の有無'
                            rules={inputRules.laborRegulationsCompliant}
                        >
                            <Radio.Group>
                                <Radio value={1}>有</Radio>
                                <Radio value={0}>無</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name='company[allow_sharoushi]'
                            label='顧問社労士の有無'
                            rules={inputRules.allowSharoushi}
                        >
                            <Radio.Group>
                                <Radio value={1}>有</Radio>
                                <Radio value={0}>無</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name='company[allow_shindanshi]'
                            label='顧問診断士の有無'
                            rules={inputRules.allowShindanshi}
                        >
                            <Radio.Group>
                                <Radio value={1}>有</Radio>
                                <Radio value={0}>無</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <div onClick={onOpenChangePasswordModal} className='change-password'>パスワードを変更する</div>

                <hr className='profile-separator' />
                <div className='my-30px mx-0'>
                    <div className='title text-start'>大同生命営業担当者情報</div>
                    <div className='subtitle text-start'>
                        <span className='me-4'>{profile.data?.company?.daidoBranchName}</span>
                        <span className='me-4'>{profile.data?.company?.daidoDepartmentName}</span>
                        <span>{profile.data?.company?.daidoEmployeeName}</span>
                    </div>
                </div>

                <Row gutter={[12, 12]} className='justify-content-center'>
                    <Col>
                        <Button className='w-200px' onClick={onCancel}
                                disabled={isLoading()}
                        >
                            キャンセル
                        </Button>
                    </Col>
                    <Col>
                        <Button type='primary' htmlType='submit' className='w-200px'
                                disabled={isLoading()}
                        >
                            {isLoading() ? <Spin size='small' /> : '保存'}
                        </Button></Col>
                </Row>
            </Form>
            <ChangePasswordModal isVisible={isChangePasswordVisible} onClose={onCloseChangePasswordModal} />
            <ValidationErrorModal isVisible={isValidationErrorModalVisible}
                                  onClose={onCloseValidationErrorModal}
                                  hasRequiredError={hasRequiredError}
                                  hasPatternError={hasPatternError}
            />
        </>
    );
};

export default DashboardEditProfile;
