import React, { useEffect } from 'react';
import { Button, Card, Form, Input } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    ApplicationDetails,
    Schedule,
    ScheduleConsultationRequest,
    setApplicationSchedule,
    SET_APPLICATION_SCHEDULE,
    selectApplicationDetails,
    Specialist,
} from 'app/slice/applicationDetailsSlice';
import SpecialistDetails from 'components/consultation/SpecialistDetails';
import ConsultationChecklists from 'components/consultation/details/ConsultationChecklists';
import { Link, useNavigate } from 'react-router-dom';

type Props = {
    application: ApplicationDetails,
    specialist: Specialist,
}

const rules = {
    emergencyContactNumber: [
        { required: true, message: '当日の緊急連絡先（電話番号）をご入力ください' },
        { pattern: new RegExp(/^[0-9]{1,3}[-\s/0-9]*$/g), message: '形式が間違っています。' }
    ],
};

const SpecialistSchedule = (props: Props) => {
    const { application, specialist } = props;
    const [form] = Form.useForm();
    const { type, loading } = useAppSelector(selectApplicationDetails);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onSubmit = (values: { schedule: Schedule, daidoJoins: boolean, emergencyContactNumber: string }) => {
        dispatch(setApplicationSchedule({
            uuid: application.uuid,
            specialist_uuid: specialist.uuid,
            number: values.schedule.number,
            daido_joins: values.daidoJoins,
            emergency_contact_number: values.emergencyContactNumber,
        } as ScheduleConsultationRequest));
    };

    const fundTitles = () => {
        return (
            <Card className='consultation-fund-info'>
                <div className='consultation-fund-info-label'><strong>相談する公的資金</strong></div>
                {application.funds?.map((row) => (
                    <div key={row.code} className='Consultation-fund-info-title'>{row.title}</div>
                ))}
            </Card>
        );
    };

    // Scroll to top on navigate
    useEffect(() => {
        scrollToTop();
    }, [navigate]);

    /**
     * Function to scroll back to the top of the screen.
     */
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
            <Form
                form={form}
                onFinish={onSubmit}
                onFinishFailed={scrollToTop}
                layout='vertical'
                autoComplete='off'
            >
                <SpecialistDetails
                    application={application}
                    hasProfileButton={true}
                    schedules={specialist.schedules!}
                    displaySpecialist={specialist}
                    isKoteki={true}
                />

                {fundTitles()}
                <div className='consultation-select-note'>
                    <p>※本依頼内容は、面談の管理のため、同席の希望状況に関わらず大同生命営業担当者に共有されます。あらかじめご了承ください。</p>
                </div>

                <ConsultationChecklists application={application}/>
                <div>
                    <Form.Item
                        className='w-350px'
                        name='emergencyContactNumber'
                        label='当日の緊急連絡先（電話番号）をご入力ください（必須）'
                        rules={rules.emergencyContactNumber}
                    >
                        <Input/>
                    </Form.Item>
                </div>
                <div className='text-center consultation-select-schedule-controls mt-30px'>
                    <Link to={`/dashboard/kouteki/consultation/${application.uuid}/details`}>
                        <Button>戻る</Button>
                    </Link>
                    <Button type='primary' htmlType='submit' loading={loading && type === SET_APPLICATION_SCHEDULE}>
                        依頼する
                    </Button>
                </div>

                <div className='text-center my-3 helper-text'>
                    ご依頼の内容は大同生命の営業担当者にも連携されます
                </div>
            </Form>
        </>

    );
};

export default SpecialistSchedule;
