import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, Card, Col, Form, Row, Space, Tooltip } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    FundApplicationRequest,
    getApplicationStatus,
    postApplication,
    resetApplication,
    selectFundApplication,
} from 'app/slice/fundApplicationSlice';
import { setSuccessState, SuccessModalState } from 'app/slice/successSlice';
import checkIcon from 'assets/images/success_check.png';
import errorIcon from 'assets/images/error.png';
import { every, filter, pickBy, pull, some } from 'lodash';
import MatchingLoading from 'components/kouteki/MatchingLoading';
import MatchFailed from 'components/kouteki/MatchFailed';
import { selectAuth } from 'app/slice/authSlice';
import DiagnoseResultValid from 'components/kouteki/DiagnoseResultValid';
import { selectFundsList } from 'app/slice/fundsListSlice';
import CustomModal from 'components/common/CustomModal';
import { InfoCircleOutlined } from '@ant-design/icons';
import InstructionModal from 'components/kouteki/InstructionModal';

export type FundsLocationState = {
    funds: {
        code: string,
        title: string,
        allowConsultation: boolean,
        type: number,
        url: string,
        result: boolean
    }[]
}

const breadcrumbs: Breadcrumb[] = [
    {
        name: 'ホームページ',
        url: '/kouteki',
    },
    {
        name: '検索',
        url: '/kouteki/funds/search',
    },
    {
        name: '活用可能性を診断',
        url: '/kouteki/funds/diagnose',
    },
];

type Props = {
    onOpenLoginModal: () => void;
}

const DiagnoseResult = (props: Props) => {
    const { onOpenLoginModal } = props;
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { funds } = useLocation().state as FundsLocationState;
    const { params } = useAppSelector(selectFundsList);
    const { loading, success, matched, finished } = useAppSelector(selectFundApplication);
    const { token } = useAppSelector(selectAuth);
    const [selected, setSelected] = useState<string[]>([]);
    const [isMatchingLoading, setIsMatchingLoading] = useState(false);
    const [isMatchFailed, setIsMatchFailed] = useState(false);

    const onCloseMatchFailed = useCallback(() => setIsMatchFailed(false), []);

    const basicRequirementsChecked = funds.find(code => code.title === '基本確認項目')?.result ?? true;
    const fundsWithoutBasicRequirements = filter(funds, 'code');
    const isShowNote = some(fundsWithoutBasicRequirements, 'result');
    const isNoneChecked = every(fundsWithoutBasicRequirements, { result: false });

    const [isDiagnoseErrorVisible, setIsDiagnoseErrorVisible] = useState(false);
    const onCloseDiagnoseErrorModal = () => setIsDiagnoseErrorVisible(false);
    const onOpenDiagnoseErrorModal = () => setIsDiagnoseErrorVisible(true);

    const [isInstructionVisible, setIsInstructionVisible] = useState(false);
    const onCloseInstructionModal = () => setIsInstructionVisible(false);
    const onOpenInstructionModal = () => setIsInstructionVisible(true);

    const onSubmit = () => {
        if (token) {
            const values = form.getFieldsValue() as FundApplicationRequest;

            //Filter all fund records by selected funds
            const filteredFunds = Object.values(funds).filter(value => selected.includes(value.code));
            // Check if the selected funds have both grant and subsidy
            if (some(filteredFunds, data => data.type === 3) && some(filteredFunds, data => data.type === 4)) {
                onOpenDiagnoseErrorModal();
                return;
            }
            // Use value to only pass the code and the remarks
            const fundSelected = Object.values(values).filter(value => selected.includes(value.code));
            dispatch(postApplication(fundSelected));
        } else {
            onOpenLoginModal();
        }
    };

    useEffect(() => {
        if (!funds) {
            navigate('/kouteki', { replace: true });
        }
    }, [dispatch, navigate, funds]);

    useEffect(() => {
        if (success) {
            const checkStatus = async () => {
                let data = await dispatch(getApplicationStatus()).unwrap();
                if (data.finished === true) {
                    return;
                }
                setTimeout(checkStatus, 2000);
            };

            setIsMatchingLoading(true);
            checkStatus().then();
        }
    }, [dispatch, success]);

    useEffect(() => {
        if (finished === true) {
            setIsMatchingLoading(false);
            setIsInstructionVisible(false);

            if (matched === true) {
                const successModalPayload: SuccessModalState = {
                    isShow: true,
                    title: '専門家相談の受付が完了しました',
                    message: (
                        <>
                            <p>
                                専門家選定は本日を含め３営業日以内（土日・祝除く）に<br/>
                                結果が反映されます。期間中に対応可能な専門家が不在であった場合<br/>
                                は、サイト上に『中企団オンライン相談窓口※』の申込案内が表示さ<br/>
                                れます。併せてご利用ください。
                            </p>
                            <div className='helper-text'>※ 本サービスの提供元である中小企業福祉事業団が運営する相談窓口です。</div>
                        </>
                    ),
                    withButton: true,
                    image: checkIcon,
                    buttonTitle: '管理画面に戻る',
                    buttonLink: '/dashboard',
                };
                dispatch(setSuccessState(successModalPayload));
            } else {
                setIsMatchFailed(true);
            }

            dispatch(resetApplication());
        }
    }, [dispatch, finished, matched]);

    const returnToPrev = () => {
        navigate({
            pathname: '/kouteki/funds/search',
            search: '?' + createSearchParams(pickBy(params, param => param)),
        });
    };

    const addSelected = (code: string) => {
        let newSelected = selected;

        if (selected.includes(code)) {
            newSelected = pull(selected, code);
        } else {
            newSelected.push(code);
        }

        setSelected([...newSelected]);
    };

    return (
        <>
            <section className='breadcrumbs'>
                <Breadcrumbs breadcrumb={breadcrumbs} />
            </section>
            <section className='content diagnose-result-container'>
                <div className='title'>活用可能性を診断</div>
                <div>
                    {isNoneChecked && (
                        <h3><strong>貴社の現状では以下の公的資金を活用できる可能性がありません。</strong></h3>
                    )}
                </div>
                <Form form={form}
                      onFinish={onSubmit}
                      layout='vertical'
                      autoComplete='off'
                >
                    {funds.filter(fund => fund.title !== '基本確認項目').map((fund, key) => (
                        (basicRequirementsChecked && fund.result && fund.allowConsultation) ? (
                            <Fragment key={key}>
                                {isShowNote && (
                                    <p>
                                        <h3><strong style={{ 'textDecoration': 'underline' }}
                                        >貴社では以下の公的資金を活用できる可能性があります。</strong></h3>
                                        <span className='note'>
                                            ※本診断結果は簡易的なものとなります。具体的に申請要件を満たすかどうかは、専門家に確認するか、申請先窓口宛にご相談することをおすすめします。
                                        </span>
                                    </p>
                                )}
                                <DiagnoseResultValid index={key} fund={fund} addSelected={addSelected} />
                                <br/>
                            </Fragment>
                        ) : (
                            <Fragment key={key}>
                                {!isNoneChecked && (
                                    fund.allowConsultation || !fund.result ? (
                                        <div className='ms-3 mb-2'>
                                            <h3><strong style={{ 'textDecoration': 'underline' }}
                                            >貴社の現状では以下の公的資金を活用できる可能性がありません。</strong></h3>
                                        </div>
                                    ) : (
                                        <div className='ms-3 mb-2'>
                                            <h3><strong style={{ 'textDecoration': 'underline' }}
                                            >貴社の現状では以下の公的資金を活用できる可能性があります。</strong></h3>
                                            <span className='note'>
                                                ※本診断結果は簡易的なものとなります。具体的に申請要件を満たすかどうかは、専門家に確認するか、申請先窓口宛にご相談することをおすすめします。
                                            </span>
                                        </div>
                                    )
                                )}
                                <Card className='diagnose-list'>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} md={24} key={key}>
                                            <Alert
                                                type={fund.allowConsultation || !fund.result ? 'error' : 'info'}
                                                message={
                                                    <Row gutter={16} className='align-items-center'>
                                                        <Col md={18}>
                                                            {fund.title}
                                                        </Col>
                                                        <Col md={6}>
                                                            <a href={fund.url} target='_blank' rel='noreferrer'>
                                                                <Button type='primary'>参考ページ（外部リンク）</Button>
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                }
                                                showIcon={false}
                                                className='p-24px'
                                                banner
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Fragment>
                        )
                    ))}
                    <Space className='diagnose-buttons'>
                        <Button type='primary' onClick={onOpenInstructionModal} disabled={selected.length === 0}>
                            専門家相談へ進む
                            <Tooltip placement='top'
                                     title='専門家マッチングとは、相談に対応できる専門家をシステム上で検索することです。専門家とマッチングした場合、本日を含め3営業日（土日・祝除く）以内にシステムからメールが届きますので、相談する専門家を選択してください。'
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Button>
                        <Button type='default' disabled={loading} onClick={returnToPrev}
                        >他の助成金・補助金・融資を診断する</Button>
                    </Space>
                    <InstructionModal isVisible={isInstructionVisible} onClose={onCloseInstructionModal}
                                      onSubmit={onSubmit}
                    />
                </Form>
                <div>
                    <br/>
                    <h3><strong>■専門家に相談できる公的資金について</strong></h3>
                    <div className='consultation-text align-items-center ms-3'>
                        各公的資金名の右に
                        「相談可能」
                        のラベルが表示されています。
                    </div>
                    <span className='ms-3'>
                        相談を希望する場合には、チェックボックスにチェックを入れたうえで、上の「専門家マッチングを申し込む」ボタンを押してください（最大5件まで相談可能）。
                    </span>
                </div>
                <div style={{'marginTop': '15px'}}>
                    <h3><strong>■専門家に相談できない公的資金について</strong></h3>
                    <div className='external-link-text align-items-center ms-3'>
                        各公的資金名の右側に
                        「参考ページ（外部リンク）」
                        のボタンが表示されています。
                    </div>
                    <div className='external-link-text align-items-center ms-3'>
                        「参考ページ（外部リンク）」
                        のボタンをクリックして、申請先にご確認ください。
                    </div>
                    <br />
                </div>
            </section>

            <CustomModal
                isVisible={isDiagnoseErrorVisible}
                onClose={onCloseDiagnoseErrorModal}
                title='助成金と補助金は同時にご相談できないです。'
                icon={errorIcon}
                text=''
                showCancelButton={false}
                showConfirmButton={true}
                confirmButtonText='閉じる'
                confirmOnClick={onCloseDiagnoseErrorModal}
            />

            <MatchingLoading visible={isMatchingLoading} />
            <MatchFailed visible={isMatchFailed} onClose={onCloseMatchFailed} />
        </>
    );
};

export default DiagnoseResult;
