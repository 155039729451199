import React from 'react';
import { Topic } from 'app/slice/policyApplicationDetailsSlice';
import { Col, Row } from 'antd';

type Props = {
    topics: Topic[],
}

const Topics = (props: Props) => {

    const { topics } = props;

    return (
        <div className='card-container light-blue d-block'>
            <Row>
                <Col md={3} xs={12}>
                    <strong>特にご相談したいこと</strong>
                </Col>
                <Col>
                    <ul>
                        {topics.map((topic) => (
                            <li key={topic.code}>{topic.name}</li>
                        ))}
                    </ul>
                </Col>
            </Row>


        </div>
    );

};

export default Topics;
