import React from 'react';
import { Link } from 'react-router-dom';
import defaultLogo from 'assets/images/daidolife_logo_original.png';
import { Divider } from 'antd';

type Props = {
    links?: FooterLink[];
    logo?: string;
    websiteTitle: string;
}

export type FooterLink = {
    url: string;
    title: string;
}

const LandingFooter = (props: Props) => {
    const {links = [], logo = defaultLogo, websiteTitle} = props;

    return (
        <footer className='landing-footer'>
            <div className='footer-wrapper'>
                <div className='logo'>
                    <img src={logo} alt='logo'/>
                    <span>{websiteTitle}</span>
                </div>
                <Divider />
                <div className='copyright d-flex'>
                    <div>Copyright © 中小企業福祉事業団</div>
                    <div className='footer-items'>
                        {links.map((link, key) =>
                            <li key={key}><Link to={link.url}>{link.title}</Link></li>,
                        )}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default LandingFooter;
