import React from 'react';
import { Card, Input } from 'antd';
import { ApplicationDetails, ApplicationFund } from 'app/slice/applicationDetailsSlice';
import { ConsultationStatuses } from 'enums';

type Props = {
    application: ApplicationDetails
}

const ConsultationChecklists = (props: Props) => {
    const { application } = props;
    const { funds } = application;
    const { TextArea } = Input;
    let grantsDisplayed = false;

    const renderChecklists = (fund: ApplicationFund) => {
        return (
            fund.fundRequirements.map(fundRequirement => {
                if (fundRequirement.title !== '基本確認項目' || !grantsDisplayed) {
                    // Make sure that 基本確認項目 is only displayed once
                    if (fundRequirement.title === '基本確認項目') {
                        grantsDisplayed = true;

                        return (
                            <Card key={fundRequirement.title}
                                  className='consultation-checklist consultation-grants-checklist'
                            >
                                <div>
                                    <h4><strong>{fundRequirement.title}</strong></h4>
                                    <ul>
                                        {
                                            fundRequirement.requirements.map(requirement => (
                                                <li key={(requirement.content)}>{requirement.content}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </Card>
                        );
                    } else {
                        return (
                            <Card key={fund.code} className='consultation-checklist'>
                                <div>
                                    <h4><strong>{fundRequirement.title}</strong></h4>
                                    <ul>
                                        {
                                            fundRequirement.requirements.map(requirement => (
                                                <li key={(requirement.content)}>{requirement.content}</li>
                                            ))
                                        }
                                    </ul>
                                </div>

                                <div className='consultation-detail'>
                                    <h4>
                                        <strong>相談内容詳細</strong>
                                        <span className='ms-2 helper-text'>※この欄には、予約時に入力した内容が記載されています</span>
                                    </h4>
                                    <TextArea rows={3} readOnly value={fund.applicationRemarks || ''} />
                                    {(application.label.content === ConsultationStatuses.CANCELED && application.cancellationReason) && (
                                        <div className='card-container red text-gray mt-3'>
                                            <strong className='min-width-100px'>キャンセル理由</strong>
                                            <div className='ms-3'>
                                                {application.cancellationReason}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Card>
                        );
                    }
                }

                return null;
            })
        );
    };

    return (
        <>
            {funds?.map((fund, index) => (
                <div key={index}>
                    以下は、全てお客さまが ✓ 印を付けられた項目です。
                    {renderChecklists(fund)}
                </div>
            ))}
        </>
    );
};

export default ConsultationChecklists;
