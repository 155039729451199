import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, Form, Input, Radio, Row, Select, Skeleton } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    getCapitalRanges,
    getCoverageTypes,
    getLoadedResources,
    getPrefectures,
    getPurposes,
    getSearchEmployeeCountRanges,
    getSearchIndustries,
    getYearsEstablishedRanges,
    selectResources,
} from 'app/slice/resourcesSlice';
import { useQuery } from 'lib/utils';
import { xor } from 'lodash';
import { Link } from 'react-router-dom';
import { Rule } from 'antd/lib/form';
import qs from 'qs';
import { searchLog } from 'app/slice/searchLogSlice';
import { SearchOutlined } from '@ant-design/icons';
import { SearchType } from 'enums';

type Props = {
    onSubmit: (values: any) => void,
    submitImmediately?: boolean
}

type QueryKey =
    'search_type'
    | 'type'
    | 'coverage_type'
    | 'employee_count_range'
    | 'industry'
    | 'capital_range'
    | 'years_established_range'
    | 'prefecture'
    | 'purpose'
    | 'keyword';

const fundingRules: Rule[] = [
    { required: true, message: '資金をご選択ください。' },
];

const purposeRules: Rule[] = [
    { required: true, message: '目的をご選択ください。' },
];

const SearchForm = (props: Props) => {
    const { onSubmit, submitImmediately = false } = props;

    const query = useQuery();
    const [categoryForm] = Form.useForm();
    const [keywordForm] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(true);
    const {
        coverageTypes,
        capitalRanges,
        prefectures,
        purposes,
        searchEmployeeCountRanges,
        searchIndustries,
        yearsEstablishedRanges,
    } = useAppSelector(selectResources);
    const dispatch = useAppDispatch();

    const getQuery = useCallback((key: QueryKey, defaultValue = '') => {
        let params = qs.parse(query.toString());
        return params[key] || defaultValue;
    }, [query]);

    useEffect(() => {
        // only fetch resources if not yet fetched
        let loadedResources = getLoadedResources();
        let requests = (xor(loadedResources, [
            'prefectures',
            'industries',
            'employeeCountRanges',
            'coverageTypes',
            'capitalRanges',
            'yearsEstablishedRanges',
            'purposes',
        ]).length > 0) ? [
            dispatch(getCoverageTypes()),
            dispatch(getSearchEmployeeCountRanges()),
            dispatch(getSearchIndustries()),
            dispatch(getCapitalRanges()),
            dispatch(getYearsEstablishedRanges()),
            dispatch(getPrefectures()),
            dispatch(getPurposes()),
        ] : [];

        Promise.all(requests).then(() => setLoading(false));
    }, [dispatch]);

    const saveSearchLog = (searchType: string) => {
        return () => {
            dispatch(searchLog(searchType === SearchType.CATEGORY ? {
                type: categoryForm.getFieldValue('type'),
                purpose: categoryForm.getFieldValue('purpose'),
            } : {
                keyword: keywordForm.getFieldValue('keyword'),
            }));
        };
    };

    useEffect(() => {
        if (loading === false) {
            if (getQuery('search_type') === SearchType.CATEGORY) {
                categoryForm.setFieldsValue({
                    type: getQuery('type', '1'),
                    purpose: getQuery('purpose', ''),
                    'coverage_type[]': getQuery('coverage_type', []),
                    employee_count_range: getQuery('employee_count_range'),
                    industry: getQuery('industry'),
                    capital_range: getQuery('capital_range'),
                    years_established_range: getQuery('years_established_range'),
                    prefecture: getQuery('prefecture'),
                });

                if (submitImmediately) categoryForm.submit();
            } else if (getQuery('search_type') === SearchType.KEYWORD) {
                keywordForm.setFieldsValue({
                    keyword: getQuery('keyword', '')
                });

                if (submitImmediately) keywordForm.submit();
            }
        }
    }, [categoryForm, getQuery, keywordForm, loading, purposes, submitImmediately]);

    return (
        <Card className='search-form-card shadow'>
            {loading ? (
                <Skeleton active />
            ) : (
                <>
                    <Card>
                        <h2>目的、企業情報などから検索する</h2>
                        <dl>
                            <dt>※「目的」などの条件を変更することで、検索結果が変わります。</dt>
                            <dt>※下部の「フリーワード検索」もご利用いただけます。</dt>
                        </dl>
                        <Form
                            form={categoryForm}
                            onFinish={onSubmit}
                            layout='vertical'
                            autoComplete='off'
                        >
                            <Form.Item hidden={true} name='search_type' initialValue={SearchType.CATEGORY}>
                                <Input type='hidden' />
                            </Form.Item>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        name='type'
                                        label='資金を選択'
                                        rules={fundingRules}
                                        initialValue='1'
                                    >
                                        <Radio.Group>
                                            <Radio value='1'>助成金・補助金</Radio>
                                            <Radio value='2'>融資</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        name='coverage_type[]'
                                        label='個人事業主or法人'
                                    >
                                        <Checkbox.Group>
                                            {coverageTypes.map((row) => (
                                                <Checkbox key={row.code} value={row.code}>{row.name}</Checkbox>
                                            ))}
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        name='purpose'
                                        label='目的'
                                        rules={purposeRules}
                                        initialValue=''
                                    >
                                        <Select>
                                            <Select.Option value='' title='--選択してください--'>--選択してください--</Select.Option>
                                            {purposes?.map((row) => (
                                                <Select.Option key={row.code} value={row.code} title={row.description}>
                                                    {row.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name='prefecture'
                                        label='本社所在地'
                                        initialValue=''
                                    >
                                        <Select>
                                            <Select.Option value=''>--選択してください--</Select.Option>
                                            {prefectures?.map((row) => (
                                                <Select.Option key={row.code} value={row.code}
                                                >{row.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        name='employee_count_range'
                                        label='従業員数'
                                        initialValue=''
                                    >
                                        <Select>
                                            <Select.Option value=''>--選択してください--</Select.Option>
                                            {searchEmployeeCountRanges.map((row) => (
                                                <Select.Option key={row.code} value={row.code}
                                                >{row.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name='industry'
                                        label='業種'
                                        initialValue=''
                                    >
                                        <Select>
                                            <Select.Option value=''>--選択してください--</Select.Option>
                                            {searchIndustries.map((row) => (
                                                <Select.Option key={row.code} value={row.code}
                                                >{row.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        name='capital_range'
                                        label='資本金または出資総額'
                                        initialValue=''
                                    >
                                        <Select>
                                            <Select.Option value=''>--選択してください--</Select.Option>
                                            {capitalRanges.map((row) => (
                                                <Select.Option key={row.code} value={row.code}
                                                >{row.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name='years_established_range'
                                        label='設立からの年数'
                                        initialValue=''
                                    >
                                        <Select>
                                            <Select.Option value=''>--選択してください--</Select.Option>
                                            {yearsEstablishedRanges.map((row) => (
                                                <Select.Option key={row.code} value={row.code}
                                                >{row.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className='text-center'>
                                <Button htmlType='submit' onClick={saveSearchLog(SearchType.CATEGORY)}>
                                    上記の内容で検索する
                                </Button>
                            </div>
                        </Form>
                    </Card>
                    <Card className='mt-3'>
                        <h2>フリーワードで検索する</h2>
                        <Form
                            form={keywordForm}
                            onFinish={onSubmit}
                            layout='inline'
                            autoComplete='off'
                        >
                            <Form.Item hidden={true} name='search_type' initialValue={SearchType.KEYWORD}>
                                <Input type='hidden' />
                            </Form.Item>
                            <Form.Item
                                name='keyword'
                                className='flex-fill'
                            >
                                <Input></Input>
                            </Form.Item>
                            <Form.Item>
                                <Button type='primary' htmlType='submit' className='search-keyword-btn'
                                        icon={<SearchOutlined />}
                                        onClick={saveSearchLog(SearchType.KEYWORD)}
                                >
                                    検索
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                    <p className='mt-2 helper-text text-center'>
                        ※ご利用にあたっては<Link to='/kouteki/terms-of-service'>利用規約</Link>を必ずお読みください。
                    </p>
                </>
            )}
        </Card>
    );
};

export default SearchForm;
