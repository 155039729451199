import React from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { cancelApplication, CancelConsultationRequest, getApplicationDetails } from 'app/slice/applicationDetailsSlice';
import { Rule } from 'antd/lib/form';

type Props = {
    uuid: string,
    isVisible: boolean,
    onClose: () => void
}

const reasonRule: Rule[] = [
    { required: true, message: 'キャンセル理由をご入力ください。' },
];


const ConsultationCancelModal = (props: Props) => {
    const { uuid, isVisible, onClose } = props;
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();

    const onSubmit = async (values: { reason: string }) => {
        await dispatch(cancelApplication({
            uuid: uuid,
            reason: values.reason,
        } as CancelConsultationRequest));

        // Make sure to update application details
        await dispatch(getApplicationDetails(uuid));
        onClose();
    };

    return (
        <Modal className='consultation-cancel-modal' visible={isVisible} footer={null} onCancel={onClose}>
            <div className='title'>面談をキャンセル</div>
            <Form
                form={form}
                onFinish={onSubmit}
                layout='vertical'
            >
                <Form.Item
                    name='reason'
                    label='キャンセル理由（必須）'
                    rules={reasonRule}
                >
                    <Input.TextArea />
                </Form.Item>
                <div className='form-buttons'>
                    <Button className='modal-button' type='primary' danger block htmlType='submit'>
                        確定
                    </Button>
                    <Button className='modal-button' block onClick={onClose}>
                        面談詳細に戻る
                    </Button>
                </div>
            </Form>
        </Modal>
    );

};

export default ConsultationCancelModal;
