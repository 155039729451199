import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getApplicationsApi, GetApplicationsRequest, getApplicationStatusTypesApi } from 'api/application';
import { ApplicationDetails } from 'app/slice/applicationDetailsSlice';
import { RootState } from 'app/store';

export type { GetApplicationsRequest } from 'api/application';

// Application Status Data Structure, mainly for Filter function
export type ApplicationStatus = {
    code: string,
    name: string
}

// Pagination Data Structure returned by API call
export type Pagination = {
    count: number,
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
}

/**
 * GET applications list
 **/
export const getApplications = createAsyncThunk(
    '/company/applications',
    async (data: GetApplicationsRequest, { dispatch }) => {
        try {
            const response = await getApplicationsApi(data);
            dispatch(setApplications(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * GET application statuses list
 **/
export const getApplicationStatusTypes = createAsyncThunk(
    '/company/status-types/application',
    async (data, { dispatch }) => {
        try {
            const response = await getApplicationStatusTypesApi();
            dispatch(setApplicationStatusTypes(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * Create Application Slice
 */
export const applicationListSlice = createSlice({
    name: 'applications',
    initialState: {
        applications: [] as ApplicationDetails[],
        applicationStatusTypes: [] as ApplicationStatus[],
        pagination: {} as Pagination,
    },
    reducers: {
        setApplications: (state, { payload }) => {
            state.applications = payload.data;
            state.applications.forEach((application: any) => {
                if (application.is_deadline) {
                    application.isDeadline = application.is_deadline;
                }

                if (application.specialist && application.specialist.office_name) {
                    application.specialist.officeName = application.specialist.office_name;
                }
            });

            state.pagination = payload.pagination;
            state.pagination.perPage = payload.pagination.per_page;
            state.pagination.currentPage = payload.pagination.current_page;
            state.pagination.lastPage = payload.pagination.last_page;
        },
        setApplicationStatusTypes: (state, { payload }) => {
            state.applicationStatusTypes = payload.data;
        },
    },
});

export const { setApplications, setApplicationStatusTypes } = applicationListSlice.actions;
export const selectApplications = (state: RootState) => state.applications;
