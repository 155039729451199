import React from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { PolicyApplicationDetails } from 'app/slice/policyApplicationDetailsSlice';
import Remarks from 'components/dashboard/working_regulation/common/Remarks';
import Topics from 'components/dashboard/working_regulation/common/Topics';

type Props = {
    application: PolicyApplicationDetails
}

const Applied = (props: Props) => {
    const { application } = props;

    return (
        <>
            {application.daidoJoins ? (
                <div className='card-container gray ps-md-5 py-md-4'>
                    <div>
                        <CheckOutlined className='light-blue-4' />
                        <span className='ms-2'>大同生命営業担当者の同席を希望します</span>
                    </div>
                </div>
            ) : (
                <div className='card-container gray ps-md-5 py-md-4'>
                    <div>
                        <CloseOutlined className='light-blue-4' />
                        <span className='ms-2'>大同生命営業担当者の同席は希望しません</span>
                    </div>
                </div>
            )}

            {application.topics && <Topics topics={application.topics} />}

            {application.remarks && <Remarks remarks={application.remarks} />}
        </>
    );
};

export default Applied;
