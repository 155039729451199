import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

const Landing = () => {
    return (
        <div className='container'>
            <section className='box-section'>
                <Row gutter={[{ xs: 0, md: 32 }, 16]}>
                    <Col xs={24} md={12}>
                        <Link to='/kouteki'>
                            <div className='box-1'>
                                <div className='title'>公的支援</div>
                                <div className='subtitle'>オンラインサービス</div>
                                <p>
                                    助成金・補助金・融資といった公的資金に関する情報をご覧になれます。 <br />
                                    また、公的資金の活用可能性の診断や、専門家への相談サービスをお申し込みいただけます。
                                </p>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={24} md={12}>
                        <Link to='kisoku'>
                            <div className='box-2'>
                                <div className='title'>就業規則診断</div>
                                <div className='subtitle'>オンラインサービス</div>
                                <p>
                                    現行法令の観点から、貴社の就業規則における留意事項を簡易診断いたします。<br />
                                    また、専門家への相談サービスをお申込みいただけます。
                                </p>
                            </div>
                        </Link>
                    </Col>
                </Row>
            </section>
        </div>
    );
};

export default Landing;
