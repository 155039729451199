import React from 'react';
import { Button, Col, Row, Tag } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { markAsViewed } from 'app/slice/notificationSlice';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ApplicationType } from "./ReadNotification";

type Props = {
    title: string,
    date: string,
    uuid: string,
    id: number,
    type: number,
}

const UnreadNotification = (props: Props) => {
    const { title, date, uuid, id, type } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const formatDate = (toFormatDate: string) => {
        return moment(toFormatDate).format('YYYY/MM/DD');
    };
    const redirectionLink = type === ApplicationType.FUND ? `/dashboard/kouteki/consultation/${uuid}/details` : `/dashboard/working-regulation/meetings/${uuid}`;
    const onReadNotification = () => {
        dispatch(markAsViewed({ id }));
        navigate(redirectionLink);
    };

    return (
        <>
            <div className='notification-container notification-container_unread'>
                <Row gutter={16}>
                    <Col className='gutter-row' md={3} xs={24}>
                        <Tag color='#006BB5'>{formatDate(date)}</Tag>
                    </Col>
                    <Col className='gutter-row' md={2} xs={24}>
                        <span className='notification-status-unread'>未読</span>
                    </Col>
                    <Col className='gutter-row' md={15} xs={24}>
                        <div className='notification-title'>{title}</div>
                    </Col>
                    <Col className='gutter-row' md={4} xs={24}>
                        <Button className='notification-btn' onClick={onReadNotification}>詳細</Button>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default UnreadNotification;
