import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { fetchFundRecommendations, fetchFunds } from 'api/funds';

export type FundDataRow = {
    code: string,
    url: string,
    type: number,
    jurisdiction: string,
    title: string,
    overview: string,
    target_groups: string,
    amount: string,
    application_period: string,
    created_at: string,
    updated_at: string
}

export type FundsListState = {
    type: string,
    success: boolean | null,
    message: string,
    loading: boolean,
    data: FundDataRow[],
    params: FundsListParams,
    page: number,
    perPage: number,
    pagination: FundsPagination,
    checked: string[]
}

export type FundsListRequest = {
    coverage_type?: string[],
    type?: string,
    capital_range?: string,
    employee_count_range?: string,
    industry?: string,
    prefecture?: string,
    years_established_range?: string,
    purpose?: string,
    keyword?: string,
    page?: string,
    per_page?: string
}

export type FundsListParams = {
    'coverage_type[]'?: string[],
    type?: string,
    capital_range?: string,
    employee_count_range?: string,
    industry?: string,
    prefecture?: string,
    years_established_range?: string,
    purpose?: string,
    keyword?: string,
    page?: string,
    per_page?: string,
    search_type?: string
}

export type FundsPagination = {
    currentPage: number,
    total: number
}

/**
 * calls fund API
 **/
export const getFunds = createAsyncThunk(
    'funds/search',
    async (_, { dispatch, rejectWithValue, getState }) => {
        try {
            const funds = (getState() as RootState).fundsList;

            // Needed to be able to delete empty keys
            const request = {
                coverage_type: funds.params['coverage_type[]'],
                type: funds.params.type,
                capital_range: funds.params.capital_range,
                employee_count_range: funds.params.employee_count_range,
                industry: funds.params.industry,
                prefecture: funds.params.prefecture,
                years_established_range: funds.params.years_established_range,
                page: funds.page.toString(),
                per_page: funds.perPage.toString(),
                purpose: funds.params.purpose,
                keyword: funds.params.keyword
            } as FundsListRequest;

            let response = await fetchFunds(request);

            if (response.data.success === true) {
                dispatch(setPagination(response.data));
                return response.data.data;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

/**
 * calls recommended fund API
 **/
export const getFundRecommendations = createAsyncThunk(
    'funds/recommendations',
    async (_, { rejectWithValue }) => {
        try {
            let response = await fetchFundRecommendations();
            return response.data.data;
        } catch (error) {
            return rejectWithValue(false);
        }
    },
);

export const fundsListSlice = createSlice({
    name: 'fundsList',
    initialState: {
        type: '',
        success: null,
        message: '',
        loading: false,
        data: [],
        pagination: {} as FundsPagination,
        params: {},
        page: 1,
        perPage: 20,
        checked: [],
    } as FundsListState,
    reducers: {
        setPage: (state: FundsListState, action) => {
            state.page = action.payload;
        },
        setPerPage: (state: FundsListState, action) => {
            state.perPage = action.payload;
        },
        setParams: (state: FundsListState, action) => {
            state.params = action.payload;
        },
        setPagination: (state: FundsListState, action) => {
            state.pagination = {
                currentPage: action.payload.current_page,
                total: action.payload.total,
            };
        },
        setChecked: (state: FundsListState, action) => {
            state.checked = action.payload;
        },
        resetPagination: (state: FundsListState) => {
            state.page = 1;
            state.pagination = {} as FundsPagination;
        },
        resetChecked: (state: FundsListState) => {
            state.checked = [];
        },
        resetFunds: (state: FundsListState) => {
            state.success = null;
            state.data = [];
        }
    },
    extraReducers: (builder) => {
        // getFunds action pending
        builder.addCase(getFunds.pending, (state: FundsListState) => {
            state.loading = true;
            state.success = null;
            state.data = [];
        });
        // getFunds action rejected
        builder.addCase(getFunds.rejected, (state: FundsListState, action) => {
            state.loading = false;
            state.success = false;
            state.message = action.payload as string;
        });
        // getFunds action fulfilled
        builder.addCase(getFunds.fulfilled, (state: FundsListState, action) => {
            state.loading = false;
            state.success = true;
            state.data = action.payload as FundDataRow[];
        });
        //getFundRecommendations action pending
        builder.addCase(getFundRecommendations.pending, (state: FundsListState) => {
            state.loading = true;
            state.data = [];
        });
        // getFundRecommendations action rejected
        builder.addCase(getFundRecommendations.rejected, (state: FundsListState, action) => {
            state.loading = false;
            state.success = false;
            state.message = action.payload as string;
        });
        // getFundRecommendations action fulfilled
        builder.addCase(getFundRecommendations.fulfilled, (state: FundsListState, action) => {
            state.loading = false;
            state.success = true;
            state.data = action.payload as FundDataRow[];
        });
    },
});

export const {
    setPage,
    setPerPage,
    setParams,
    setPagination,
    setChecked,
    resetPagination,
    resetChecked,
    resetFunds
} = fundsListSlice.actions;
export const selectFundsList = (state: RootState) => state.fundsList;
