import React, { useState } from 'react';
import { Alert, Card, Checkbox, Col, Form, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type Props = {
    fund: {
        code: string,
        title: string,
        result: boolean,
    },
    index: number,
    addSelected: (code: string) => void
}

const DiagnoseResultValid = (props: Props) => {
    const { fund, index, addSelected } = props;
    const { code, title } = fund;
    const [isInputDisabled, setInputDisabled] = useState(true);

    const onChange = (e: CheckboxChangeEvent) => {
        addSelected(code);
        setInputDisabled(!e.target.checked);
    };

    return (
        <Card className='diagnose-list'>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={24} key={index}>
                    <Form.Item name={[index, 'code']} initialValue={code} hidden>
                        <Input type='hidden' />
                    </Form.Item>
                    <Alert
                        type='info'
                        className='border-0'
                        message={
                            <>
                                <Checkbox className='checkbox-size' onChange={onChange} />
                                <a href={`/kouteki/funds/${code}/details`} className='ms-1 text-black link link-desc'
                                   target='_blank' rel='noreferrer'
                                >
                                    {title}
                                </a>
                                <p style={{'color': '#FF4D4F'}}>
                                ※相談を希望する場合は、チェックボックスにチェックを入れたうえで、下の「専門家マッチングを申し込む」ボタンを押してください。
                                </p>
                                <Row gutter={24} className='align-items-center'>
                                    <Col xs={16} md={18}>
                                        <Form.Item name={[index, 'remarks']} className='mb-0'>
                                            <TextArea rows={5}
                                                      placeholder={'●ご相談したい内容を具体的にご入力ください。※任意。500文字以内 \n    （例：申請要件を満たしているか詳しく聞きたい）\n\n●ご希望の日程がありましたら、こちらにご入力ください。\n    ※ご希望の日程がある場合は、本日より７日後以降の日程を入力ください（土日祝日を除く）。'}
                                                      disabled={isInputDisabled}
                                                      maxLength={500}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={8} md={6}>
                                        <div className='consult-badge'>相談可能</div>
                                    </Col>
                                </Row>
                            </>
                        }
                    />
                </Col>
            </Row>
        </Card>
    );
};

export default DiagnoseResultValid;
