import { getAxiosClient } from './index';
import { TopicListParams, TopicParams } from 'app/slice/topicSlice';

export const fetchTopicList = (params: TopicListParams) => {
    const restClient = getAxiosClient();

    return restClient.get('/notifications/topics/' + params.type, {
        params: { per_page: params.perPage },
    });
};

export const fetchTopic = (params: TopicParams) => {
    const restClient = getAxiosClient();
    return restClient.get('/notifications/topics/' + params.type + '/' + params.uuid);
};
