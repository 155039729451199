import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Form, Input } from 'antd';
import ChangePasswordSuccess from './ChangePasswordSuccess';
import { validatePasswordConfirmation } from 'lib/utils';
import { useAppDispatch } from 'app/hooks';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { changePassword } from 'app/slice/companySlice';

type Props = {
    isPasswordSuccess: boolean,
    setClose: () => void,
    setIsPasswordSuccess: (value: boolean) => void
}
export type ChangePasswordHandle = {
    clearFields: () => void;
};
const rules = {
    currentPassword: [{ required: true, whitespace: true, message: '現在のパスワードを入力してください' }],
    newPassword: [
        { required: true, whitespace: true, message: '新しいパスワードを入力してください' },
        { pattern: new RegExp(/^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d).*$/), message: '8桁の英数混合のパスワードをご入力ください。' },
    ],
    confirmPassword: [
        { required: true, whitespace: true, message: '新しいパスワード（確認用）をご入力ください。' },
        validatePasswordConfirmation('new_password', 'パスワードは一致していません。'),
    ],
};
const ChangePasswordRequirement = forwardRef((props: Props, ref) => {
    const { setIsPasswordSuccess } = props;
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const { status } = useSelector((state: RootState) => state.company);
    const [currentPassError, setCurrentPassError] = useState(false);

    const onSubmit = async (values: any) => {
        const { current_password, new_password, new_password_confirmation } = values;
        await dispatch(changePassword({ current_password, new_password, new_password_confirmation }));
    };

    const resetCurrentPassError = () => setCurrentPassError(false);

    useImperativeHandle(ref, () => ({
        clearFields() {
            form.resetFields();
        },
    }));

    useEffect(() => {
        if (status === 'success') {
            setIsPasswordSuccess(true);
            form.resetFields();
        } else if (status === 'failed') {
            setCurrentPassError(true);
            form.validateFields(['current_password']);

        }
    }, [form, setIsPasswordSuccess, status]);

    const onCloseSuccess = () => {
        props.setClose();
    };


    const currentPasswordValidation = () => {
        if (currentPassError) {
            return Promise.reject('現在のパスワードが無効です。');
        }
        return Promise.resolve();
    };

    return (
        <>
            {props.isPasswordSuccess === false ?
                <Form
                    form={form}
                    onFinish={onSubmit}
                    layout='vertical'
                    autoComplete='off'
                >
                    <Form.Item
                        name='current_password'
                        label='現在のパスワード'
                        rules={[...rules.currentPassword, { validator: currentPasswordValidation }]}
                    >
                        <Input.Password onChange={resetCurrentPassError} />
                    </Form.Item>
                    <Form.Item
                        name='new_password'
                        label='新しいパスワード'
                        rules={rules.newPassword}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name='new_password_confirmation'
                        label='新しいパスワード (確認用)'
                        rules={rules.confirmPassword}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Button type='primary' htmlType='submit' className='w-100'>
                        変更
                    </Button>
                </Form>
                :
                <ChangePasswordSuccess onCloseSuccess={onCloseSuccess} />
            }
        </>
    );
});

export default ChangePasswordRequirement;
