import React from 'react';
import { Button, Col, Row } from 'antd';
import { ApplicationDetails } from 'app/slice/applicationDetailsSlice';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ConsultationChecklists from 'components/consultation/details/ConsultationChecklists';
import SpecialistDetails from 'components/consultation/SpecialistDetails';
import { Link } from 'react-router-dom';
import { selectSearchQuery } from 'app/slice/searchQuerySlice';
import { useAppSelector } from 'app/hooks';

type Props = {
    application: ApplicationDetails,
    onOpenCancelModal: () => void
}

const Scheduled = (props: Props) => {
    const { application, onOpenCancelModal } = props;
    const { searchQuery } = useAppSelector(selectSearchQuery);

    const meetingDetails = () => {
        return (
            <div>
                <hr />
                <p className='label-text'>
                    <strong>日程 : <span className='datetime'>{application.scheduleDatetime!}</span></strong>
                </p>

                {application?.daidoJoins ?
                    <div><CheckOutlined className='attendance-check-icon' /> 大同生命営業担当者の同席を希望します。</div>
                    :
                    <div><CloseOutlined className='attendance-check-icon red-3' /> 大同生命営業担当者の同席は希望しません。</div>
                }

                <Row gutter={20} align='bottom'>
                    <Col md={20}>
                        <Row gutter={[0, 4]}>
                            <Col md={4} xs={24}>
                                <strong>ミーティングURL:</strong>
                            </Col>
                            <Col md={20} xs={24}>
                                <a className='link' href={application.joinUrl || ''} target='_blank' rel='noreferrer'>
                                    {application.joinUrl || ''}
                                </a>
                            </Col>
                            <Col md={4} xs={24}>
                                <strong>パスワード:</strong>
                            </Col>
                            <Col md={20} xs={24}>
                                {application.password || ''}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4} xs={24} className='mt-2'>
                        <Button onClick={onOpenCancelModal} type='primary' danger>面談をキャンセル</Button>
                    </Col>
                </Row>
                <div className='note-text'>
                    ※面談ルームへの入室は予約時刻の５分前から可能です。<br />
                    ※面談時間は予約時刻から３０分間となります。３０分を過ぎた場合は予告なくシステムが終了する可能性がございます。予めご了承ください。<br />
                    ※面談日時を変更したい場合、大同生命営業担当者にご連絡ください。
                </div>
            </div>
        );
    };

    return (
        <>
            <ConsultationChecklists application={application} />

            <SpecialistDetails
                application={application}
                hasProfileButton={!!application.specialist}
                meetingDetails={meetingDetails()}
                isKoteki={true}
            />

            <div className='text-center'>
                <Link to={`/dashboard/kouteki/consultation-list` + searchQuery}>
                    <Button className='back-button' type='primary'>戻る</Button>
                </Link>
            </div>
        </>
    );

};

export default Scheduled;
