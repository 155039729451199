import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { getProfile, saveProfile } from 'api/user';
import { ProfileFieldData } from 'types';

export const FETCH_PROFILE = 'FETCH_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export type ProfileData = {
    user: {
        email: string,
        uuid: string
    },
    profile: {
        id: number,
        userId: number,
        lastname: string,
        firstname: string,
        lastnameFurigana: string,
        firstnameFurigana: string,
        phone: string,
        createdAt: string,
        updatedAt: string
    },
    company: {
        name: string,
        nameFurigana: string,
        postalCode: string,
        address: string,
        prefectureCode: string,
        buildingName: string,
        industryCode: string,
        representativePosition: string,
        employeeCountRangeCode: string,
        parttimeEmployeeCount: number,
        insurancePlans: string[],
        laborRegulationsCompliant: number,
        allowSharoushi: number,
        allowShindanshi: number,
        daidoBranchName: string,
        daidoDepartmentName: string,
        daidoEmployeeName: string,
        createdAt: string,
        updatedAt: string
    },
    insurancePlans: {
        code: string,
        name: string
    }[]
};

export type ProfileDataResponse = {
    user: {
        email: string,
        uuid: string
    },
    profile: {
        id: number,
        user_id: number,
        lastname: string,
        firstname: string,
        lastname_furigana: string,
        firstname_furigana: string,
        phone: string,
        created_at: string,
        updated_at: string
    },
    company: {
        name: string,
        name_furigana: string,
        postal_code: string,
        address: string,
        prefecture_code: string,
        building_name: string,
        industry_code: string,
        representative_position: string,
        employee_count_range_code: string,
        parttime_employee_count: number,
        insurance_plans: string[],
        labor_regulations_compliant: number,
        allow_sharoushi: number,
        allow_shindanshi: number,
        daido_branch_name: string,
        daido_department_name: string,
        daido_employee_name: string,
        created_at: string,
        updated_at: string
    },
    insurance_plans: {
        code: string,
        name: string
    }[]
};

export type ErrorFields = {
    //Currently values wanted to fetch, can add in the future
    field: string 
}

export type ProfileState = {
    type: string,
    success: boolean | null,
    data: ProfileData,
    loading: boolean,
    errors: ErrorFields
}

/**
 * calls profile API
 **/
export const fetchProfile = createAsyncThunk(
    'users/fetchProfile',
    async (_, { getState, dispatch, rejectWithValue }) => {
        try {
            // @ts-ignore
            const { uuid } = getState().auth;
            const response = await getProfile(uuid);
            const { data, success } = response.data;

            if (success) {
                dispatch(setData(data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);


/**
 * PATCH user's registration data
 **/
export const updateProfile = createAsyncThunk(
    'users/updateProfile',
    async (values: ProfileFieldData, { getState, rejectWithValue }) => {
        try {
            let response = await saveProfile(values);
            const { success } = response.data;
            if (success) {
                return true;
            }
            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error, true));
        }
    },
);

export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        type: '',
        loading: false,
        success: null,
        data: {},
        errors: {} as ErrorFields
    } as ProfileState,
    reducers: {
        setData: (state, { payload }) => {
            state.data = {
                user: {
                    email: payload?.user?.email,
                    uuid: payload?.user?.uuid,
                },
                profile: {
                    id: payload?.profile?.id,
                    userId: payload?.profile?.user_id,
                    lastname: payload?.profile?.lastname,
                    firstname: payload?.profile?.firstname,
                    lastnameFurigana: payload?.profile?.lastname_furigana,
                    firstnameFurigana: payload?.profile?.firstname_furigana,
                    phone: payload?.profile?.phone,
                    createdAt: payload?.profile?.created_at,
                    updatedAt: payload?.profile?.updated_at,
                },
                company: {
                    name: payload?.company?.name,
                    nameFurigana: payload?.company?.name_furigana,
                    postalCode: payload?.company?.postal_code,
                    address: payload?.company?.address,
                    prefectureCode: payload?.company?.prefecture_code,
                    buildingName: payload?.company?.building_name,
                    industryCode: payload?.company?.industry_code,
                    representativePosition: payload?.company?.representative_position,
                    employeeCountRangeCode: payload?.company?.employee_count_range_code,
                    parttimeEmployeeCount: payload?.company?.parttime_employee_count,
                    insurancePlans: payload?.company?.insurance_plans,
                    laborRegulationsCompliant: payload?.company?.labor_regulations_compliant,
                    allowSharoushi: payload?.company?.allow_sharoushi,
                    allowShindanshi: payload?.company?.allow_shindanshi,
                    daidoBranchName: payload?.company?.daido_branch_name,
                    daidoDepartmentName: payload?.company?.daido_department_name,
                    daidoEmployeeName: payload?.company?.daido_employee_name,
                    createdAt: payload?.company?.created_at,
                    updatedAt: payload?.company?.updated_at,
                },
                insurancePlans: payload?.insurance_plans,
            };
        },
    },
    extraReducers: (builder) => {
        // fetchProfile action pending
        builder.addCase(fetchProfile.pending, (state: ProfileState) => {
            state.type = FETCH_PROFILE;
            state.loading = true;
            state.success = null;
        });
        // fetchProfile action rejected
        builder.addCase(fetchProfile.rejected, (state: ProfileState) => {
            state.type = FETCH_PROFILE;
            state.loading = false;
            state.success = false;
        });
        // fetchProfile action fulfilled
        builder.addCase(fetchProfile.fulfilled, (state: ProfileState) => {
            state.type = FETCH_PROFILE;
            state.loading = false;
            state.success = true;
        });
        // updateProfile action pending
        builder.addCase(updateProfile.pending, (state: ProfileState) => {
            state.type = UPDATE_PROFILE;
            state.loading = true;
            state.success = null;
        });
        // updateProfile action rejected
        builder.addCase(updateProfile.rejected, (state: ProfileState, { payload }) => {
            state.type = UPDATE_PROFILE;
            state.loading = false;
            state.success = false;
            state.errors = payload as ErrorFields;
        });
        // updateProfile action fulfilled
        builder.addCase(updateProfile.fulfilled, (state: ProfileState) => {
            state.type = UPDATE_PROFILE;
            state.loading = false;
            state.success = true;
        });
    },
});

export const { setData } = profileSlice.actions;
export const selectProfile = (state: RootState) => state.profile;
