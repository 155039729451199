import React from 'react';
import { Card, Col, Row } from 'antd';
import { ApplicationFund } from 'app/slice/applicationDetailsSlice';
import classNames from 'classnames';

type Props = {
    funds: ApplicationFund[],
    date?: String,
    label: {
        content: string,
        color: string
    },
    status: string
}

const ConsultationHeader = (props: Props) => {

    const { funds, date, label, status } = props;

    return (
        <div className='consultation-status-card'>
            <Card className={classNames('status', status)}>
                <Row>
                    <Col className='gutter-row status-title' md={1} xs={6}>
                        <b>内容:</b>
                    </Col>
                    <Col className='gutter-row status-title' md={12} xs={18}>
                        {funds?.map((row) => (
                            <div key={row.code} className='Consultation-list-card-title'>{row.title}</div>
                        ))}
                    </Col>
                    <Col className='gutter-row text-center status-title' md={6} xs={24}>
                        {date}
                    </Col>
                    <Col className='gutter-row status-text' md={5} xs={24} style={{ color: `${label?.color}` }}>
                        {label?.content}
                    </Col>
                </Row>
            </Card>
        </div>
    );

};

export default ConsultationHeader;
