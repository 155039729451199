import React from 'react';
import koutekiWebsiteTitle from 'assets/images/kouteki_web_title.png';
import logo from 'assets/images/daidolife_logo_original.png';
import LandingFooter, { FooterLink } from 'components/landing/LandingFooter';
import { Outlet, useLocation } from 'react-router-dom';
import TopLandingHeader from 'components/common/TopLandingHeader';
import SubHeader from 'components/common/SubHeader';
import LandingNavbar from 'components/landing/LandingNavbar';
import { useStyle } from 'lib/utils';
import SystemNotification from 'components/common/SystemNotification';

/**
 * Props for footer links
 */
const footerLinks: FooterLink[] = [
    {
        url: '/kouteki/privacy-policy',
        title: '個人情報取扱規約 ',
    },
    {
        url: '/kouteki/terms-of-service',
        title: '利用規約',
    },
    {
        url: '/kouteki/inquiries',
        title: 'Q&A',
    },
];

type Props = {
    onOpenLoginModal: () => void
}

const PublicSupportLayout = (props: Props) => {
    const { onOpenLoginModal } = props;
    useStyle('public-support');
    const location = useLocation();

    const getTitle = () => {
        switch (location.pathname) {
            case '/kouteki/privacy-policy':
                return '個人情報取扱規約';
            case '/kouteki/terms-of-service':
                return '利用規約';
            case '/kouteki/inquiries':
                return 'Q&A';
            default:
                return '';
        }
    };

    const displayWebsiteTitle = () => {
        if (location.pathname.includes('/kouteki/')) {
            return '公的支援オンラインサービス';
        }

        return '公的支援オンラインサービス／就業規則診断オンラインサービス';
    };

    const Header = () => {
        switch (location.pathname) {
            case '/kouteki':
                return <TopLandingHeader image={koutekiWebsiteTitle} />;
            default:
                return <SubHeader title={getTitle()} />;
        }
    };

    return (
        <>
            <SystemNotification />
            <LandingNavbar onOpenLoginModal={onOpenLoginModal} websiteTitle={displayWebsiteTitle()} />
            <Header />
            <Outlet />
            <LandingFooter links={footerLinks} logo={logo} websiteTitle={displayWebsiteTitle()} />
        </>
    );
};

export default PublicSupportLayout;
