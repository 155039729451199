import { createAsyncThunk } from '@reduxjs/toolkit';
import { kisokuTemplateLogClick } from 'api/kisokuTemplateLog';

export type KisokuTemplateLogRequest = {
    filename: string
}

export const addKisokuTemplateLog = createAsyncThunk(
    'resource/kisoku-template-log',
    async (request: KisokuTemplateLogRequest) => {
        try {
            await kisokuTemplateLogClick(request);
            return true;
        } catch (err) {
            return false;
        }
    },
);
