import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authenticate } from 'api/auth';
import { RootState } from 'app/store';
import { setAuth } from 'app/slice/authSlice';
import { getErrorMessage } from 'api';

export type LoginState = {
    success: boolean,
    message: string,
    loading: boolean
}

export type LoginRequest = {
    email: string,
    password: string
}

/**
 * calls login API
 **/
export const login = createAsyncThunk(
    'users/login',
    async ({ email, password }: LoginRequest, { dispatch, rejectWithValue }) => {
        try {
            let response = await authenticate(email, password);
            const { data } = response;

            if (data.success) {
                dispatch(setAuth(data));
                return true;
            }
            return rejectWithValue(data.message);
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        success: false,
        message: '',
        loading: false,
    } as LoginState,
    reducers: {
        resetLogin: (state: LoginState) => {
            state.success = false;
            state.message = '';
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        // login action pending
        builder.addCase(login.pending, (state: LoginState) => {
            state.loading = true;
            state.message = '';
        });
        // login action rejected
        builder.addCase(login.rejected, (state: LoginState, action) => {
            state.loading = false;
            state.success = false;
            state.message = action.payload as string;
        });
        // login action fulfilled
        builder.addCase(login.fulfilled, (state: LoginState) => {
            state.loading = false;
            state.success = true;
        });
    },
});

export const selectLogin = (state: RootState) => state.login;
export const { resetLogin } = loginSlice.actions;
