import React from 'react';
import { Col, Row } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { PolicyApplicationDetails } from 'app/slice/policyApplicationDetailsSlice';
import Remarks from 'components/dashboard/working_regulation/common/Remarks';
import Topics from 'components/dashboard/working_regulation/common/Topics';

type Props = {
    application: PolicyApplicationDetails
}

const Finished = (props: Props) => {
    const { application } = props;
    return (
        <>
            {application.schedule || application.daidoJoins ?
                <div className='card-container gray py-md-4'>
                    <Row className='w-100'>
                        {application.schedule ?
                            <Col span={12}>
                                <strong>{application.schedule}</strong>
                            </Col> : ''
                        }

                        {application.daidoJoins ? (
                            <Col span={12}>
                                <div>
                                    <CheckOutlined className='light-blue-4' />
                                    <span className='ms-2'>大同生命営業担当者の同席を希望します</span>
                                </div>
                            </Col>
                        ) : (
                            <Col span={12}>
                                <div>
                                    <CloseOutlined className='light-blue-4' />
                                    <span className='ms-2'>大同生命営業担当者の同席は希望しません</span>
                                </div>
                            </Col>
                        )}
                    </Row>
                </div> : null
            }

            {application.topics && <Topics topics={application.topics} />}
            {application.remarks && <Remarks remarks={application.remarks} />}
        </>
    );
};

export default Finished;
