import { ApplicationDetails } from 'app/slice/applicationDetailsSlice';
import { Button, Col, Row, Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { ConsultationStatuses } from 'enums';

type Props = {
    application: ApplicationDetails
}

const ConsultationListCard = (props: Props) => {
    const { application } = props;
    const location = useLocation();

    const renderTooltipContent = () => {
        const status = application?.label?.content;

        switch (status) {
            case ConsultationStatuses.MATCHING:
                return '対応可能な専門家からの応募待ちの状態です。';
            case ConsultationStatuses.SELECTING_SPECIALIST:
                return '専門家から応募がありました。お客さまのご回答をお待ちしている状態です。（本日を含め、土日・祝日を除く３日を過ぎると「回答期限切れ」となります 。）';
            case ConsultationStatuses.SCHEDULED:
                return '専門家との面談日時が決定した状態です。面談当日は、詳細画面に掲載されている「ミーティングURL」からアクセスしてください。';
            case ConsultationStatuses.FINISHED:
                return '面談が完了した状態です。';
            case ConsultationStatuses.NO_MATCH:
                return '専門家とマッチしなかったため、中企団オンライン相談窓口をご利用いただける状態です。（本日を含め、土日・祝日を除く３日を過ぎると「キャンセル」となります 。）';
            case ConsultationStatuses.CANCELED:
                return '面談はキャンセルとなりました。';
            case ConsultationStatuses.REQUESTING:
                return '中企団オンライン相談窓口がお客さまのお申し込みを確認している状態です。本日を含め、土日・祝日を除く３日以内に面談の候補日が届きますのでしばらくお待ちください。';
            case ConsultationStatuses.WAITING_CONF:
                return '中企団相談オンライン窓口から面談の候補日が届いています。お客さまの日時選択をお待ちしている状態です。（本日を含め、土日・祝日を除く３日を過ぎると「回答期限切れ」となります 。）';
            case ConsultationStatuses.PAST_DUE:
                return '回答期限までに専門家の選択をされず、キャンセルとなっている状態です。';
            default:
                return '';
        }
    };

    return (
        <div key={application?.uuid} className='consultation-list-card'>
            <Row gutter={16} align='middle'>
                <Col className='gutter-row consultation-list-card-titles' md={10} xs={24}>
                    {application?.funds?.map((row) => (
                        <div key={row?.code} className='consultation-list-card-title'>{row?.title}</div>
                    ))}
                </Col>
                <Col className='gutter-row consultation-schedule' md={5} xs={24}>
                    {
                        application?.schedule && application?.label?.content !== ConsultationStatuses.PAST_DUE ? ((application?.isDeadline ? '回答期限：' : '面談日：') + application?.schedule) : ''
                    }
                </Col>
                <Col className='gutter-row mb-2' md={6} xs={24}>
                    <Space>
                        <span className='gutter-row consultation-list-card-title'
                              style={{ color: `${application?.label?.color}` }}
                        >{application?.label?.content}</span>
                        <Tooltip title={renderTooltipContent()}>
                            <InfoCircleOutlined className='status-info-icon' />
                        </Tooltip>
                    </Space>
                    <Row>
                        {application?.specialist ?
                            <strong><span
                                className='gutter-row consultation-specialist'
                            >{application?.specialist?.label}: {application?.specialist?.name}</span></strong>
                            : ''
                        }
                    </Row>
                </Col>
                <Col className='gutter-row text-center' md={3} xs={24}>
                    <Link to={`/dashboard/kouteki/consultation/${application?.uuid}/details`}>
                        <Button className='details-button'>詳細</Button>
                    </Link>
                </Col>
            </Row>
        </div>
    );

};

export default ConsultationListCard;
