export enum ConsultationStatuses {
    MATCHING = 'マッチング中',
    SCHEDULED = '面談予約済み',
    SELECTING_SPECIALIST = '専門家選択',
    NO_MATCH = 'マッチングなし',
    CANCELED = 'キャンセル',
    FINISHED = '面談済み',
    REQUESTING = '中企団相談窓口ご案内中',
    WAITING_CONF = '面談日程確認待ち',
    PAST_DUE = '回答期限切れ'
}

export enum MeetingStatuses {
    APPLIED = '面談申込中',
    SCHEDULED = '面談予約済み',
    WAITING = '日程選択待ち',
    FINISHED = '面談済み',
    CANCELED = 'キャンセル',
    PAST_DUE = '回答期限切れ'
}

export enum PolicyStatuses {
    NOT_SHARED = '共有待ち',
    WAITING_FOR_REPORT = '診断レポート待ち',
    REPORT_DOWNLOADABLE = 'レポート閲覧可能',
    CONSULTATION_POSSIBLE = '面談申込可能',
}

export enum UploadFileType {
    PDF_FILE = 'application/pdf',
    DOC_FILE = 'application/msword',
    DOCX_FILE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
}

export enum SpecialistRoles {
    SHAROUSHI = '社労士名',
    SHINDANSHI = '診断士名'
}

export enum NotificationType {
    KOTEKI = 1,
    KISOKU = 2
}

export enum SearchType {
    CATEGORY = '1',
    KEYWORD = '2'
}
