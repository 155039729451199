import React, { CSSProperties } from 'react';
import classNames from 'classnames';

type Props = {
    containerClass?: string,
    contentStyle?: CSSProperties,
    title: string,
}

/**
 * Creates header for top pages
 * @param { Props } props
 * @return html
 * */
const SubHeader = (props: Props) => {
    const { containerClass = '', contentStyle, title } = props;

    return (
        <section className={classNames('sub-header', containerClass)}>
            <div className='content' style={contentStyle}>
                <p className='title'>{title}</p>
            </div>
        </section>
    );
};

export default SubHeader;
