import React from 'react';

const TermsOfService = () => {
    return (
        <>
            第１条 利用規約
            <ol>
                <li>本規約は、中小企業福祉事業団（以下「当事業団」という）が、インターネットを通じて提供する「『公的支援オンラインサービス』『就業規則診断オンラインサービス』（以下「本サービス」という）」の利用に関する規約を定めたものである。</li>
                <li>当サービスを利用する法人・団体・組合・個人の利用者（以下「利用者」という）は、本規約を承諾したものとみなし、以下の規約に同意し、遵守した上で利用しなければならない。</li>
            </ol>

            第２条 本サービスの目的
            <ol>
                <li>本サービスは、利用者に以下を提供することを主たる目的とする。</li>
                １）公的助成金・公的補助金・融資等の情報提供および受給可能性の診断 <br />
                ２）利用者の就業規則の診断 <br />
                ３）本項第１号、第２号に関する専門家との面談設定
            </ol>

            第３条 大同生命保険株式会社等との協働
            <ol>
                <li>
                    本サービスは、当事業団が大同生命保険株式会社（以下「大同生命」という）と協働して提供するものであり、それぞれの役割は以下とする。<br />
                    １）当事業団：本サービスの運営管理<br />
                    ２）大同生命：本サービスの顧客への紹介
                </li>
                <li>
                    第２条の本サービスの目的を円滑に遂行するため、以下の業務を大同生命にて実施するものとする。<br />
                    １）本サービスの顧客への提案・紹介<br />
                    ２）本サービスの継続利用に向けた利用者への情報提供・各種サポート<br />
                    ３）本サービスにより提供する情報の拡充・品質向上等に向けた利用者のデータ分析<br />
                    ４）その他利用者に対するサービス等の適切かつ円滑な遂行
                </li>
                <li>当事業団は、本条第２項に定める業務を実施する上で必要な利用者情報を大同生命と共有するものとする。</li>
                <li>大同生命は本条第３項にて取得した情報を本条第２項に定める業務を実施することのみに使用できるものとする。</li>
                <li>当事業団は、本サービスに関する業務の全部または一部を第三者に委託することができる。</li>
            </ol>

            第４条 利用者
            <ol>
                <li>本規約を承認の上、利用登録を完了した法人・団体・組合・個人を総称して「利用者」と称する。</li>
            </ol>

            第５条 利用登録
            <ol>
                <li>本サービスの利用を希望する者（以下「利用希望者」という）は、所定の利用手続きを実施するものとする。</li>
                <li>
                    利用希望者は次の要件（以下「申込条件」という）を満たしていることを必要とする。<br />
                    １）メールアドレスを保有していること。<br />
                    ２）本サービスを利用するための通信機器を保有していること。<br />
                    ３）その他、当事業団が定める要件を満たしていること。
                </li>
                <li>利用登録は、利用希望者が当事業団または大同生命に申出を行い、所定の手続きを実施し、当事業団および大同生命による承認が完了した日に完了するものとする。</li>
                <li>
                    利用希望者が以下のいずれかの事項に該当する場合、その利用申込は承認されないものとする。また利用登録後においても、以下のいずれかの事項に該当すると判明した場合は、当事業団は利用登録を取り消すことができる。<br />
                    １）申込者が本条第２項に定める申込条件に該当しない場合<br />
                    ２）申込者の登録情報に虚偽がある場合<br />
                    ３）その他当事業団または大同生命が不適切であると判断した場合
                </li>
            </ol>

            第６条 ユーザIDとパスワード
            <ol>
                <li>当事業団は、利用者ごとのIDとパスワードを発行する。</li>
                <li>利用者は、IDとパスワードの使用および管理に関して一切の責任を負うものとする。</li>
                <li>当事業団は、利用者のIDおよびパスワードが第三者に使用されたことによって当該利用者が被る損害については一切責任を負わないものとする。また、当該IDまたはパスワードによりなされた本サービスの利用は当該利用者によりなされたものとみなし、当該利用者はその利用料金、その他の債務の一切を負担するものとする。</li>
                <li>利用者は、自己のIDおよびパスワードを忘れた場合、本サービスのWebサイト上の問い合わせ機能、パスワード再発行機能を用いて、再度ID、パスワードの発行を受けるものとする。</li>
            </ol>

            第７条 本サービスの内容
            <ol>
                <li>
                    本サービスの内容は以下のとおりとする。<br />
                    １）公的助成金・公的補助金・融資等の受給可能性の診断機能 <br />
                    ２）本項第１号に関する専門家との面談予約・管理機能<br />
                    ３）就業規則の診断機能（就業規則のアップロードおよび診断結果のダウンロード機能）<br />
                    ４）本項第３号に関する専門家との面談予約・管理機能<br />
                    ５）その他、第２条の本サービスの目的に沿った機能
                </li>
                <li>当事業団は、本サービスの内容に関する情報を、本サービスのWebサイト上に表示するものとする。</li>
                <li>当事業団は、利用者へ事前に通知することなく、本サービスの内容の追加・変更・停止を実施できるものとする。追加・変更・停止した内容については、本条第２項の方法で利用者に情報提供することとする。</li>
                <li>本サービスで提供される各種情報については、一般的な情報であり、確実性、完全性を保証するものではない。</li>
            </ol>

            第８条 利用者の義務
            <ol>
                <li>
                    利用者は次の義務を負うものとする。 <br />
                    １）本規約ならびに第７条２項に定める情報提供方法により提供された諸規則を遵守すること。<br />
                    ２）利用者は、本サービスの利用資格を第三者に譲渡、貸与、売却ならびにこれらに準ずる行為を行わないこと。<br />
                    ３）本サービスで得たサービスの利用権等を第三者に譲渡、貸与、売却ならびにこれらに準ずる行為を行わないこと。<br />
                    ４）本サービスの秩序を乱す行為をしないこと。
                </li>
            </ol>

            第９条 禁止事項
            <ol>
                <li>
                    利用者は、本サービスを利用するにあたり、以下のいずれの事項も行うことはできないものとする。<br />
                    １）本サービスを利用した営業活動 <br />
                    ２）本サービスの運営を妨害する行為<br />
                    ３）他の利用者、第三者、当事業団の名誉・信用を毀損し不利益、損害を与える行為<br />
                    ４）公序良俗に反する行為またはそのおそれのある行為 <br />
                    ５）犯罪的行為または犯罪的行為に結びつく行為 <br />
                    ６）第三者のメールアドレスを登録する行為または本サービスのWebサイトにおいて虚偽の申告、届出を行う行為 <br />
                    ７）コンピュータ・ウィルス等有害なプログラムによって当事業団の運営活動を妨害する行為 <br />
                    ８）第三者に対して、当該利用者に代わって本サービスのWebサイトにアクセスすることを委任、委託または許諾する行為<br />
                    ９）第三者から委任または委託を受けて、そのIDとパスワードを利用して本サービスのWebサイトにアクセスする行為<br />
                    １０）他人のIDとパスワードを使用して第三者になりすます行為<br />
                    １１）法令に違反する、または違反するおそれのある行為<br />
                    １２）その他、当事業団が不適切と判断する行為
                </li>
            </ol>

            第１０条 情報の無断使用の禁止
            <ol>
                <li>本サービスを通じて提供する情報や、その他本サービスのWebサイトに掲載されている文章・写真・デザイン・ロゴマーク・ソフトウェア等の著作権、商標権その他の知的財産権、肖像権・パブリシティ権、その他一切の権利は、当事業団もしくは正当な権利者に帰属する。</li>
                <li>本サービスのWebサイトに掲載されている内容のすべてまたは一部について、電子的方法または機械的方法その他方法の如何を問わず、いかなる目的であれ、当事業団または正当な権利者の事前の承諾なく、譲渡、編集、使用、複製、転載または転送等をおこなうこと、その他前項の権利を侵害することはできない。</li>
            </ol>

            第１１条 自己責任の原則
            <ol>
                <li>利用者は、利用者自身による本サービスの利用と、本サービスを利用してなされた一切の行為とその結果について一切の責任を負う。</li>
                <li>利用者は、本サービスの利用により、当事業団または他者に対して損害を与えた場合、自己の責任と費用をもって損害を賠償するものとする。</li>
                <li>第１０条に定める規定に違反して問題が生じた場合、当該利用者は自己の責任と費用においてかかる問題を解決するとともに、当事業団もしくは正当な権利者に何等の迷惑または損害を与えないものとする。</li>
            </ol>

            第１２条 利用者の退会
            <ol>
                <li>利用者は、本サービスからの退会を希望する場合、退会意思を当事業団または大同生命に申し出るものとする。なお、退会日は退会手続が完了した日とする。</li>
                <li>利用者は、退会日の翌日付けで利用資格を喪失する。</li>
                <li>利用者が本サービスへの最終ログインから１年経過した場合、判定日の翌日付けで利用資格を喪失する。</li>
            </ol>

            第１３条 利用資格の取消
            <ol>
                <li>
                    当事業団は、次のいずれかの場合には、利用者の利用資格を取り消すことができる。 <br />
                    １）利用者が法人・団体・組合の場合、当該法人等が倒産またはそれに準ずる事態、解散、営業停止処分等により事業等を停止し、またはその恐れが生じたとき<br />
                    ２）大同生命から利用者の退会申請が行われたとき<br />
                    ３）本規約、その他の付随する規約等に定める事項に違反したとき<br />
                    ４）利用者の利用申込内容に虚偽の記載があったとき<br />
                    ５）利用者が不適正な方法で本サービスを利用したことにより、本サービスの正常な運営を妨げまたは信用を傷つけていると当事業団が判断したとき
                </li>
                <li>利用者は、当事業団が利用資格の取り消しを通知した日付で利用資格を喪失するものとする。</li>
            </ol>

            第１４条 利用者データの消去
            <ol>
                <li>当事業団は、利用者が利用資格を喪失した場合、利用者の承諾を得ることなく、利用者に関するデータの全部または一部を消去することができるものとする。</li>
                <li>利用者は前項に基づくデータの消去について一切異議を述べないものとし、当事業団は前項に基づく利用者のデータの消去に関連して利用者が被った損害等について一切の責任を負わないものとする。</li>
            </ol>

            第１５条 本サービスの一時的な中断
            <ol>
                <li>
                    当事業団は、以下のいずれかの事由が生じた場合、利用者に事前に通告することなく、一時的に本サービスを中断することがある。<br />
                    １）本サービス用の設備の保守を行う場合<br />
                    ２）火災、停電等により本サービスの提供ができなくなった場合<br />
                    ３）地震、噴火、洪水、津波等の天災により本サービスの提供ができなくなった場合<br />
                    ４）戦争、暴動、騒乱、労働争議等により本サービスの提供ができなくなった場合<br />
                    ５）その他、運用上、また技術上、当事業団が本サービスの一時的な中断が必要と判断した場合
                </li>
                <li>前項各号のいずれか、またはその他の事由により本サービスの提供の遅延、または中断等が発生したとしても、当事業団はこれに起因する利用者、または他者が被った損害について、本規約で特に定める場合を除き、一切の責任を負わないものとする。</li>
            </ol>

            第１６条 個人情報の取り扱い
            <ol>
                <li>当事業団は、本規約に付随する「個人情報取扱規約」に基づき、利用者登録に際して利用者より届け出られた利用者本人を識別する情報(以下「個人情報」という)を適切に取り扱うものとする。なお、利用者は、本規約および「個人情報取扱規約」へ同意しなければ、本サービスを利用することができない。</li>
            </ol>

            第１７条 反社会的勢力の排除
            <ol>
                <li>
                    当事業団は、利用者（利用者が法人・団体・組合の場合、その代表者、役員もしくは実質的に経営等に関与する者、従業員または構成員等を含み、以下本条において同じ）が次のいずれかに該当すると認められる場合には、ただちに本サービスの利用を解除することができる。 <br />
                    １）利用者が暴力団、暴力団構成員、暴力団準構成員、暴力団関係企業、総会屋、社会運動標榜ゴロ、政治活動標榜ゴロ、特殊知能暴力集団等、暴力、威力や詐欺的手法を駆使して経済的利益を追求する集団または個人（以下「反社会的勢力」という）である場合 <br />
                    ２）利用者が、反社会的勢力に対し、出資、貸付、資金若しくは役務の提供を行っている場合またはそれに準ずる取引関係を有している場合<br />
                    ３）利用者が、当事業団または大同生命に対し、自らまたは第三者を利用して、詐術、暴力的行為、脅迫的言辞を用いた要求または法的な責任を超えた不当な要求等を行った場合
                </li>
                <li>当事業団が、前項の規定により本サービスの利用を解除した場合、この解除によって利用者に生じた損害については、当事業団は責任を負わないものとする。</li>
            </ol>

            第１８条 規約の変更
            <ol>
                <li>当事業団は、当事業団の判断により利用者の了承を得ることなく、本規約を変更でき、本サービスのWebサイト上に表示した時点より効力を発することができるものとする。</li>
            </ol>

            第１９条 準拠法および管轄裁判所
            <ol>
                <li>本規約に関する準拠法は、日本法とする。利用者と当事業団との間で紛争が生じたときは、東京地方裁判所を第一審の専属的合意管轄裁判所とする。</li>
            </ol>
        </>
    );
}

export default TermsOfService;
