import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { fetchFundRequirements } from 'api/funds';

export type FundRequirementsState = {
    type: string,
    success: boolean | null,
    message: string,
    loading: boolean,
    data: FundRequirementsRow[]
}

export type FundRequirementsRequest = {
    coverage_type?: string,
    type?: string,
    capital_range?: string,
    employee_count_range?: string,
    industry?: string,
    prefecture?: string,
    years_established_range?: string,
    page?: string,
    per_page?: string
}

export type FundRequirementsResponse = {
    code: string,
    type: number,
    title: string,
    requirements: { content: string }[],
    url: string,
    allow_consultation: boolean
}

export type FundRequirementsRow = {
    code: string,
    type: number,
    title: string,
    requirements: { content: string }[],
    url: string,
    allowConsultation: boolean
}

export const getFundRequirements = createAsyncThunk(
    'funds/requirements',
    async (_, { rejectWithValue, getState, dispatch }) => {
        try {
            let { fundsList } = getState() as RootState;
            let response = await fetchFundRequirements(fundsList.checked);

            if (response.data.success === true) {
                dispatch(setFundRequirements(response.data.data));
                return response.data.data;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const fundRequirementsSlice = createSlice({
    name: 'fundRequirements',
    initialState: {
        type: '',
        success: null,
        message: '',
        loading: false,
        data: [],
    } as FundRequirementsState,
    reducers: {
        setFundRequirements: (state, { payload }: { payload: FundRequirementsResponse[] }) => {
            state.data = payload.map(row => ({
                code: row.code,
                type: row.type,
                title: row.title,
                url: row.url,
                allowConsultation: row.allow_consultation,
                requirements: row.requirements,
            }));
        },
        resetFundRequirements: (state) => {
            state.type = '';
            state.success = null;
            state.message = '';
            state.loading = false;
            state.data = [];
        },
    },
    extraReducers: (builder) => {
        // getFundRequirements action pending
        builder.addCase(getFundRequirements.pending, (state: FundRequirementsState) => {
            state.loading = true;
            state.data = [];
        });
        // getFundRequirements action rejected
        builder.addCase(getFundRequirements.rejected, (state: FundRequirementsState, action) => {
            state.loading = false;
            state.success = false;
            state.message = action.payload as string;
        });
        // getFundRequirements action fulfilled
        builder.addCase(getFundRequirements.fulfilled, (state: FundRequirementsState) => {
            state.loading = false;
            state.success = true;
        });
    },
});

export const { setFundRequirements, resetFundRequirements } = fundRequirementsSlice.actions;
export const selectFundRequirements = (state: RootState) => state.fundRequirements;
