import React from 'react';
import { Modal } from 'antd';
import loadingIcon from 'assets/images/loader.gif';

type Props = {
    isVisible: boolean,
}

const UploadProcessingModal = (props: Props) => {
    const {isVisible} = props;

    return (
        <Modal className='change-password-modal' visible={isVisible} closable={false} footer={null}>
            <div className='title'>アップロード中</div>
            <img src={loadingIcon} alt='' className='mx-auto d-block'/>
        </Modal>
    );
};

export default UploadProcessingModal;
