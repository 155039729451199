import React, { useEffect, useMemo } from 'react';
import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import { useParams } from 'react-router-dom';
import { getTopic, selectTopic } from 'app/slice/topicSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Skeleton } from 'antd';
import { NotificationType } from 'enums';

const WorkRegulationTopic = () => {
    const { uuid } = useParams();
    const dispatch = useAppDispatch();
    const { topic, loading } = useAppSelector(selectTopic);

    useEffect(() => {
        dispatch(getTopic({
            type: NotificationType.KISOKU,
            uuid: uuid!,
        }));
    }, [dispatch, uuid]);

    const breadcrumbs: Breadcrumb[] = [
        {
            name: 'ホームページ',
            url: '/kisoku',
        },
        {
            name: '就業規則に関連するトピックス',
            url: '/kisoku/topics',
        },
        {
            name: topic?.title || '',
            url: '',
        },
    ];

    const content = useMemo(() => {
        let element = document.createElement('div');
        element.innerHTML = topic?.content;

        for (let a of element.getElementsByTagName('a')) {
            a.setAttribute('target', '_blank');
        }

        return element.innerHTML;
    }, [topic?.content]);

    return (
        <>
            <section className='breadcrumbs'>
                <Breadcrumbs breadcrumb={breadcrumbs} />
            </section>
            <section className='topic-container'>
                {loading ? (
                    <Skeleton active />
                ) : (
                    <>
                        <div className='title'>{topic?.title}</div>
                        <span className='badge'>{topic?.sendDateList}</span>
                        <p className='mt-2 content' dangerouslySetInnerHTML={{ __html: content }} />
                    </>
                )}
            </section>
        </>
    );
};

export default WorkRegulationTopic;
