import { createAsyncThunk } from '@reduxjs/toolkit';
import { searchLogClick } from 'api/searchLog';

export type SearchLogRequest = {
    type?: number,
    purpose?: number,
    keyword?: string,
}

/**
 * GET insurance plans
 **/
export const searchLog = createAsyncThunk(
    'resource/searchLog',
    async (searchLogRequest: SearchLogRequest) => {
        try {
            await searchLogClick(searchLogRequest);
            return true;
        } catch (err) {
            return false;
        }
    },
);
