import React from 'react';
import { Form, Radio } from 'antd';
import { Schedule } from 'app/slice/applicationDetailsSlice';

type Props = {
    schedules: Schedule[],
}

const ScheduleSelection = (props: Props) => {
    const { schedules } = props;
    const { Item } = Form;
    const { Group } = Radio;

    const scheduleRadioButtons = (schedule: Schedule, index: number) => {
        const numbers = ['一', '二', '三', '四', '五'];

        if (numbers[index]) {
            return (
                <Radio className='w-100' key={index} value={schedule} disabled={schedule.expired}>
                    <strong>第{numbers[index]}希望：</strong>{schedule.content}
                </Radio>
            );
        }

        return '';
    };

    return (
        <div>
            <hr />
            <div className='profile-card-spacing'>
                <div className='profile-card-title label-text'>日程：</div>
                <Item
                    name='schedule'
                    rules={[{ required: true, message: '日程を選択してください' }]}
                >
                    <Group className='profile-card-selection w-100'>
                        {schedules.map((schedule: Schedule, index) => scheduleRadioButtons(schedule, index))}
                    </Group>
                </Item>
            </div>

            <div className='profile-card-spacing'>
                <div className='profile-card-title label-text'> 大同生命営業担当者：</div>
                <Item
                    name='daidoJoins'
                    rules={[{ required: true, message: '同席希望を選択してください' }]}
                >
                    <Group className='profile-card-selection w-100'>
                        <Radio className='w-100' value={1}><strong>同席を希望します</strong></Radio>
                        <Radio className='w-100' value={0}><strong>同席を希望しません</strong></Radio>
                    </Group>
                </Item>
            </div>

            <div className='profile-card-note'>
                <p>※大同生命営業担当者の同席の有無については、事前に大同生命営業担当者にご相談の上、ご選択ください。</p>
                <p>※都合が合わない場合、同席で出来ないこともありますので予めご了承ください。</p>
            </div>
        </div>
    );
};

export default ScheduleSelection;
