import { Button, Card, List } from 'antd';
import { UploadFileType } from 'enums';
import { CloseOutlined, FilePdfOutlined } from '@ant-design/icons';
import React from 'react';
import { UploadFile } from 'antd/es/upload/interface';

type Props = {
    uploadedFile: UploadFile | null;
    uploadedFileList: UploadFile[];
    setUploadedFileList: (uploadedFileList: UploadFile[]) => void;
}

type UploadRemoveItemProps = {
    file: UploadFile;
    uploadedFileList: UploadFile[];
    setUploadedFileList: (uploadedFileList: UploadFile[]) => void;
}

const UploadListRemoveButton = (props: UploadRemoveItemProps) => {
    const { file, uploadedFileList, setUploadedFileList } = props;
    const removeFileFromList = () => {
        setUploadedFileList(uploadedFileList.filter(item => item.uid !== file.uid));
    };
    return (
        <Button onClick={removeFileFromList}><CloseOutlined /></Button>
    );
};

const UploadedFileList = (props: Props) => {
    const { uploadedFile, uploadedFileList, setUploadedFileList } = props;
    return (
        <>
            {uploadedFile !== null &&
            <Card className='mx-auto ant-upload ant-upload-drag uploaded-file'>
                <List
                    dataSource={uploadedFileList}
                    renderItem={item => (
                        <>
                            {item.type === UploadFileType.PDF_FILE ?
                                <List.Item key={item.name}
                                           actions={[<UploadListRemoveButton file={item}
                                                                             setUploadedFileList={setUploadedFileList}
                                                                             uploadedFileList={uploadedFileList} />]}
                                >
                                    <List.Item.Meta
                                        avatar={<FilePdfOutlined className='red-3 pdf-upload-icon' />}
                                        title={<span className={'red-3'}>{item?.name}</span>} />
                                </List.Item>
                                : (item.type === UploadFileType.DOC_FILE || item.type === UploadFileType.DOCX_FILE) && (
                                <List.Item key={item.name}
                                           actions={[<UploadListRemoveButton file={item}
                                                                             setUploadedFileList={setUploadedFileList}
                                                                             uploadedFileList={uploadedFileList} />]}
                                >
                                    <List.Item.Meta
                                        avatar={<FilePdfOutlined className='primary-color pdf-upload-icon' />}
                                        title={<span className={'primary-color'}>{item?.name}</span>} />
                                </List.Item>
                            )}
                        </>
                    )}
                />
            </Card>
            }
        </>
    );
};
export default UploadedFileList;
