import React, { useEffect, useState } from 'react';
import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import { Button, Card, Checkbox, Col, Form, message, Row } from 'antd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { setSuccessState, SuccessModalState } from 'app/slice/successSlice';
import checkIcon from 'assets/images/success_check.png';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { getEvaluationItems, selectResources } from 'app/slice/resourcesSlice';
import { addUserEvaluationMeeting, reset, ReviewDetails, selectMeeting } from 'app/slice/meetingSlice';
import SpecialistDetails from 'components/consultation/SpecialistDetails';
import questionIcon from 'assets/images/icon_question.png';
import CustomModal from 'components/common/CustomModal';

export type SpecialistReviewData = {
    image: string;
    name: string;
    officeName: string;
    address: string;
    status: boolean;
    fundTitle: string;
    schedule: string;
}

type Evaluations = {
    code:string,
    name:string,
    category:string
}

type ScheduleData = {
    scheduleData: ReviewDetails
}

const SpecialistReviewDetails = () => {
    const state = useLocation().state as ScheduleData;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { status } = useParams();
    const dispatch = useAppDispatch();
    const [isButtonEnabled, setButtonEnabled] = useState(false);

    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const onCloseConfirmModal = () => setIsConfirmModalVisible(false);
    const onOpenConfirmModal = () => setIsConfirmModalVisible(true);

    const onCheckboxChange = (checkedValues: CheckboxValueType[]) => {
        setButtonEnabled(checkedValues.length >= 1);
    };

    const {
        evaluationItems,
    } = useAppSelector(selectResources);

    const {
        addMeetingStatus,
    } = useAppSelector(selectMeeting);

    useEffect(() => {
        if (state == null || (addMeetingStatus === 'success' && status === 'to-review')) {
            // Redirect to review list if detail page is accessed directly
            message.warn('アクセスが無効です。メニューでもう一度選択してください');
            navigate('/dashboard/specialist-review/');
        }
    }, [addMeetingStatus, navigate, state, status]);

    //Reset add meeting status
    useEffect(() => {
        if (status === 'to-review') {
            dispatch(reset());
        }
    }, [dispatch, status]);

    useEffect(() => {
        dispatch(getEvaluationItems());
    }, [dispatch, state]);

    const breadcrumbs: Breadcrumb[] = [
        {
            name: '評価一覧',
            url: '/dashboard/working-regulation/specialist-review',
        },
        {
            name: '評価',
            url: '',
        },
    ];

    const renderSuccessModal = () => {
        const successModalPayload: SuccessModalState = {
            isShow: true,
            title: '面談評価完了',
            message: 'ご協力ありがとうございました。',
            withButton: true,
            image: checkIcon,
            buttonTitle: '閉じる',
        };

        dispatch(setSuccessState(successModalPayload));

        // check if state schedule data has a value before initializing a value
        if (state?.scheduleData) {
            state.scheduleData.evaluations = evaluationItems.filter(data => (
                form.getFieldValue('evaluatedItems[]').includes(data.code)
            ));
        }

        navigate('/dashboard/working-regulation/specialist-review/details/reviewed', { state: state });
    };

    const onFinish = () => {
        onCloseConfirmModal();
        dispatch(addUserEvaluationMeeting({
            form: form.getFieldsValue(),
            scheduleId: state?.scheduleData?.scheduleId,
        }));
        renderSuccessModal();
    };

    const specialistReviewForm = () => {
        return (
            <>
                <Form
                    form={form}
                    className='mt-4'
                    layout='vertical'
                    autoComplete='off'
                    onFinish={onOpenConfirmModal}
                >
                    <Card className='feedback-container'>
                        <h1>以下のうち該当するものにチェックを付けてください。（任意）</h1>
                        <h2>※本評価の内容は、大同生命および中小企業福祉事業団がサービス活用状況の集計のために利用します。面談を行った専門家には共有されませんので、あらかじめご了承ください。</h2>
                        <Form.Item
                            className='mb-1'
                            name='evaluatedItems[]'
                            rules={[{ required: true, message: 'アイテムを選択してください' }]}
                        >
                            <Checkbox.Group className='d-block' onChange={onCheckboxChange}>
                                <h3><strong>専門家の良かった点を選択してください。</strong>（複数選択可）</h3>
                                <Row gutter={[6, 6]}>
                                    {evaluationItems?.map((data) => data.category === 'expert' && (
                                        <Col md={24}>
                                            <Checkbox value={data.code}>{data.name}</Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                                <h3><strong>専門家に望む点を選択してください。</strong>（複数選択可）</h3>
                                <Row gutter={[6, 6]}>
                                    {evaluationItems?.map((data) => data.category === 'question' && (
                                        <Col md={24}>
                                            <Checkbox value={data.code}>{data.name}</Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </Card>
                    <div className='text-center mt-4 feedback-buttons'>
                        <Link to='/dashboard/working-regulation/specialist-review'>
                            <Button>キャンセル</Button>
                        </Link>
                        <Button
                            disabled={!isButtonEnabled}
                            htmlType='submit'
                            type='primary'
                        >評価を登録する</Button>
                    </div>
                </Form>
            </>
        );
    };
 
    const specialistReviewDisplay = () => {
        const evaluation: Evaluations[] = state?.scheduleData?.evaluations;
        return (
            <>
                <Card className='feedback-container'>
                    <h3><strong>専門家の良かった点：</strong></h3>
                    <Row gutter={[6, 6]}>
                        {evaluation?.map((data) => data.category === 'expert' && (
                            <Col md={24}>
                                {data.name}
                            </Col>
                        ))}
                    </Row>
                    <h3><strong>専門家に望む点：</strong></h3>
                    <Row gutter={[6, 6]}>
                        {evaluation?.map((data) => data.category === 'question' && (
                            <Col md={24}>
                                {data.name}
                            </Col>
                        ))}
                    </Row>
                </Card>

                <div className='text-center mt-30px'>
                    <Link to='/dashboard/working-regulation/specialist-review'>
                        <Button className='me-20px w-200px'>戻る</Button>
                    </Link>
                </div>
            </>
        );
    };

    return (
        <>
            <Breadcrumbs breadcrumb={breadcrumbs} />
            <div className='content specialist-review'>
                <div className='title'>評価一覧</div>
                <SpecialistDetails
                    reviewData={state?.scheduleData}
                    isKoteki={false}
                />
                <Card className='fund-info'>
                    <Row>
                        <Col md={2} xs={24}>
                            <strong>内容:</strong>
                        </Col>
                        <Col md={14} xs={24}>
                            {state?.scheduleData?.fundTitle}
                        </Col>
                        <Col offset={1} md={6} xs={24}>
                            <strong>日程:</strong> {state?.scheduleData?.schedule}
                        </Col>
                    </Row>
                </Card>
                {status === 'to-review' ? specialistReviewForm() : specialistReviewDisplay()}
            </div>

            <CustomModal
                isVisible={isConfirmModalVisible}
                onClose={onCloseConfirmModal}
                title='面談評価登録の確認'
                icon={questionIcon}
                text={<div>評価を登録してもよろしいですか？<br/>一度登録した評価の修正はできませんので、あらかじめご了承ください。</div>}
                showCancelButton={true}
                cancelButtonText='キャンセル'
                showConfirmButton={true}
                confirmButtonText='登録'
                confirmOnClick={onFinish}
                cancelOnClick={onCloseConfirmModal}
            />
        </>
    );
};

export default SpecialistReviewDetails;
