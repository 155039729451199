import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    CANCEL_POLICY_APP,
    GET_POLICY_APP,
    getPolicyApplicationDetails,
    reset,
    selectPolicyApplicationDetails,
} from 'app/slice/policyApplicationDetailsSlice';
import { MeetingStatuses } from 'enums';
import { Link, useParams } from 'react-router-dom';
import { Button, Skeleton } from 'antd';
import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import CancelMeetingModal from 'components/dashboard/working_regulation/CancelMeetingModal';
import Applied from 'components/dashboard/working_regulation/Applied';
import Waiting from 'components/dashboard/working_regulation/Waiting';
import Canceled from 'components/dashboard/working_regulation/Canceled';
import Finished from 'components/dashboard/working_regulation/Finished';
import Scheduled from 'components/dashboard/working_regulation/Scheduled';
import PastDue from 'components/dashboard/working_regulation/PastDue';
import { selectSearchQuery } from 'app/slice/searchQuerySlice';

type Params = {
    uuid: string;
};

const statusesToNotDisplayCancelButton: MeetingStatuses[] = [
    MeetingStatuses.FINISHED,
    MeetingStatuses.CANCELED,
    MeetingStatuses.PAST_DUE,
];

const WorkingRegulationMeetingDetails = () => {
    const [isCancelMeetingVisible, setCancelMeetingVisible] = useState(false);
    const onOpenCancelMeeting = () => setCancelMeetingVisible(true);
    const onCloseCancelMeeting = () => setCancelMeetingVisible(false);

    // Constants for Application Details Display
    const { loading, data, success, type } = useAppSelector(selectPolicyApplicationDetails);
    const { searchQuery } = useAppSelector(selectSearchQuery);
    const dispatch = useAppDispatch();
    const { uuid } = useParams<Params>();

    // Get Application Details
    useEffect(() => {
        // Reset data to make sure previous state is not displayed
        dispatch(reset());

        if (uuid) {
            dispatch(getPolicyApplicationDetails(uuid));
        }
    }, [dispatch, uuid]);

    // Update details
    useEffect(() => {
        if (success) {
            if (type === CANCEL_POLICY_APP) {
                onCloseCancelMeeting();
            }

            if (type !== GET_POLICY_APP && uuid) {
                dispatch(getPolicyApplicationDetails(uuid));
            }
        }
    }, [dispatch, success, type, uuid]);

    const renderMeetingDetailPage = () => {
        switch (data.label?.content) {
            case MeetingStatuses.APPLIED:
                return <Applied application={data} />;
            case MeetingStatuses.SCHEDULED:
                return <Scheduled application={data} />;
            case MeetingStatuses.WAITING:
                return <Waiting application={data} />;
            case MeetingStatuses.FINISHED:
                return <Finished application={data} />;
            case MeetingStatuses.CANCELED:
                return <Canceled application={data} />;
            case MeetingStatuses.PAST_DUE:
                return <PastDue application={data} />;
            default:
                return '';
        }
    };

    const getHeaderClass = () => {
        let className = 'card-container';
        switch (data.label?.content) {
            case MeetingStatuses.APPLIED:
                className += ' orange';
                break;
            case MeetingStatuses.SCHEDULED:
                className += ' light-blue';
                break;
            case MeetingStatuses.WAITING:
                className += ' orange';
                break;
            case MeetingStatuses.FINISHED:
                className += ' green';
                break;
            case MeetingStatuses.CANCELED:
                className += ' red';
                break;
            case MeetingStatuses.PAST_DUE:
                className += ' purple';
        }

        return className;
    };

    const breadcrumbs: Breadcrumb[] = [
        {
            name: '面談一覧',
            url: '/dashboard/working-regulation/meetings' + searchQuery,
        },
        {
            name: '面談詳細',
            url: '',
        },
    ];

    return (
        <>
            {loading ? (
                <Skeleton active />
            ) : (
                <>
                    <Breadcrumbs breadcrumb={breadcrumbs} />
                    <div className='content kisoku-meetings'>
                        {!statusesToNotDisplayCancelButton.includes(data.label?.content as MeetingStatuses) ?
                            <div className='action-buttons'>
                                <Button onClick={onOpenCancelMeeting} danger>面談をキャンセル</Button>
                            </div> : null
                        }
                        <div className='title'>面談詳細</div>
                        <div className={getHeaderClass()}>
                            <div>{data.type}</div>
                            <strong className='ms-auto status-finished'
                                    style={{ color: `${data.label?.color}` }}
                            >
                                {data.label?.content}
                            </strong>
                        </div>
                        {renderMeetingDetailPage()}

                        {data.label?.content !== MeetingStatuses.WAITING ?
                            <div className='text-center mt-5'>
                                <Link to={`/dashboard/working-regulation/meetings`}>
                                    <Button type='primary' className='w-200px'>戻る</Button>
                                </Link>
                            </div> : null
                        }

                    </div>
                    <CancelMeetingModal isVisible={isCancelMeetingVisible} uuid={uuid!}
                                        onClose={onCloseCancelMeeting}
                    />
                </>
            )}
        </>
    );
};

export default WorkingRegulationMeetingDetails;
