import { getAxiosClientWithToken } from './index';

export const getNotifications = (page: number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/notifications', { params: { page: page } });
};

export const getNotViewedNotifications = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/notifications/notViewed');
};

export const markingAsViewed = (id: number) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch('/notifications/' + id);
};


