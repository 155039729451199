import React, { Fragment } from 'react';
import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import { Col, Collapse, Row } from 'antd';
import EmailInquiryForm from 'components/common/EmailInquiryForm';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { QuestionAndAnswer } from 'types';

/**
 * Props for Breadcrumb
 */
const breadcrumbs: Breadcrumb[] = [
    {
        name: 'ホームページ',
        url: '/kisoku',
    },
    {
        name: 'Q&A',
        url: '/kisoku/inquiries',
    },
];

const { Panel } = Collapse;

const questionAndAnswer: QuestionAndAnswer[] = [
    {
        id: 1,
        question: 'サービスは無料で使えますか？',
        answer: 'はい。大同生命のお客さまであれば、ご契約有無に関わらずどなたでも無料でご利用いただけます。ご利用開始にあたっては、お客さまの基本情報をご登録いただく必要がありますので、まずは大同生命の営業担当者までご連絡をお願いいたします。'
    },
    {
        id: 2,
        question: 'サービス利用の流れを教えてください。',
        answer: '① 就業規則診断のお申し込み\n' +
            'お客さまの就業規則のファイルをアップロードすることでお申し込みが完了 します。\n\n' +
            '② 就業規則を簡易診断\n' +
            'アップロードされた就業規則のファイルを社会保険労務士が簡易診断したうえで、診断レポートを提供いたします。\n\n' +
            '③ 社会保険労務士への相談サービス申込\n' +
            '診断レポートをもとに、お客さまのご希望により社会保険労務士への相談サービスをお申し込みいただけます。※任意。\n\n' +
            '※就業規則診断および社会保険労務士への相談サービスをご利用になる際は、ログインが必要です。'
    },
    {
        id: 3,
        question: 'ログインする方法が分からないので、教えてください。',
        answer: 'はじめにお客さまの基本情報をご登録いただく必要があります。登録の方法につきましては、大同生命の営業担当者までご連絡をお願いいたします。'
    },
    {
        id: 4,
        question: 'ログイン用のメールアドレス、パスワードを忘れてしまいました。',
        answer: 'メールアドレスをお忘れの方は、大同生命の営業担当者までご連絡をお願いいたします。\n' +
            'パスワードをお忘れの方は、画面右上の「ログイン」ボタンをクリックした後に、「パスワードをお忘れの方」をクリックしてください。パスワードの再設定ができます。'
    },
    {
        id: 5,
        question: '就業規則の他に規程があるのですが、診断・相談は可能ですか？',
        answer: '就業規則の他に、賃金規程、育児介護休業規程について、診断・相談サービスをご利用になれます。\n' +
            'その他の附属規程等は本サービスの対象外となりますので、あらかじめご了承ください。'
    },
    {
        id: 6,
        question: '専門家の社会保険労務士とはどんな人ですか？',
        answer: 'サービス提供元である中小企業福祉事業団の会員社会保険労務士です。'
    },
    {
        id: 7,
        question: '相談はオンラインのみですか？',
        answer: '本サービスではオンラインでのご相談を基本としております。'
    },
    {
        id: 8,
        question: '面談の開始時刻は、10時00分など、ちょうどの時間からしかできないのですか？',
        answer: '本サービスでは、10時～16時までの間の、正時（ちょうどの時間）が開始時間となります。\n' +
            '10時～\n' +
            '11時～\n' +
            '12時～\n' +
            '13時～\n' +
            '14時～\n' +
            '15時～\n' +
            '16時～\n' +
            '※初回無料の面談時間は30分間です。'
    },
    {
        id: 9,
        question: '社会保険労務士に相談するのに費用はかかりますか？',
        answer: '初回30分は無料で相談できます。\n' +
            'なお、2回目以降のご相談や、就業規則の変更などの実務が発生する場合は、専門家に直接ご相談ください。'
    },
    {
        id: 10,
        question: '社会保険労務士に相談できるのは1回だけですか？',
        answer: '原則として1回だけとなります。'
    },
];

const WorkRegulationInquiries = () => {

    const PanelHeader = (question: string) => {
        return (
            <Row className='mt-3'>
                <Col md={1} xs={2}>
                    <span className='q-char'>Q</span>
                </Col>
                <Col md={23} xs={22}>
                    <p className='q-question'>{question}</p>
                </Col>
            </Row>
        );
    };

    return (
        <div className='inquiries'>
            <section className='breadcrumbs'>
                <Breadcrumbs breadcrumb={breadcrumbs}/>
            </section>
            <section className='static-content'>
                {questionAndAnswer.map((questionAndAnswer, key) =>
                    <Fragment key={key}>
                        <Collapse expandIconPosition='right'
                                  expandIcon={({isActive}) => isActive ? <UpOutlined/> : <DownOutlined/>}
                        >
                            <Panel header={PanelHeader(questionAndAnswer.question)} key={key}>
                                <Row>
                                    <Col md={1} xs={2}>
                                        <div className='a-char'>A</div>
                                    </Col>
                                    <Col md={23} xs={22}>
                                        <div className='a-content'>
                                            {questionAndAnswer.answer}
                                        </div>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                        <br/>
                    </Fragment>
                )}

                <br/>
                <EmailInquiryForm/>
            </section>
        </div>
    );
};

export default WorkRegulationInquiries;
