import React, { useEffect } from 'react';
import { Button, Calendar, Col, ConfigProvider, Row, Tag } from 'antd';
import locale from 'antd/lib/locale/ja_JP';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import moment, { Moment } from 'moment';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getUserScheduledMeetings, selectMeeting } from 'app/slice/meetingSlice';
import { Link } from 'react-router-dom';

type CalendarHeaderConfig = {
    value: Moment;
    type: CalendarMode;
    onChange: (date: Moment) => void;
    onTypeChange: (type: CalendarMode) => void;
}

type ListData = {
    content: string,
    uuid: string,
    fundType: number
}

const MeetingCalendar = () => {
    const {
        schedules,
        meetingsFromPublicSupportCount,
        meetingsFromWorkRegulationCount,
    } = useAppSelector(selectMeeting);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getUserScheduledMeetings());
    }, [dispatch]);

    const getListData = (value: Moment) => {
        let listData: ListData[] = [];
        schedules.forEach(sched => {
            const scheduledDate = moment(sched.schedule).format('YYYY/MM/DD');
            const calendarDate = moment(value).format('YYYY/MM/DD');
            if (calendarDate === scheduledDate) {
                listData.push({ content: moment(sched.schedule).format('HH:mm') + ' - ' + sched.specialist, uuid: sched.application_uuid, fundType: sched.fund_type });
            }
        });
        return listData;
    };

    const CalendarHeader = (config: CalendarHeaderConfig) => {
        const onClickPrev = () => {
            let newValue = config.value.clone().add(-1, 'month');
            config.onChange(newValue);
        };

        const onClickNext = () => {
            let newValue = config.value.clone().add(1, 'month');
            config.onChange(newValue);
        };

        return (
            <Row>
                <Col md={{offset: 16, span: 8}} xs={24} className='month-picker'>
                    <Button onClick={onClickPrev}><LeftOutlined/></Button>
                    <span className='month-picker-text'>{config.value.format('YYYY年M月')}</span>
                    <Button onClick={onClickNext}><RightOutlined/></Button>
                </Col>
            </Row>
        );
    };

    const dateCellRender = (value: Moment) => {
        const listData: ListData[] = getListData(value);
        return (
            <ul className='events'>
                {listData.map((item, index) => {
                    const currentType = item.fundType === 4 ? '/kouteki/consultation/' + item.uuid + '/details': '/working-regulation/meetings/' + item.uuid;
                    return (
                    <li key={index}>
                        <Tag color='blue'><Link to={'/dashboard' + currentType}>{item.content}</Link></Tag>
                    </li>)
                })}
            </ul>
        );
    };

    return (
        <>
            {schedules && (
                <div className='content dashboard-calendar'>
                    <div className='title'>ご利用情報</div>
                    <div className='meeting-count'>
                        <Row gutter={16} align='middle'>
                            <Col className='gutter-row meeting-count-title' md={12} xs={24}>
                                公的支援オンラインサービス
                            </Col>
                            <Col className='gutter-row' md={8} xs={12}>
                                面談数：{meetingsFromPublicSupportCount}
                            </Col>
                            <Col className='gutter-row' md={4} xs={12}>
                                <Link to='/dashboard/kouteki/consultation-list'>
                                    <Button className='secondary-button'>詳細</Button>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                    <div className='meeting-count'>
                        <Row gutter={16} align='middle'>
                            <Col className='gutter-row meeting-count-title' md={12} xs={24}>
                                就業規則診断オンラインサービス
                            </Col>
                            <Col className='gutter-row' md={8} xs={12}>
                                面談数：{meetingsFromWorkRegulationCount}
                            </Col>
                            <Col className='gutter-row' md={4} xs={12}>
                                <Link to='/dashboard/working-regulation/meetings'>
                                    <Button className='secondary-button'>詳細</Button>
                                </Link>
                            </Col>
                        </Row>
                    </div>

                    <hr className='divider' />

                    <div className='subtitle'>面談数：0件</div>
                    <ConfigProvider locale={locale}>
                        <Calendar
                            dateCellRender={dateCellRender}
                            headerRender={CalendarHeader}
                        />
                    </ConfigProvider>
                </div>
            )}
        </>
    );
};

export default MeetingCalendar;
