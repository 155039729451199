import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    ApplicationDetails,
    getApplicationDetails,
    Schedule,
    ScheduleConsultationRequest,
    selectApplicationDetails,
    SET_APPLICATION_SCHEDULE,
    setApplicationSchedule,
} from 'app/slice/applicationDetailsSlice';
import SpecialistDetails from 'components/consultation/SpecialistDetails';
import ConsultationChecklists from 'components/consultation/details/ConsultationChecklists';
import ScheduleConfirmationModal from 'components/consultation/ScheduleConfirmationModal';
import { Link, useNavigate } from 'react-router-dom';
import { selectSearchQuery } from 'app/slice/searchQuerySlice';

type Props = {
    application: ApplicationDetails
}

const rules = {
    emergencyContactNumber: [
        { required: true, message: '当日の緊急連絡先（電話番号）をご入力ください' },
        { pattern: new RegExp(/^[0-9]{1,3}[-\s/0-9]*$/g), message: '形式が間違っています。' }
    ],
};

const WaitingConfirmation = (props: Props) => {
    const { application } = props;
    const [form] = Form.useForm();

    const [isScheduleConfirmationModalVisible, setIsScheduleConfirmationModalVisible] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { type, loading } = useAppSelector(selectApplicationDetails);
    const { searchQuery } = useAppSelector(selectSearchQuery);

    const onOpenScheduleConfirmationModal = () => setIsScheduleConfirmationModalVisible(true);
    const onCloseScheduleConfirmationModal = () => {
        // Update Application Details before redirecting the user
        dispatch(getApplicationDetails(application.uuid));
        setIsScheduleConfirmationModalVisible(false);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        navigate(`/dashboard/kouteki/consultation/${application.uuid}/details`);
    };

    const onSubmit = async (values: { schedule: Schedule, daidoJoins: boolean, emergencyContactNumber: string }) => {
        await dispatch(setApplicationSchedule({
            uuid: application.uuid,
            specialist_uuid: values.schedule.uuid,
            number: values.schedule.number,
            daido_joins: values.daidoJoins,
            emergency_contact_number: values.emergencyContactNumber,
        } as ScheduleConsultationRequest));

        onOpenScheduleConfirmationModal();
    };

    return (
        <>
            <ConsultationChecklists application={application} />
            <Form
                form={form}
                onFinish={onSubmit}
                layout='vertical'
                autoComplete='off'
            >
                <SpecialistDetails schedules={application.chukidanSchedules!} />

                <div className='consultation-select-note'>
                    <p>※本依頼内容は、面談の管理のため、同席の希望状況に関わらず大同生命営業担当者に共有されます。あらかじめご了承ください。</p>
                </div>
                <div>
                    <Form.Item
                        className='w-350px'
                        name='emergencyContactNumber'
                        label='当日の緊急連絡先（電話番号）をご入力ください（必須）'
                        rules={rules.emergencyContactNumber}
                    >
                        <Input/>
                    </Form.Item>
                </div>
                <div className='text-center consultation-select-schedule-controls mt-30px'>
                    <Link to={`/dashboard/kouteki/consultation-list` + searchQuery}>
                        <Button>戻る</Button>
                    </Link>
                    <Button type='primary' htmlType='submit' loading={loading && type === SET_APPLICATION_SCHEDULE}>
                        依頼する
                    </Button>
                </div>
            </Form>
            <ScheduleConfirmationModal application={application}
                                       isVisible={isScheduleConfirmationModalVisible}
                                       onClose={onCloseScheduleConfirmationModal}
            />
        </>

    );
};

export default WaitingConfirmation;
