import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { emailInquire } from 'api/inquire';

export type INQUIRE_STATUS = 'success' | 'failed' | 'pending';

type InquireState = {
    loading: boolean,
    success: boolean,
    status: INQUIRE_STATUS,
    message: string
}

export type InquireRequest = {
    inquirer_name: string,
    company_name: string,
    email: string,
    inquiries: string,
    type: string
}

/**
 * inquire
 **/
export const inquire = createAsyncThunk(
    'inquire',
    async (data: InquireRequest, { getState, rejectWithValue }) => {
        const { inquirer_name, company_name, email, inquiries, type } = data;
        // @ts-ignore
        const { loading } = getState().inquire;
        if (!loading) {
            return;
        }
        try {
            const response = await emailInquire(inquirer_name, company_name, email, inquiries, type);
            return response.data;
        } catch (err) {
            // We got validation errors, let's return those so we can reference in our component and set form errors
            return rejectWithValue(false);
        }
    },
);

const initialState: InquireState = {
    loading: false,
    success: false,
    status: 'pending',
    message: '',
};

export const inquireSlice = createSlice({
    name: 'inquire',
    initialState: initialState,
    reducers: {
        reset: (state: InquireState) => {
            state.success = false;
            state.loading = false;
            state.status = 'pending';
            state.message = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase((inquire.pending), (state) => {
            state.loading = true;
            state.status = 'pending';
        });
        builder.addCase((inquire.fulfilled), (state) => {
            state.loading = false;
            state.status = 'success';
            state.success = true;
        });
        builder.addCase((inquire.rejected), (state) => {
            state.loading = false;
            state.status = 'failed';
        });
    },
});

export const { reset } = inquireSlice.actions;
