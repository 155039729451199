import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getUserNotification, selectNotification } from 'app/slice/notificationSlice';
import UnreadNotification from 'components/dashboard/notification/UnreadNotification';
import ReadNotification from 'components/dashboard/notification/ReadNotification';
import { Pagination } from 'antd';

const Notifications = () => {
    const dispatch = useAppDispatch();
    const { notificationData, total, perPage } = useAppSelector(selectNotification);
    const [page, setPage] = useState(1);

    useEffect(() => {
        dispatch(getUserNotification(1));
    }, [dispatch]);

    // get notifications if page changes
    useEffect(() => {
        dispatch(getUserNotification(page));
    }, [dispatch, page, total]);

    // get 20 notification on change page
    const onChangePage = (page: number) => {
        setPage(page);
    };

    // Return scroll to top on page change only
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, [page]);

    return (
        <div className='content dashboard-notifications'>
            <div className='title'>新着情報</div>
            {notificationData?.map(items => {
                if (items.viewedAt) {
                    return <ReadNotification key={items.id}
                                             title={items.message}
                                             date={items.createdAt}
                                             uuid={items.uuid}
                                             type={items.type}
                    />;
                } else {
                    return <UnreadNotification key={items.id}
                                               title={items.message}
                                               date={items.createdAt}
                                               uuid={items.uuid}
                                               id={items.id}
                                               type={items.type}
                    />;
                }
            })}
            {total > 0 && (<div className='text-center'>
                <Pagination className='d-inline float-center'
                            pageSize={perPage}
                            current={page}
                            total={total}
                            onChange={onChangePage}
                            showSizeChanger={false}
                />
            </div>)}
        </div>
    );
};

export default Notifications;
