import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

type Props = {
    containerClass?: string,
    image?: string | null
}

/**
 * Creates header for top pages
 * @param { Props } props
 * @return html
 * */
const TopLandingHeader = (props: PropsWithChildren<Props>) => {
    const { containerClass = '', image } = props;

    return (
        <section className={classNames('header', containerClass)}>
            <div className='content'>
                {image && <img src={image} alt='' />}
                {props.children}
            </div>
        </section>
    );
};

export default TopLandingHeader;
