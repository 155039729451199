import React, { useEffect } from 'react';
import { Button, Card, Col, Modal, Row } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { setSuccessState, SuccessModalState } from 'app/slice/successSlice';
import checkIcon from 'assets/images/success_check.png';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    CREATE_POLICY_APP,
    createCompanyPolicyApplication,
    CreateCompanyPolicyApplicationRequest,
    selectCompanyPolicies,
    Topic,
    Type,
} from 'app/slice/policySlice';
import { MeetingRequest } from './RequestMeetingModal';

type Props = {
    isVisible: boolean,
    onClose: () => void,
    onSuccess: () => void,
    request?: MeetingRequest,
    types: Type[],
}

const RequestMeetingConfirmationModal = (props: Props) => {
    const { isVisible, onClose, onSuccess, request, types } = props;
    const dispatch = useAppDispatch();

    const onRequestSubmit = () => {
        let codes = [] as string[];
        request?.topics?.forEach((topic: Topic) => {
            codes.push(topic.code);
        });

        let data = {
            daido_joins: request?.daidoJoins,
            type: request?.type,
            topics: codes,
            remarks: request?.remarks,
        } as CreateCompanyPolicyApplicationRequest;

        dispatch(createCompanyPolicyApplication(data));
    };

    const { success, type } = useAppSelector(selectCompanyPolicies);

    // Show success modal on successful upload
    useEffect(() => {
        if (success && type === CREATE_POLICY_APP) {
            onSuccess();
            const successModalPayload: SuccessModalState = {
                isShow: true,
                title: '面談依頼が完了しました',
                message: '本日を含め、土日・祝日を除く３日を過ぎると「回答期限切れ」となります 。以内に、専門家の社会保険労務士から面談の候補日時のメールが届きますので、しばらくお待ちください。',
                withButton: true,
                image: checkIcon,
                buttonTitle: '確認',
            };
            dispatch(setSuccessState(successModalPayload));
        }
    }, [dispatch, onSuccess, success, type]);

    return (
        <Modal className='request-meeting-modal' visible={isVisible} onCancel={onClose} footer={null}>
            <div className='title'>面談依頼確認</div>

            {request?.daidoJoins ?
                <Card className='daido-joins-card'>
                    <CheckOutlined className='attendance-check-icon mx-2' /> 大同生命営業担当者の同席を希望します。
                </Card>
                :
                <Card className='daido-joins-card'>
                    <CloseOutlined className='attendance-check-icon mx-2' /> 大同生命営業担当者の同席は希望しません。
                </Card>
            }

            <Card className='meeting-request-card my-2'>
                <Row>
                    <Col md={8}>
                        <strong>面談を行う規程</strong>
                    </Col>
                    <Col md={16}>
                        {types?.find(type => +type.code === request?.type)?.name}
                    </Col>

                    <Col md={8} className='mt-2'>
                        <strong>特にご相談したいこと</strong>
                    </Col>
                    <Col md={16}>
                        <ul>
                            {request?.topics?.map((topic, key) =>
                                <li key={key}>{topic.name}</li>,
                            )}
                        </ul>
                    </Col>

                    <Col md={8} className='mt-2'>
                        <strong>相談内容</strong>
                    </Col>
                    <Col md={16}>
                        {request?.remarks}
                    </Col>
                </Row>
            </Card>

            <div className='my-4 action-buttons'>
                <Row gutter={[10, 10]}>
                    <Col span={12}>
                        <Button className='me-3' htmlType='button' onClick={onClose} block>
                            戻る
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button type='primary' htmlType='submit' onClick={onRequestSubmit} block>
                            申込
                        </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};

export default RequestMeetingConfirmationModal;
