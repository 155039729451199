import React, { useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, Form, Input, message, Row } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { inquire, InquireRequest } from 'app/slice/inquireSlice';
import Text from 'antd/lib/typography/Text';
import { Rules } from 'types';

const rules: Rules = {
    companyName: [{ required: true, message: 'あなたの会社を入力してください' }],
    inquirerName: [{ required: true, message: '担当者を入力してください' }],
    inquiries: [{ required: true, message: 'お問い合わせを入力してください' }],
    email: [
        { required: true, message: 'メールアドレスを入力してください。' },
        { type: 'email' },
    ],
};

const EmailInquiryForm = () => {
    const location = useLocation();
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [checked, setChecked] = useState(false);
    const dispatch = useAppDispatch();
    const { status } = useSelector((state: RootState) => state.inquire);

    const baseUrl = () => {
        switch (location.pathname) {
            case '/kouteki/inquiries':
                return '/kouteki';
            default:
                return '/kisoku';
        }
    };

    const onCheckboxChange = (e: any) => {
        setChecked(e.target.checked);
    };

    const validation = () => {
        if (!checked) {
            return Promise.reject('利用規約に同意してください');
        }

        return Promise.resolve();
    };
    const onSubmit = (values: InquireRequest) => {
        const pathname = location.pathname;
        const type = pathname.includes('kouteki') ? 'ps' : 'wr';

        const { company_name, inquirer_name, email, inquiries } = values;
        dispatch(inquire({ company_name, inquirer_name, email, inquiries, type }));
    };

    useEffect(() => {
        if (status === 'success') {
            message.success('メールが送信されました。');
        } else if (status === 'failed') {
            message.error('何かがうまくいかなかった');
        }
    }, [status]);

    return (
        <>
            <Card className='contact-us-form'>
                <Text className='title'>お問い合わせ</Text>
                <Form form={form} onFinish={onSubmit} autoComplete='off' className='p-3' layout='vertical'>
                    <div className='container'>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='company_name'
                                    label='企業名'
                                    rules={rules.companyName}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='inquirer_name'
                                    label='ご担当者名'
                                    rules={rules.inquirerName}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name='email'
                                    label='メールアドレス'
                                    rules={rules.email}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name='inquiries'
                                    label='内容'
                                    rules={rules.inquiries}
                                >
                                    <TextArea showCount maxLength={500} autoSize={{ minRows: 5 }} />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row justify='center' className='text-center'>
                            <Col md={8} xs={24}>
                                <Form.Item
                                    name='checkbox'
                                    rules={[{ validator: validation }]}
                                >
                                    <Checkbox value='個人情報の取扱いに同意して次に進む'
                                              checked={checked} onChange={onCheckboxChange}
                                    >
                                        <Link target='_blank' to={baseUrl() + '/privacy-policy'}>個人情報の取扱い</Link>に同意して次に進む
                                    </Checkbox>
                                </Form.Item>
                                <Button type='primary' htmlType='submit' block>
                                    送信
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Card>
        </>
    );
};
export default EmailInquiryForm;
