import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { applicationDetailsSlice } from 'app/slice/applicationDetailsSlice';
import { applicationListSlice } from 'app/slice/applicationListSlice';
import { authSlice } from 'app/slice/authSlice';
import { loaderSlice } from 'app/slice/loaderSlice';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { forgotPasswordSlice } from 'app/slice/forgotPasswordSlice';
import { successModalSlice } from 'app/slice/successSlice';
import { loginSlice } from 'app/slice/loginSlice';
import { profileSlice } from 'app/slice/profileSlice';
import { registrationSlice } from 'app/slice/registrationSlice';
import { resourcesSlice } from 'app/slice/resourcesSlice';
import { companySlice } from 'app/slice/companySlice';
import { inquireSlice } from 'app/slice/inquireSlice';
import { notificationSlice } from 'app/slice/notificationSlice';
import { fundsListSlice } from 'app/slice/fundsListSlice';
import { fundDetailsSlice } from 'app/slice/fundDetailsSlice';
import { fundApplicationSlice } from 'app/slice/fundApplicationSlice';
import { fundRequirementsSlice } from 'app/slice/fundRequirementsSlice';
import { meetingSlice } from './slice/meetingSlice';
import { companyPoliciesSlice } from './slice/policySlice';
import { policyApplicationListSlice } from './slice/policyApplicationListSlice';
import { policyApplicationDetailsSlice } from './slice/policyApplicationDetailsSlice';
import { topicSlice } from 'app/slice/topicSlice';
import { servicesSlice } from 'app/slice/serviceSlice';
import { systemNotificationSlice } from 'app/slice/systemNotificationSlice';
import { searchQuerySlice } from 'app/slice/searchQuerySlice';
import { kisokuRegulationSlice } from 'app/slice/kisokuRegulationSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'],
    blacklist: ['auth'],
};

const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: ['email', 'name', 'uuid', 'token', 'roleName'],
};

const reducers = combineReducers({
    auth: persistReducer(authPersistConfig, authSlice.reducer),
    applicationDetails: applicationDetailsSlice.reducer,
    applications: applicationListSlice.reducer,
    companyPolicies: companyPoliciesSlice.reducer,
    loader: loaderSlice.reducer,
    login: loginSlice.reducer,
    profile: profileSlice.reducer,
    successModal: successModalSlice.reducer,
    forgotPassword: forgotPasswordSlice.reducer,
    company: companySlice.reducer,
    registration: registrationSlice.reducer,
    resource: resourcesSlice.reducer,
    inquire: inquireSlice.reducer,
    fundsList: fundsListSlice.reducer,
    fundDetails: fundDetailsSlice.reducer,
    fundApplication: fundApplicationSlice.reducer,
    fundRequirements: fundRequirementsSlice.reducer,
    notification: notificationSlice.reducer,
    meeting: meetingSlice.reducer,
    policyApplicationDetails: policyApplicationDetailsSlice.reducer,
    policyApplications: policyApplicationListSlice.reducer,
    topic: topicSlice.reducer,
    services: servicesSlice.reducer,
    systemNotification: systemNotificationSlice.reducer,
    searchQuery: searchQuerySlice.reducer,
    kisokuRegulation: kisokuRegulationSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
