import React, { Fragment, PropsWithChildren } from 'react';
import { get, isArray, isString } from 'lodash';

type Props = {
    search: string
}

const highlightText = (text: string, search: string) => {
    const parts = text.split(new RegExp(`(${search})`, 'gi'));

    return (
        <span>
            {parts.map((part, index) => (
                part.toLowerCase() === search.toLowerCase()
                    ? <mark>{part}</mark>
                    : part
            ))}
        </span>
    );
};

const Highlight = (props: PropsWithChildren<Props>) => {
    const { search, children } = props;

    if (!search) {
        return <>{children}</>;
    }

    const highlightTextNodes = (node: React.ReactNode): React.ReactNode => {
        if (isString(node)) {
            return highlightText(node, search);
        }

        if (isArray(node)) {
            return node.map((child, index) => (
                <Fragment key={index}>{highlightTextNodes(child)}</Fragment>
            ));
        }

        // Fix error when using <Button>
        if (get(node, 'type.__ANT_BUTTON')) {
            return node;
        }

        if (React.isValidElement(node)) {
            const { children, ...props } = node.props;

            return (
                <node.type {...props}>
                    {children && highlightTextNodes(children)}
                </node.type>
            );
        }

        return node;
    };

    return <>{highlightTextNodes(children)}</>;
};

export default Highlight;
