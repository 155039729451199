import React from 'react';
import { Modal, Spin } from 'antd';

type Props = {
    visible: boolean
}

const MatchingLoading = (props: Props) => {
    const { visible } = props;
    return (
        <Modal visible={visible} footer={null} closable={false} maskClosable={false} keyboard={false}
               centered={true} className='match-loading-modal'
        >
            <div className='text-center'>
                <div className='mb-3 title'>専門家マッチング開始</div>
                <Spin size='large'/>
            </div>
        </Modal>
    );
};

export default MatchingLoading;
