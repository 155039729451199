import React, { ReactNode, useEffect } from 'react';
import { Button, Card, Carousel, Col, Row, Skeleton } from 'antd';
import { ArrowDownOutlined, ArrowRightOutlined, FontSizeOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import amicoImage from 'assets/images/amico.png';
import analystImage from 'assets/images/analyst.png';
import profileImage from 'assets/images/profile.png';
import SearchForm from 'components/kouteki/SearchForm';
import { scrollToTop, useIsMobile } from 'lib/utils';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getFundRecommendations, selectFundsList } from 'app/slice/fundsListSlice';
import { pickBy, range } from 'lodash';
import { getTopicList, selectTopic } from 'app/slice/topicSlice';
import { NotificationType } from 'enums';

const PublicSupportLanding = () => {
    const isMobile = useIsMobile();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { data, success } = useAppSelector(selectFundsList);
    const { data: topics, loading, pagination } = useAppSelector(selectTopic);

    useEffect(() => {
        dispatch(getTopicList({
            type: NotificationType.KOTEKI,
            perPage: 5,
        }));
    }, [dispatch]);

    const searchFormOnSubmit = (values: any) => {
        scrollToTop();
        navigate({
            pathname: '/kouteki/funds/search',
            search: '?' + createSearchParams(pickBy(values, value => value)),
        });
    };

    useEffect(() => {
        dispatch(getFundRecommendations());
    }, [dispatch]);

    const displayRecommended = (() => {
        let recommendedList: ReactNode[] = [];
        let groupRecommended: ReactNode[] = [];
        //Since there is no literal pattern that can be queried in the db, create custom pattern declaration for now
        const sortOrder = [
            'ものづくり・商業・サービス生産性向上促進補助金（一般型、グローバル展開型）',
            '人材確保等支援助成金（テレワークコース）',
            '働き方改革推進支援助成金（勤務間インターバル導入コース）',
            '人材確保等支援助成金（外国人労働者就労環境整備助成コース）',
            '両立支援等助成金（介護離職防止支援コース）',
            'トライアル雇用助成金（障害者トライアルコース）',
            '産業雇用安定助成金',
            '雇用調整助成金',
        ];
        //Create copy of fund data, cannot sort with data variable directly
        let arrayForSort = [...data];
        let sortedData = arrayForSort.sort(function(firstRecord, secondRecord) {
            return sortOrder.indexOf(firstRecord.title) - sortOrder.indexOf(secondRecord.title);
        });
        sortedData.forEach((row, key) => {
            groupRecommended.push(
                <Col key={key} md={6}>
                    <Card className='shadow recommendation'>
                        <p>{row.title}</p>
                        <Link to={`/kouteki/funds/${row.code}/details`}>
                            <Button className='w-100'>詳細</Button>
                        </Link>
                    </Card>
                </Col>,
            );
            if ((key + 1) % 4 === 0 || key === data.length - 1) {
                recommendedList.push(
                    <div key={key} className='carousel-page'>
                        <Row gutter={[16, 16]}>
                            {groupRecommended}
                        </Row>
                    </div>,
                );
                groupRecommended = [];
            }
        });

        return recommendedList;
    })();

    return (
        <div className='container'>
            <section className='home-content'>
                <div className='title'>助成金・補助金・融資検索</div>
                <p>
                    助成金・補助金・融資といった公的資金のうち、貴社の目的に該当するものを簡易検索いたします。
                    <br />
                </p>
                <div className='list-center'>
                    <div>※専門家への相談サービスをご利用になるにはログインが必要です。</div>
                    <div style={{'marginLeft': 15}}>また、相談の対象は以下のとおりです。</div>
                    <dl style={{'marginLeft': 15}}>
                        <dt>○ 助成金：本サービスで検索可能な助成金全て。</dt>
                        <dt>○ 補助金：本サービスで検索可能な補助金のうち以下の４種類</dt>
                        <ul style={{'marginBottom': 0}}>
                            <li>ものづくり・商業・サービス生産性向上促進補助金</li>
                            <li>小規模事業者持続化補助金</li>
                            <li>IT導入補助金</li>
                            <li>事業再構築補助金</li>
                        </ul>
                        <dt>○ 融資：相談対象外</dt>
                    </dl>
                </div>
                
            </section>
            <section className='home-content'>
                <div className='title'>助成金・補助金・融資とは？</div>
                <Row gutter={[20, 10]} align='middle' justify='center' className='concept-meaning'>
                    <Col md={6}>
                        <Card className='kouteki-card-size'>
                            <div className='concept-title text-center'>－ 助成金 －</div>
                            <div style={{'fontSize': 14}}>助成対象となる労働環境整備などの要件を満たしたうえ、申請することにより厚生労働省から助成される資金です。<br /></div>
                            <table className='sub-description'>
                                <tr>
                                    <td style={{'verticalAlign': 'baseline'}}>※</td>
                                    <td>本サービスで検索可能な助成金は全て専門家相談可能です。</td>
                                </tr>
                            </table>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className='kouteki-card-size'>
                            <div className='concept-title text-center'>－ 補助金 －</div>
                            <div style={{'fontSize': 14}}>補助対象となる事業を実施のうえ、行政機関の審査に適うことにより支給される資金です。<br /></div>
                            <div className='sub-description'>
                                <table className='sub-description'>
                                    <tr>
                                        <td style={{'verticalAlign': 'baseline'}}>※</td>
                                        <td>補助金は、以下の４種類が専門家相談可能です。</td>
                                    </tr>
                                </table>
                                <dl>
                                    <ul>
                                        <li>ものづくり・商業・サービス生産性向上促進補助金</li>
                                        <li>小規模事業者持続化補助金</li>
                                        <li>IT導入補助金</li>
                                        <li>事業再構築補助金</li>
                                    </ul>
                                </dl>
                            </div>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className='kouteki-card-size'>
                            <div className='concept-title text-center'>－ 融資 －</div>
                            <div style={{'fontSize': 14}}>特定の要件を満たすことによりご利用いただける低利子の資金融資です。<br /></div>
                            <div className='sub-description'>※融資は専門家相談の対象外となります。</div>
                        </Card>
                    </Col>
                </Row>
                <br />
                <p>
                    ※上記は本サイトにおける定義です。
                </p>
            </section>
            <section className='home-content'>
                <div className='title'>サービスご利用の流れ</div>
                <Row gutter={[16, 16]} align='middle' justify='center'>
                    <Col md={7} className='service-card-container'>
                        <Card className='service-card shadow'>
                            <img src={amicoImage} alt='' />
                            <div className='card-title'>助成金・補助金・融資を検索</div>
                            <p style={{'textAlign': 'left'}}>
                                貴社のご状況に応じて、活用可能性のある公的資金を検索できます。
                            </p>
                        </Card>
                    </Col>
                    <Col md={1}>
                        {!isMobile ? (
                            <ArrowRightOutlined className='arrow-icon' />
                        ) : (
                            <ArrowDownOutlined className='arrow-icon' />
                        )}
                    </Col>
                    <Col md={7} className='service-card-container'>
                        <Card className='service-card shadow'>
                            <img src={analystImage} alt='' />
                            <div className='card-title'>検索した公的資金を簡易診断</div>
                            <p style={{'textAlign': 'left'}}>
                                検索した公的資金について、チェック項目により簡易診断できます。
                            </p>
                        </Card>
                    </Col>
                    <Col md={1}>
                        {!isMobile ? (
                            <ArrowRightOutlined className='arrow-icon' />
                        ) : (
                            <ArrowDownOutlined className='arrow-icon' />
                        )}
                    </Col>
                    <Col md={7} className='service-card-container'>
                        <Card className='service-card shadow'>
                            <img src={profileImage} alt='' />
                            <div className='card-title'>専門家への相談サービス申込 （各資金お問い合わせ窓口のご案内）※任意</div>
                            <p style={{'textAlign': 'left'}}>
                                簡易診断ののち、助成金と代表的な補助金については専門家への相談サービスをお申し込みいただけます。
                                <br />
                                （相談サービス対象外の資金は、お問い合わせ窓口をご案内いたします）
                            </p>
                        </Card>
                    </Col>
                </Row>
            </section>
            <section className='home-content'>
                <div className='search-form-container'>
                    <SearchForm onSubmit={searchFormOnSubmit} />
                </div>
            </section>
            <section className='home-content'>
                <div className='title'>おすすめの助成金・補助金・融資</div>
                {success && (
                    displayRecommended.length > 0 ? (
                        <Carousel arrows={true} prevArrow={<LeftOutlined />} nextArrow={<RightOutlined />}>
                            {displayRecommended}
                        </Carousel>
                    ) : (
                        <div className='text-center'>おすすめの助成金・補助金・融資がありません。</div>
                    )
                )}
            </section>
            {/*<section className='home-content'>*/}
            {/*    <div className='title'>公的支援に関連するトピックス</div>*/}
            {/*    <div className='topics mx-0'>*/}
            {/*        {loading ? (*/}
            {/*            <div className='my-3'>*/}
            {/*                {range(0, 5).map(key => (*/}
            {/*                    <Skeleton.Button key={key} active size='large' block className='mb-2' />*/}
            {/*                ))}*/}
            {/*            </div>*/}
            {/*        ) : topics.map((topic, key) => (*/}
            {/*            <Card size='small' key={key}>*/}
            {/*                <Row>*/}
            {/*                    <Col md={3} xs={24} className='topic-date-col'>*/}
            {/*                        <div className='topic-date-container'>{topic.sendDateList}</div>*/}
            {/*                    </Col>*/}
            {/*                    <Col md={18} xs={24} className='topic-text-col'>*/}
            {/*                        {topic.title}*/}
            {/*                    </Col>*/}
            {/*                    <Col md={3} xs={24} className='topic-button-col'>*/}
            {/*                        <Link to={`/kouteki/topics/${topic.uuid}`}>*/}
            {/*                            <Button className='secondary small'>詳細</Button>*/}
            {/*                        </Link>*/}
            {/*                    </Col>*/}
            {/*                </Row>*/}
            {/*            </Card>*/}
            {/*        ))}*/}
            {/*        {pagination?.total > 5 && (*/}
            {/*            <div className='mt-2 text-center'>*/}
            {/*                <Link to='/kouteki/topics'>もっと見る</Link>*/}
            {/*            </div>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*</section>*/}
        </div>
    );
};

export default PublicSupportLanding;
