import { getAxiosClientWithToken } from './index';
import { FundsListRequest } from 'app/slice/fundsListSlice';
import { FundApplicationRequest } from 'app/slice/fundApplicationSlice';

export const fetchFunds = (data: FundsListRequest) => {
    const restClient = getAxiosClientWithToken();

    // Remove capital_range key if empty
    if (data.capital_range === undefined || data.capital_range === '') {
        delete data.capital_range;
    }

    // Remove coverage_type key if empty
    if (data.coverage_type === undefined || data.coverage_type.length === 0) {
        delete data.coverage_type;
    }

    // Remove employee_count_range key if empty
    if (data.employee_count_range === undefined || data.employee_count_range === '') {
        delete data.employee_count_range;
    }

    // Remove industry key if empty
    if (data.industry === undefined || data.industry === '') {
        delete data.industry;
    }

    // Remove per_page key if empty
    if (data.per_page === undefined || data.per_page === '') {
        delete data.per_page;
    }

    // Remove prefecture key if empty
    if (data.prefecture === undefined || data.prefecture === '') {
        delete data.prefecture;
    }

    // Remove years_established_range key if empty
    if (data.years_established_range === undefined || data.years_established_range === '') {
        delete data.years_established_range;
    }

    return restClient.get('/company/funds', { params: data });
};

export const fetchFundDetails = (code: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get('/company/funds/' + code);
};

export const fetchFundRequirements = (funds: string[]) => {
    const restClient = getAxiosClientWithToken();
    const formData = new FormData();

    for (let fund of funds) {
        formData.append('funds[]', fund);
    }

    return restClient.get('/company/fund-requirements?' + new URLSearchParams(formData as any).toString());
};

export const createApplication = (funds: FundApplicationRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post('/company/applications', { funds });
};

export const fetchApplicationStatus = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/company/applications/${uuid}/status`);
};

export const fetchFundRecommendations = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/company/fund-recommendations`);
};
