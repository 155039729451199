import React, { useEffect } from 'react';
import { ApplicationDetails } from 'app/slice/applicationDetailsSlice';
import ConsultationChecklists from 'components/consultation/details/ConsultationChecklists';
import SpecialistProfiles from 'components/consultation/details/SpecialistProfiles';
import { useNavigate } from 'react-router-dom';

type Props = {
    application: ApplicationDetails
}

const SelectingSpecialist = (props: Props) => {
    const { application } = props;
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, [navigate]);

    return (
        <>
            <ConsultationChecklists application={application} />
            <p>
                社労士のマッチング結果：{application.specialists!.length}件
            </p>

            <SpecialistProfiles
                specialists={application.specialists}
            />
        </>

    );

};

export default SelectingSpecialist;
