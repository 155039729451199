import React, { useRef, useState } from 'react';
import { Modal } from 'antd';
import ChangePasswordRequirement, { ChangePasswordHandle } from './ChangePasswordRequirement';

type Props = {
    isVisible: boolean,
    onClose: () => void,
}

const ChangePasswordModal = (props: Props) => {
    const { isVisible, onClose } = props;
    const [isPasswordSuccess, setIsPasswordSuccess] = useState(false);
    const changePassRef = useRef<ChangePasswordHandle>();
    
    const setClose = () => {
        setIsPasswordSuccess(false);
        changePassRef?.current?.clearFields()
        onClose();
    };

    return (
        <Modal className='change-password-modal' visible={isVisible} onCancel={setClose} footer={null}>
            <div className='title'>パスワードの変更</div>
            <section>
                <ChangePasswordRequirement ref={changePassRef} setClose={setClose} isPasswordSuccess={isPasswordSuccess}
                                           setIsPasswordSuccess={setIsPasswordSuccess}
                />
            </section>
        </Modal>
    );
};

export default ChangePasswordModal;
