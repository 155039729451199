import { Button, Skeleton } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    CANCEL_APPLICATION,
    cancelApplication,
    getApplicationDetails,
    reset,
    selectApplicationDetails,
    SET_APPLICATION_SCHEDULE,
    Specialist,
} from 'app/slice/applicationDetailsSlice';
import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import ConsultationCancelModal from 'components/consultation/ConsultationCancelModal';
import Canceled from 'components/consultation/details/Canceled';
import ConsultationHeader from 'components/consultation/details/ConsultationHeader';
import Finished from 'components/consultation/details/Finished';
import Matching from 'components/consultation/details/Matching';
import NoMatch from 'components/consultation/details/NoMatch';
import Requesting from 'components/consultation/details/Requesting';
import Scheduled from 'components/consultation/details/Scheduled';
import SelectingSpecialist from 'components/consultation/details/SelectingSpecialist';
import SpecialistSchedule from 'components/consultation/details/SpecialistSchedule';
import WaitingConfirmation from 'components/consultation/details/WaitingConfirmation';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { resetActionKey, selectSuccessModal, setSuccessState, SuccessModalState } from 'app/slice/successSlice';
import checkIcon from 'assets/images/success_check.png';
import { scrollToTop } from 'lib/utils';
import { ConsultationStatuses } from 'enums';
import ScheduleConfirmationModal from 'components/consultation/ScheduleConfirmationModal';
import { selectSearchQuery } from 'app/slice/searchQuerySlice';

/*
    Change color depending on status of kouteki-Consultation status
*/
const getColor = (status?: string) => {
    switch (status) {
        case ConsultationStatuses.SCHEDULED:
            return 'status-scheduled';
        case ConsultationStatuses.SELECTING_SPECIALIST:
            return 'status-selecting-specialist';
        case ConsultationStatuses.MATCHING:
            return 'status-matching';
        case ConsultationStatuses.NO_MATCH:
            return 'status-no-match';
        case ConsultationStatuses.CANCELED:
            return 'status-canceled';
        case ConsultationStatuses.PAST_DUE:
            return 'status-past-due';
        case ConsultationStatuses.FINISHED:
            return 'status-finished';
        case ConsultationStatuses.REQUESTING:
            return 'status-requesting';
        case ConsultationStatuses.WAITING_CONF:
            return 'status-waiting-conf';
        default:
            return '';
    }
};

type Params = {
    uuid: string;
    specialist?: string;
};

const ConsultationDetails = () => {
    const successModalState = useAppSelector(selectSuccessModal);
    // Constants for Application Details Display
    const { loading, data, type, success } = useAppSelector(selectApplicationDetails);
    const { searchQuery } = useAppSelector(selectSearchQuery);
    const dispatch = useAppDispatch();
    const params = useParams<Params>();
    const navigate = useNavigate();
    // for cancel modal
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
    // for schedule confirmation success modal
    const [isScheduleConfirmationModalVisible, setIsScheduleConfirmationModalVisible] = useState(false);
    // for cancel modal
    const onCloseCancelModal = () => setIsCancelModalVisible(false);
    const onOpenCancelModal = () => setIsCancelModalVisible(true);
    // for schedule confirmation success modal
    const onOpenScheduleConfirmationModal = () => setIsScheduleConfirmationModalVisible(true);
    const onCloseScheduleConfirmationModal = () => {
        // Update Application Details before redirecting the user
        dispatch(getApplicationDetails(data.uuid));
        setIsScheduleConfirmationModalVisible(false);
        navigate(`/dashboard/kouteki/consultation/${data.uuid}/details`);
    };

    const onCancelApplication = () => {
        if (data.label?.content === ConsultationStatuses.NO_MATCH) {
            dispatch(cancelApplication({
                uuid: params.uuid!,
                reason: '',
            }));
        } else {
            onOpenCancelModal();
        }
    };

    // if scheduling is success;
    // status will change to SCHEDULED;
    // prompt schedule confirmation success modal
    useEffect(() => {
        if (data.label?.content === ConsultationStatuses.SCHEDULED && success && type === SET_APPLICATION_SCHEDULE) {
            onOpenScheduleConfirmationModal();
        }
    }, [data.label?.content, success, type]);

    useEffect(() => {
        if (data.label?.content === ConsultationStatuses.NO_MATCH && type === CANCEL_APPLICATION && success) {
            const successModalPayload: SuccessModalState = {
                isShow: true,
                title: '今回の相談申し込みはキャンセルされました.',
                message: '',
                withButton: true,
                image: checkIcon,
                buttonTitle: '閉じる',
                buttonActionKey: CANCEL_APPLICATION,
            };
            dispatch(setSuccessState(successModalPayload));
        }
    }, [data.label?.content, dispatch, params.uuid, success, type]);

    useEffect(() => {
        if (successModalState.buttonActionKey === CANCEL_APPLICATION && !successModalState.isShow) {
            dispatch(resetActionKey());
            dispatch(getApplicationDetails(params.uuid!));
            scrollToTop('smooth');
        }
    }, [dispatch, params.uuid, successModalState.buttonActionKey, successModalState.isShow]);

    /*
        Change component displayed depending on status of kouteki-Consultation status
    */
    const getStatusComponents = (status?: string) => {
        switch (status) {
            case ConsultationStatuses.SCHEDULED:
                return <Scheduled application={data} onOpenCancelModal={onOpenCancelModal} />;
            case ConsultationStatuses.SELECTING_SPECIALIST:
                if (params.specialist) {
                    return (
                        <SpecialistSchedule
                            application={data}
                            specialist={
                                data?.specialists?.find((specialist: Specialist) => {
                                    return specialist.uuid === params.specialist;
                                })!
                            }
                        />
                    );
                } else {
                    return <SelectingSpecialist application={data} />;
                }
            case ConsultationStatuses.MATCHING:
                return <Matching application={data} />;
            case ConsultationStatuses.NO_MATCH:
                return <NoMatch />;
            case ConsultationStatuses.CANCELED:
            case ConsultationStatuses.PAST_DUE:
                return <Canceled application={data} />;
            case ConsultationStatuses.FINISHED:
                return <Finished application={data} />;
            case ConsultationStatuses.REQUESTING:
                return <Requesting application={data} />;
            case ConsultationStatuses.WAITING_CONF:
                return <WaitingConfirmation application={data} />;
            default:
                return '';
        }
    };

    /*
        Get cancel button content, if any
    */
    const cancelButton = (status?: string) => {
        if (
            status === ConsultationStatuses.CANCELED ||
            status === ConsultationStatuses.PAST_DUE ||
            status === ConsultationStatuses.FINISHED ||
            status === ConsultationStatuses.SCHEDULED ||
            (status === ConsultationStatuses.SELECTING_SPECIALIST && params.specialist)
        ) {
            return null;
        } else if (status === ConsultationStatuses.MATCHING) {
            return (
                <div className='cancel-button'>
                    <Button onClick={onOpenCancelModal} danger>
                        マッチングを中止する
                    </Button>
                </div>
            );
        } else {
            return (
                <div className='cancel-button'>
                    <Button onClick={onCancelApplication} danger loading={type === CANCEL_APPLICATION && loading}>
                        面談をキャンセル
                    </Button>
                </div>
            );
        }
    };

    const getBreadcrumbsDetailLabel = () => {
        if (!params.specialist) {
            return '面談詳細';
        } else if (data) {
            return data.specialists?.find((specialist: Specialist) => {
                return specialist.uuid === params.specialist;
            })?.label;
        } else {
            return '';
        }
    };

    // Get Application Details
    useEffect(() => {
        // Reset data to make sure previous state is not displayed
        dispatch(reset());

        if (params.uuid) {
            dispatch(getApplicationDetails(params.uuid));
        }
    }, [dispatch, params.uuid]);

    const breadcrumbs: Breadcrumb[] = [
        {
            name: '面談依頼一覧',
            url: '/dashboard/kouteki/consultation-list' + searchQuery,
        },
        {
            name: getBreadcrumbsDetailLabel() ?? '',
            url: '',
        },
    ];

    return (
        <>
            <Breadcrumbs breadcrumb={breadcrumbs} />

            {loading ? (
                <Skeleton active />
            ) : (
                <div className='content consultation-details'>
                    {cancelButton(data.label?.content)}
                    <div className='title'>面談詳細</div>

                    {!params.specialist && (
                        <ConsultationHeader funds={data.funds} label={data.label}
                                            status={getColor(data.label?.content)}
                        />
                    )}

                    {getStatusComponents(data.label?.content)}
                </div>
            )}
            <ConsultationCancelModal uuid={params.uuid!}
                                     isVisible={isCancelModalVisible} onClose={onCloseCancelModal}
            />
            <ScheduleConfirmationModal application={data}
                                       isVisible={isScheduleConfirmationModalVisible}
                                       onClose={onCloseScheduleConfirmationModal}
            />
        </>
    );
};

export default ConsultationDetails;
