import React, { useState } from 'react';
import logo from 'assets/images/daidolife_logo_original.png';
import { Button, Dropdown, Menu } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { logout, selectAuth } from 'app/slice/authSlice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import CustomModal from 'components/common/CustomModal';
import questionIcon from 'assets/images/icon_question.png';

type Props = {
    login?: boolean,
    onOpenLoginModal?: () => void,
    websiteTitle: string;
}

const LandingNavbar = (props: Props) => {
    const { login = true, onOpenLoginModal, websiteTitle } = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { token, name } = useAppSelector(selectAuth);
    const location = useLocation();
    const [isConfirmLogoutVisible, setIsConfirmLogoutVisible] = useState(false);
    const onCloseConfirmLogout = () => setIsConfirmLogoutVisible(false);
    const onOpenConfirmLogout = () => setIsConfirmLogoutVisible(true);

    const onLogout = () => {
        dispatch(logout());
        onCloseConfirmLogout();
        if (location.pathname === '/kouteki') {
            navigate('/kouteki');
        } else if (location.pathname === '/kisoku') {
            navigate('/kisoku');
        } else {
            navigate('/');
        }
    };

    const menu = (
        <Menu>
            <Menu.Item>
                <Link to='/dashboard'>管理画面</Link>
            </Menu.Item>
            <Menu.Item onClick={onOpenConfirmLogout}>
                ログアウト
            </Menu.Item>
        </Menu>
    );

    const redirectLink = () => {
        if (location.pathname.includes('/kouteki/')) {
            return '/kouteki';
        } else if (location.pathname.includes('/kisoku/')) {
            return '/kisoku';
        }
        return '/';
    };

    return (
        <>
            <nav className='landing-navbar'>
                <Link to={redirectLink()}>
                    <div className='logo'>
                        <img src={logo} alt='logo' />
                        <span>{websiteTitle}</span>
                    </div>
                </Link>

                {login && (
                    <div className='buttons'>
                        {
                            !token ? (
                                <Button onClick={onOpenLoginModal}>
                                    ログイン
                                </Button>
                            ) : (
                                <Dropdown overlay={menu} placement='bottomRight' trigger={['click']}>
                                    <Button>
                                        {name}
                                        <DownOutlined className='down-icon' />
                                    </Button>
                                </Dropdown>
                            )
                        }
                    </div>
                )}
            </nav>

            <CustomModal
                isVisible={isConfirmLogoutVisible}
                onClose={onCloseConfirmLogout}
                title='ログアウト'
                icon={questionIcon}
                text='ログアウトしてもよろしいですか'
                showCancelButton={true}
                cancelButtonText='いいえ'
                showConfirmButton={true}
                confirmButtonText='はい'
                confirmOnClick={onLogout}
                cancelOnClick={onCloseConfirmLogout}
            />
        </>
    );
};

export default LandingNavbar;
