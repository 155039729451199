import React, { useEffect, useState } from 'react';
import {
    AreaChartOutlined,
    BellOutlined,
    CustomerServiceOutlined,
    FileTextOutlined,
    LayoutOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { Badge, Layout, Menu } from 'antd';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import { logout } from 'app/slice/authSlice';
import { SiderProps } from 'antd/lib/layout';
import { resetLogin } from 'app/slice/loginSlice';
import questionIcon from 'assets/images/icon_question.png';
import CustomModal from 'components/common/CustomModal';

const { Sider } = Layout;
const { SubMenu } = Menu;

type Props = SiderProps & {
    onCloseCollapsed?: () => void;
    notificationCount: number;
}

const Sidebar = (props: Props) => {
    const { breakpoint, collapsedWidth, collapsed, onCollapse, onCloseCollapsed, notificationCount } = props;

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isConfirmLogoutVisible, setIsConfirmLogoutVisible] = useState(false);
    const [selectedKey, setSelectedKey] = useState(pathname);
    const onCloseConfirmLogout = () => setIsConfirmLogoutVisible(false);
    const onOpenConfirmLogout = () => setIsConfirmLogoutVisible(true);
    const [openKeys, setOpenKeys] = useState<string[]>([]);

    /**
     * Close other collapse when one was opened
     *
     * @param {string[]} keys
     */
    const onOpenChange = (keys: string[]) => {
        let newOpenKeys = [];
        let lastKey = keys.pop();
        if (lastKey) newOpenKeys.push(lastKey);

        setOpenKeys(newOpenKeys);
    };

    const onLogout = () => {
        onCloseConfirmLogout();
        navigate('/');
        dispatch(logout());
        dispatch(resetLogin());
    };

    useEffect(() => {
        onCloseCollapsed?.();
    }, [onCloseCollapsed, pathname]);

    useEffect(() => {
        //Default url active menu
        setSelectedKey(pathname);
        if (pathname.includes('/dashboard/kouteki/consultation')) {
            onOpenChange(['dashboard-kouteki']);
            //Set Active list Menu when accessing detail page
            setSelectedKey('/dashboard/kouteki/consultation-list');
        } else if (pathname.includes('/dashboard/working-regulation/meetings')) {
            onOpenChange(['dashboard-wr']);
            //Set Active list Menu when accessing detail page
            setSelectedKey('/dashboard/working-regulation/meetings');
        }
    }, [pathname]);

    return (
        <>
            <Sider className='dashboard-sider'
                   width={300}
                   breakpoint={breakpoint}
                   collapsedWidth={collapsedWidth}
                   collapsed={collapsed}
                   onCollapse={onCollapse}
            >
                <Menu key={'dashboard-layout'} mode='inline' selectedKeys={[selectedKey]}
                      openKeys={openKeys} onOpenChange={onOpenChange}
                >
                    <Menu.Item key='/dashboard/management' icon={<LayoutOutlined />}>
                        <NavLink to='/dashboard/management'>管理画面</NavLink>
                    </Menu.Item>
                    <Menu.Item key='/dashboard/notifications' icon={<BellOutlined />}>
                        <Link to='/dashboard/notifications'>新着情報</Link>
                        <Badge className='notification-count' count={notificationCount} />
                    </Menu.Item>
                    <Menu.Item key='/dashboard/calendar' icon={<AreaChartOutlined />}>
                        <Link to='/dashboard/calendar'>ご利用状況</Link>
                    </Menu.Item>
                    <SubMenu key={'dashboard-kouteki'} icon={<CustomerServiceOutlined />} title='公的支援サービス'>
                        <Menu.Item key='/kouteki'>
                            <NavLink to='/kouteki'>公的支援トップページへ</NavLink>
                        </Menu.Item>
                        <Menu.Item key='/dashboard/kouteki/consultation-list'>
                            <NavLink to='/dashboard/kouteki/consultation-list'>面談管理</NavLink>
                        </Menu.Item>
                        <Menu.Item key='/dashboard/kouteki/specialist-review'>
                            <NavLink to='/dashboard/kouteki/specialist-review'>評価</NavLink>
                        </Menu.Item>
                    </SubMenu>
                    <SubMenu key={'dashboard-wr'} icon={<FileTextOutlined />} title='就業規則診断サービス'>
                        <Menu.Item className='menu-name-too-long' key='/kisoku'>
                            <NavLink to='/kisoku'>就業規則診断トップページへ（名種規程ひな形掲載）</NavLink>
                        </Menu.Item>
                        <Menu.Item key='/dashboard/working-regulation'>
                            <NavLink to='/dashboard/working-regulation'>診断申込管理</NavLink>
                        </Menu.Item>
                        <Menu.Item key='/dashboard/working-regulation/meetings'>
                            <NavLink to='/dashboard/working-regulation/meetings'>面談管理</NavLink>
                        </Menu.Item>
                        <Menu.Item key='/dashboard/working-regulation/specialist-review'>
                            <NavLink to='/dashboard/working-regulation/specialist-review'>評価</NavLink>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key='/dashboard/services' icon={<QuestionCircleOutlined />}>
                        <Link to='/dashboard/services'>サービスの使い方</Link>
                    </Menu.Item>
                    <Menu.Item key={'logout'} onClick={onOpenConfirmLogout} icon={<LogoutOutlined />}
                               className='mt-auto'
                    >
                        ログアウト
                    </Menu.Item>
                </Menu>
            </Sider>

            <CustomModal
                isVisible={isConfirmLogoutVisible}
                onClose={onCloseConfirmLogout}
                title='ログアウト'
                icon={questionIcon}
                text='ログアウトしてもよろしいですか'
                showCancelButton={true}
                cancelButtonText='いいえ'
                showConfirmButton={true}
                confirmButtonText='はい'
                confirmOnClick={onLogout}
                cancelOnClick={onCloseConfirmLogout}
            />
        </>
    );
};

export default Sidebar;
