import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserData } from 'api/user';
import { RootState } from 'app/store';

export type AuthState = {
    email: string,
    name: string,
    uuid: string,
    token: string,
    loading: boolean,
    success: boolean
}

export type AuthRequest = {
    email: string,
    password: string
}


/**
 * calls login API
 **/
export const fetchUserData = createAsyncThunk(
    'users/fetchUserData',
    async (data, { dispatch, rejectWithValue }) => {
        try {
            let response = await getUserData();
            const { success, data } = response.data;
            if (success) {
                dispatch(setAuth(data));
                return true;
            }
            return rejectWithValue(false);
        } catch (error: any) {
            const { status } = error.response || {};

            if (status === 401) {
                dispatch(logout());
            }

            return rejectWithValue(false);
        }
    },
);

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        email: '',
        name: '',
        uuid: '',
        token: '',
        loading: false,
        success: false,
    } as AuthState,
    reducers: {
        setAuth: (state: AuthState, action) => {
            const { token, email, name, uuid } = action.payload;
            state.email = email;
            state.name = name;
            state.uuid = uuid;
            state.success = true;
            if (token) {
                state.token = token;
            }
        },
        logout: (state: AuthState) => {
            state.email = '';
            state.name = '';
            state.uuid = '';
            state.token = '';
            state.success = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserData.pending, (state: AuthState) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(fetchUserData.fulfilled, (state: AuthState) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(fetchUserData.rejected, (state: AuthState) => {
            state.loading = false;
            state.success = false;
        });
    },
});

export const selectAuth = (state: RootState) => state.auth;
export const { setAuth, logout } = authSlice.actions;
