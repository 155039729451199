import { createAsyncThunk, createSlice, isRejectedWithValue } from '@reduxjs/toolkit';
import { getErrorMessage } from 'api';
import {
    checkCanCreateCompanyPolicyApplicationApi,
    createCompanyPolicyApplicationApi,
    CreateCompanyPolicyApplicationRequest,
    deleteCompanyPolicyApi,
    getCompanyPoliciesAndVerificationsApi,
    getCompanyPolicyTopicsApi,
    getCompanyPolicyTypesApi,
    shareCompanyPolicyApi,
    uploadCompanyPolicyApi,
} from 'api/policy';
import { RootState } from 'app/store';
import { fileURLToPath } from 'url';

export type { CreateCompanyPolicyApplicationRequest } from 'api/policy';
export const CREATE_POLICY_APP = 'CREATE_POLICY_APP';
export const DELETE_POLICY = 'DELETE_POLICY';
export const DUPLICATE_POLICY = 'DUPLICATE_POLICY';
export const GET_POLICY = 'GET_POLICY';
export const SHARE_POLICY = 'SHARE_POLICY';
export const UPLOAD_POLICY = 'UPLOAD_POLICY';

// Application Details State
type CompanyPolicyState = {
    loading: boolean,
    success: boolean | null,
    type: string | null,
    data: CompanyPolicy[] | null
    newPolicyUuid: string | null,
    types: Type[],
    topics: Topic[],
    allowSubmission: boolean,
}

// Company Policy Topic Structure
export type Topic = {
    code: string,
    name: string,
}

// Company Policy Type Structure
export type Type = {
    code: string,
    name: string,
}

export type File = {
    uuid: string,
    groupUuid: string | null,
    name: string,
    downloadUrl: string,
    previewUrl: string,
    fileType: string | null,
}

export type CompanyPolicy = {
    uuid: string,
    policyUuid: string,
    name: string,
    shared: boolean | null,
    status: string | null,
    downloadUrl: string,
    previewUrl: string,
    fileType: string | null,
    shareable: boolean,
    isPolicy: boolean
    createdAt: string,
    isBordered: boolean,
}

type FileData = {
    uuid: string,
    group_uuid: string | null,
    name: string,
    download_url: string,
    preview_url: string,
    file_type: string | null,
    created_at: string,
}

// Expected Json Structure of API response
// Main difference is usage of snake_case
type CompanyPolicyData = {
    uuid: string,
    shared: boolean | null,
    status: string | null,
    policy_files: FileData[],
    verification_file: FileData | null,
    created_at: string,
}
/**
 * GET Specialist Profile
 **/
export const getCompanyPoliciesAndVerifications = createAsyncThunk(
    '/company/policy',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await getCompanyPoliciesAndVerificationsApi();

            if (response.data.success === true) {
                dispatch(setCompanyPolicies(response.data));
                return true;
            }

            return isRejectedWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

/**
 * POST Application Consultation / Meeting Schedule
 */
export const uploadCompanyPolicy = createAsyncThunk(
    '/company/policy/upload',
    async (data: FormData, { rejectWithValue }) => {
        try {
            let response = await uploadCompanyPolicyApi(data);

            if (response.data.success === true) {
                if (response.data.data.new === true) {
                    return response.data.data.uuid;
                }

                return null;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    },
);

/**
 * POST Application Consultation / Meeting Schedule
 */
export const shareCompanyPolicy = createAsyncThunk(
    '/company/policy/:uuid/share',
    async (uuid: string, { rejectWithValue }) => {
        try {
            let response = await shareCompanyPolicyApi(uuid);

            if (response.data.success === true) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

/**
 * POST Application Consultation / Meeting Schedule
 */
export const deleteCompanyPolicy = createAsyncThunk(
    '/company/policy/:uuid',
    async (uuid: string, { rejectWithValue }) => {
        try {
            let response = await deleteCompanyPolicyApi(uuid);

            if (response.data.success === true) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

/**
 * GET Company Policy Topics
 */
export const getCompanyPolicyTopics = createAsyncThunk(
    '/company/policies/topics',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            let response = await getCompanyPolicyTopicsApi();

            if (response.data.success === true) {
                dispatch(setCompanyPolicyTopics(response.data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

/**
 * GET Company Policy Types
 */
export const getCompanyPolicyTypes = createAsyncThunk(
    '/company/policies/types',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            let response = await getCompanyPolicyTypesApi();

            if (response.data.success === true) {
                dispatch(setCompanyPolicyTypes(response.data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

/**
 * POST Application Consultation / Meeting Schedule
 */
export const createCompanyPolicyApplication = createAsyncThunk(
    '/company/policies/application',
    async (data: CreateCompanyPolicyApplicationRequest, { rejectWithValue }) => {
        try {
            let response = await createCompanyPolicyApplicationApi(data);

            if (response.data.success === true) {
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

/**
 * GET Application Consultation Creation Allowed Status
 */
export const checkCanCreateCompanyPolicyApplication = createAsyncThunk(
    '/company/policies/application/allowed',
    async (_, { rejectWithValue }) => {
        try {
            let response = await checkCanCreateCompanyPolicyApplicationApi();
            return response.data.data.allowed;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
)

/**
 * Create Application Slice
 */
export const companyPoliciesSlice = createSlice({
    name: 'application',
    initialState: {
        loading: true,
        success: null,
        type: null,
        data: [] as CompanyPolicy[],
        newPolicyUuid: null,
    } as CompanyPolicyState,
    reducers: {
        reset: (state: CompanyPolicyState) => {
            state.loading = false;
            state.success = null;
            state.type = null;
            state.data = [] as CompanyPolicy[];
            state.allowSubmission = false;
        },
        setCompanyPolicies: (state, { payload }) => {
            const convertedData: CompanyPolicy[] = [];
            payload.data.forEach((policy: CompanyPolicyData) => {
                let policyFiles: File[] = [];
                let isFirst = true;

                policy.policy_files.forEach((file: FileData, index) => {
                    convertedData.push({
                        uuid: file.uuid,
                        policyUuid: policy.uuid,
                        name: file.name,
                        shared: policy.shared,
                        status: policy.status,
                        downloadUrl: file.download_url,
                        previewUrl: file.preview_url,
                        fileType: file.file_type,
                        shareable: isFirst,
                        isPolicy: true,
                        createdAt: file.created_at,
                        isBordered: policy.verification_file === null && policy.policy_files.length === (index + 1)
                    } as CompanyPolicy ) ;

                    if (isFirst) {
                        isFirst = false;
                    }
                })

                if (policy.verification_file){
                    convertedData.push({
                        uuid: policy.verification_file.uuid,
                        policyUuid: policy.uuid,
                        name: policy.verification_file.name,
                        shared: policy.shared,
                        status: policy.status,
                        downloadUrl: policy.verification_file.download_url,
                        previewUrl: policy.verification_file.preview_url,
                        fileType: policy.verification_file.file_type,
                        shareable: false,
                        isPolicy: false,
                        createdAt: policy.verification_file.created_at,
                        isBordered: true
                    } as CompanyPolicy ) ;
                }
            });

            state.data = convertedData;
        },
        setCompanyPolicyTypes: (state, { payload }) => {
            state.types = payload.data;
        },
        setCompanyPolicyTopics: (state, { payload }) => {
            state.topics = payload.data;
        },
    },
    extraReducers: (builder) => {
        // getCompanyPoliciesAndVerifications action pending
        builder.addCase(getCompanyPoliciesAndVerifications.pending, (state: CompanyPolicyState) => {
            state.loading = true;
            state.success = null;
            state.type = GET_POLICY;
        });
        // getCompanyPoliciesAndVerifications action rejected
        builder.addCase(getCompanyPoliciesAndVerifications.rejected, (state: CompanyPolicyState) => {
            state.loading = false;
            state.success = false;
            state.type = GET_POLICY;
        });
        // getCompanyPoliciesAndVerifications action fulfilled
        builder.addCase(getCompanyPoliciesAndVerifications.fulfilled, (state: CompanyPolicyState) => {
            state.loading = false;
            state.success = true;
            state.type = GET_POLICY;
        });
        // uploadCompanyPolicy action rejected
        builder.addCase(uploadCompanyPolicy.pending, (state: CompanyPolicyState) => {
            state.loading = true;
            state.success = null;
            state.type = UPLOAD_POLICY;
            state.newPolicyUuid = null;
        });
        // uploadCompanyPolicy action rejected
        builder.addCase(uploadCompanyPolicy.rejected, (state: CompanyPolicyState, { payload }) => {
            state.loading = false;
            state.success = false;

            if (payload === 409) {
                state.type = DUPLICATE_POLICY;
            } else {
                state.type = UPLOAD_POLICY;
            }

            state.newPolicyUuid = null;
        });
        // uploadCompanyPolicy action fulfilled
        builder.addCase(uploadCompanyPolicy.fulfilled, (state: CompanyPolicyState, { payload }) => {
            state.loading = false;
            state.success = true;
            state.type = UPLOAD_POLICY;
            state.newPolicyUuid = payload;
        });
        // shareCompanyPolicy action rejected
        builder.addCase(shareCompanyPolicy.rejected, (state: CompanyPolicyState) => {
            state.loading = false;
            state.success = false;
            state.type = SHARE_POLICY;
            state.newPolicyUuid = null;
        });
        // shareCompanyPolicy action fulfilled
        builder.addCase(shareCompanyPolicy.fulfilled, (state: CompanyPolicyState) => {
            state.loading = false;
            state.success = true;
            state.type = SHARE_POLICY;
            state.newPolicyUuid = null;
        });
        // deleteCompanyPolicy action rejected
        builder.addCase(deleteCompanyPolicy.rejected, (state: CompanyPolicyState) => {
            state.loading = false;
            state.success = false;
            state.type = DELETE_POLICY;
            state.newPolicyUuid = null;
        });
        // deleteCompanyPolicy action fulfilled
        builder.addCase(deleteCompanyPolicy.fulfilled, (state: CompanyPolicyState) => {
            state.loading = false;
            state.success = true;
            state.type = DELETE_POLICY;
            state.newPolicyUuid = null;
        });
        // createCompanyPolicyApplication action rejected
        builder.addCase(createCompanyPolicyApplication.rejected, (state: CompanyPolicyState) => {
            state.loading = false;
            state.success = false;
            state.type = CREATE_POLICY_APP;
            state.newPolicyUuid = null;
        });
        // createCompanyPolicyApplication action fulfilled
        builder.addCase(createCompanyPolicyApplication.fulfilled, (state: CompanyPolicyState) => {
            state.loading = false;
            state.success = true;
            state.type = CREATE_POLICY_APP;
            state.newPolicyUuid = null;
        });
        // checkCanCreateCompanyPolicyApplication action pending
        builder.addCase(checkCanCreateCompanyPolicyApplication.pending, (state: CompanyPolicyState) => {
            state.allowSubmission = false;
        });
        // checkCanCreateCompanyPolicyApplication action rejected
        builder.addCase(checkCanCreateCompanyPolicyApplication.rejected, (state: CompanyPolicyState) => {
            state.allowSubmission = false;
        });
        // checkCanCreateCompanyPolicyApplication action fulfilled
        builder.addCase(checkCanCreateCompanyPolicyApplication.fulfilled, (state: CompanyPolicyState, { payload }) => {
            state.allowSubmission = payload;
        });
    },
});

export const {
    reset,
    setCompanyPolicies,
    setCompanyPolicyTopics,
    setCompanyPolicyTypes,
} = companyPoliciesSlice.actions;
export const selectCompanyPolicies = (state: RootState) => state.companyPolicies;
