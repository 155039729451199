import React from 'react';
import LandingNavbar from 'components/landing/LandingNavbar';
import websiteTitle from 'assets/images/landing_website_title.png';
import { useLocation, useOutlet } from 'react-router-dom';
import { useStyle } from 'lib/utils';
import TopLandingHeader from 'components/common/TopLandingHeader';
import LandingFooter from 'components/landing/LandingFooter';
import SystemNotification from 'components/common/SystemNotification';

type OutletPosition = 'header' | 'body';

type Props = {
    onOpenLoginModal: () => void,
}

const Layout = (props: Props) => {
    const { onOpenLoginModal } = props;
    useStyle('landing');

    const location = useLocation();
    const outlet = useOutlet();

    const outletPosition: OutletPosition = location.pathname === '/' ? 'body' : 'header';

    const Body = () => {
        switch (outletPosition) {
            case 'header':
                return (
                    <TopLandingHeader>
                        {outlet}
                    </TopLandingHeader>
                );
            case 'body':
                return (
                    <>
                        <TopLandingHeader image={websiteTitle} />
                        {outlet}
                    </>
                );
        }
    };

    return (
        <>
            <SystemNotification />
            <LandingNavbar onOpenLoginModal={onOpenLoginModal} websiteTitle='公的支援オンラインサービス／就業規則診断オンラインサービス' />
            <Body />
            <LandingFooter websiteTitle='公的支援オンラインサービス／就業規則診断オンラインサービス' />
        </>
    );
};

export default Layout;
