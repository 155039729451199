import React from 'react';
import { FundDataRow, selectFundsList, setChecked } from 'app/slice/fundsListSlice';
import { Button, Checkbox, List } from 'antd';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { pull } from 'lodash';
import { SearchType } from 'enums';
import Highlight from 'components/common/Highlight';
import { useParamsState } from 'lib/utils';

type Props = {
    item: FundDataRow,
}

const SearchCard = (props: Props) => {
    const { item } = props;
    const { checked, params } = useAppSelector(selectFundsList);
    const dispatch = useAppDispatch();
    const [keyword] = useParamsState('keyword', '');

    const onCheckboxChange = (e: CheckboxChangeEvent) => {
        let code = e.target.value;
        let newCheckedList = [...checked];

        if (checked.includes(code)) {
            newCheckedList = pull(newCheckedList, code);
        } else {
            newCheckedList.push(code);
        }

        dispatch(setChecked(newCheckedList));
    };

    return (
        <List.Item>
            <Highlight search={keyword}>
                {params.search_type === SearchType.CATEGORY ? (
                    <Checkbox onChange={onCheckboxChange}
                              value={item.code}
                              disabled={checked.length >= 5 && !checked.includes(item.code)}
                              checked={checked.includes(item.code)}
                    >
                        <div className='content-wrapper'>
                            <div className='title'>{item.title}</div>
                            <div className='description'>{item.overview}</div>
                        </div>
                    </Checkbox>
                ) : (
                    <div className='content-wrapper'>
                        <div className='title'>{item.title}</div>
                        <div className='description'>{item.overview}</div>
                    </div>
                )}
            </Highlight>

            <Link to={`/kouteki/funds/${item.code}/details` + (keyword ? '?keyword=' + keyword : '')} target='_blank'>
                <Button>詳細</Button>
            </Link>
        </List.Item>
    );
};

export default SearchCard;
