import React from 'react';
import Breadcrumbs, { Breadcrumb } from 'components/common/Breadcrumb';
import TermsOfService from 'components/landing/TermsOfService';

/**
 * Props for Breadcrumb
 */
const breadcrumbs: Breadcrumb[] = [
    {
        name: 'ホームページ',
        url: '/kouteki',
    },
    {
        name: '利用規約',
        url: '/kouteki/privacy-policy',
    },
];

const PublicSupportTermsOfService = () => {
    return (
        <>
            <section className='breadcrumbs'>
                <Breadcrumbs breadcrumb={breadcrumbs}/>
            </section>
            <section className='content'>
                <TermsOfService/>
            </section>
        </>
    );
};

export default PublicSupportTermsOfService;
