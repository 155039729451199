import React from 'react';
import { Button, Checkbox, Col, Modal, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useAppSelector } from 'app/hooks';
import { FundRequirementsRow, selectFundRequirements } from 'app/slice/fundRequirementsSlice';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FundsLocationState } from 'pages/kouteki/DiagnoseResult';

type Props = {
    isVisible: boolean,
    onClose: () => void,
    onSubmit: (state: FundsLocationState) => void
}

const EligibilityModal = (props: Props) => {
    const { isVisible, onClose } = props;
    const { data } = useAppSelector(selectFundRequirements);

    const state: FundsLocationState = {
        funds: data.map(row => ({
            code: row.code,
            title: row.title,
            allowConsultation: row.allowConsultation,
            type: row.type,
            url: row.url,
            result: false,
        })),
    };

    const onChange = ({ code, title }: FundRequirementsRow) => {
        return (checkedValue: CheckboxValueType[]) => {
            const index = state.funds.findIndex(fund => fund.code === code && fund.title === title);
            const fund = data.find(fund => fund.code === code && fund.title === title);
            
            state.funds[index].result = fund?.requirements.length === checkedValue.length;
        };
    };

    const onSubmit = () => {
        props.onSubmit(state);
    };

    return (
        <Modal className='eligibility-modal' visible={isVisible}
               onCancel={onClose} footer={null}
        >
            <div className='title'>活用可能性を診断</div>
            <div className='subtitle'>
                該当する項目にチェックを入れ「診断する」ボタンをクリックしてください。
            </div>
            {data.map((row, index1) => (
                <section key={index1} className='pt-33px'>
                    <Text className='d-block mb-2' strong>{row.title}</Text>
                    <Checkbox.Group className='w-100'>
                        <Row gutter={[6, 6]}>
                            <Checkbox.Group onChange={onChange(row)}>
                                {row.requirements.map((requirement, index2) => (
                                    <Col key={index2} span={24} className={'eligibility-item'}>
                                        <Checkbox value={index1 + '_' + index2}>
                                            {requirement.content}
                                        </Checkbox>
                                    </Col>
                                ))}
                            </Checkbox.Group>
                        </Row>
                    </Checkbox.Group>
                </section>
            ))}
            <div className='text-center mt-5'>
                <Button onClick={onSubmit} className='diagnose-button'>診断する</Button>
            </div>
        </Modal>
    );
};

export default EligibilityModal;
