import React, { useEffect } from 'react';
import { Avatar, Button, Card, Col, Empty, Pagination, Row } from 'antd';
import avatar from 'assets/images/avatardefault.png';
import { CheckOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getUserReviewList, reset, ReviewDetails, selectMeeting } from 'app/slice/meetingSlice';
import { SpecialistRoles } from 'enums';

export type SpecialistData = {
    image: string;
    name: string;
    status: boolean;
}

type Role = {
    [key: string]: string;
}

const specialistRoles: Role = { 
    'sharoushi': SpecialistRoles.SHAROUSHI,
    'shindanshi': SpecialistRoles.SHINDANSHI
};   

const SpecialistReviewList = () => {
    const {
        reviewList,
    } = useAppSelector(selectMeeting);
    const dispatch = useAppDispatch();
  
    useEffect(() => {
        dispatch(getUserReviewList(1));
    }, [dispatch]);

    const renderDetailsButton = (status: boolean, scheduleData: ReviewDetails) => {
        if (status) {
            return (
                <Link state={{ scheduleData }} to='/dashboard/kouteki/specialist-review/details/reviewed'>
                    <Button type='default' className='w-100'>詳細</Button>
                </Link>
            );
        }
        return (
            <Link state={{ scheduleData }} to='/dashboard/kouteki/specialist-review/details/to-review'>
                <Button type='primary' className='w-100'>評価する</Button>
            </Link>
        );
    };

    //Reset add meeting status upon accessing review list
    useEffect(() => {
        dispatch(reset());
    }, [dispatch, reviewList]);

    return (
        <div className='content specialist-review'>
            <div className='title'>評価一覧</div>
            {reviewList.length === 0 ? (
                <Empty />
            ) : (
                <>
                    {reviewList.map((column: ReviewDetails, key) =>
                        <Card key={key} className='specialist-review-card'>
                            <Row key={key} align={'middle'} gutter={16}>
                                <Col md={3} xs={6}>
                                    <Avatar
                                        className='specialist-review-image'
                                        size={80}
                                        src={column.specialist.profileImageUrl ?? avatar}
                                    />
                                </Col>
                                <Col md={14} xs={18}>
                                    <div className='specialist-review-name'>
                                        <span>{specialistRoles[column.role]}</span> {column?.specialist?.name}
                                    </div>
                                </Col>
                                <Col md={4} xs={24} className='text-center mb-2'>
                                    {column.evaluations.length > 0 && (
                                        <div className='specialist-review-done'>
                                            <CheckOutlined className='mr-2' />
                                            評価済み
                                        </div>
                                    )}
                                </Col>

                                <Col md={3} xs={24}>
                                    {renderDetailsButton(column.evaluations.length > 0, column)}
                                </Col>
                            </Row>
                        </Card>,
                    )}
                    <div className='text-center specialist-review-pagination'>
                        <Pagination size='small' total={reviewList.length} />
                    </div>
                </>
            )}
        </div>
    );
};

export default SpecialistReviewList;
