import React from 'react';
import { Button } from 'antd';
import { ApplicationDetails } from 'app/slice/applicationDetailsSlice';
import ConsultationChecklists from 'components/consultation/details/ConsultationChecklists';
import { Link } from 'react-router-dom';
import { selectSearchQuery } from 'app/slice/searchQuerySlice';
import { useAppSelector } from 'app/hooks';

type Props = {
    application: ApplicationDetails
}

const Canceled = (props: Props) => {
    const { application } = props;
    const { searchQuery } = useAppSelector(selectSearchQuery);

    return (
        <>
            <ConsultationChecklists application={application} />

            <div className='text-center'>
                <Link to={`/dashboard/kouteki/consultation-list` + searchQuery}>
                    <Button className='back-button' type='primary'>戻る</Button>
                </Link>
            </div>
        </>

    );

};

export default Canceled;
