import React, { useEffect } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import iconConfirm from 'assets/images/icon_confirm.png';
import { unsubscribe } from 'app/slice/companySlice';
import { useAppDispatch } from 'app/hooks';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { useNavigate } from 'react-router-dom';
import { logout } from 'app/slice/authSlice';

type Props = {
    isVisible: boolean,
    onClose: () => void,
}

const UnsubscribeModal = (props: Props) => {
    const { isVisible, onClose } = props;
    const dispatch = useAppDispatch();
    const { status } = useSelector((state: RootState) => state.company);
    const navigate = useNavigate();

    const confirm = async () => {
        await dispatch(unsubscribe());
    };

    useEffect(() => {
        if (status === 'success') {
            dispatch(logout());
            navigate('/');
        } else if (status === 'failed') {
            alert('Could not proceed');
        }
    }, [dispatch, navigate, status]);

    return (
        <Modal className='unsubscribe-modal' centered transitionName='' maskTransitionName='' visible={isVisible}
               onCancel={onClose} footer={null}
        >
            <div className='unsubscribe-content'>
                <img src={iconConfirm} alt='' className='mx-auto d-block' />
                <div className='unsubscribe-description p-4'>
                    「退会」ボタンを押すと、アカウントにログインできなくなります。本当に「退会」して宜しいですか。
                </div>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Button className='w-100' onClick={onClose}>
                            キャンセル
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button type='primary' className='ant-btn-dangerous w-100' onClick={confirm}>
                            退会
                        </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};

export default UnsubscribeModal;
