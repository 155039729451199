import React from 'react';
import { Button } from 'antd';
import { ApplicationDetails } from 'app/slice/applicationDetailsSlice';
import SpecialistDetails from 'components/consultation/SpecialistDetails';
import ConsultationChecklists from 'components/consultation/details/ConsultationChecklists';
import { Link } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { selectSearchQuery } from 'app/slice/searchQuerySlice';
import { useAppSelector } from 'app/hooks';

type Props = {
    application: ApplicationDetails
}

const Finished = (props: Props) => {
    const { application } = props;
    const { searchQuery } = useAppSelector(selectSearchQuery);

    const meetingDetails = () => {
        return (
            <div>
                <hr />
                <p className='label-text'>
                    <strong>日程 : <span className='datetime'>{application.scheduleDatetime!}</span></strong>
                </p>

                {application?.daidoJoins ?
                    <div><CheckOutlined className='attendance-check-icon' /> 大同生命営業担当者の同席を希望します。</div>
                    :
                    <div><CloseOutlined className='attendance-check-icon red-3' /> 大同生命営業担当者の同席は希望しません。</div>
                }
            </div>
        );
    };

    return (
        <>
            <ConsultationChecklists application={application} />

            <SpecialistDetails
                application={application}
                hasProfileButton={false}
                meetingDetails={meetingDetails()}
                isKoteki={true}
            />

            <div className='text-center'>
                <Link to={`/dashboard/kouteki/consultation-list` + searchQuery}>
                    <Button className='back-button' type='primary'>戻る</Button>
                </Link>
            </div>
        </>

    );
};

export default Finished;
