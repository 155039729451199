import { getAxiosClient } from './index';

export const requestForgotPasswordEmail = (email: string) => {
    const restClient = getAxiosClient();
    return restClient.post('/forgot-password', {
        email,
        origin: 'daido-chukidan',
    });
};

export const changePassword = (
    temporary_password: string,
    password: string,
    password_confirmation: string,
    token: string | undefined,
) => {
    const restClient = getAxiosClient();
    return restClient.patch('/forgot-password/' + token, {
        temporary_password,
        password,
        password_confirmation,
    });
};

export const validateToken = (token: string | undefined) => {
    const restClient = getAxiosClient();
    return restClient.get('/forgot-password/' + token);
};
