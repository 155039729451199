import React, { useEffect, useState } from 'react';
import DashboardEditProfile from 'components/dashboard/DashboardEditProfile';
import DashboardProfile from 'components/dashboard/DashboardProfile';
import { Button } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { fetchProfile, selectProfile, UPDATE_PROFILE, updateProfile } from 'app/slice/profileSlice';
import { resetActionKey, selectSuccessModal, setSuccessState } from 'app/slice/successSlice';
import successCheck from 'assets/images/success_check.png';
import { useNavigate } from 'react-router-dom';
import { selectAuth } from 'app/slice/authSlice';


const DashboardManagement = () => {
    const [editMode, setEditMode] = useState(false);
    const navigate = useNavigate();
    const profile = useAppSelector(selectProfile);
    const { success } = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();
    const successModal = useAppSelector(selectSuccessModal);

    const onCancel = () => {
        setEditMode(false);
    };

    const onFinish = (values: any) => {
        dispatch(updateProfile(values));
    };

    useEffect(() => {
        if (success) {
            dispatch(fetchProfile());
        }
    }, [dispatch, success]);

    useEffect(() => {
        if (profile.type === UPDATE_PROFILE && profile.success === true) {
            dispatch(setSuccessState({
                isShow: true,
                title: 'プロフィールを更新',
                message: 'プロフィールの詳細が更新されました',
                withButton: true,
                buttonTitle: '閉じる',
                buttonActionKey: 'ON_UPDATE_PROFILE_SUCCESS',
                image: successCheck,
            }));
        }
    }, [dispatch, navigate, profile.success, profile.type]);


    useEffect(() => {
        if (!successModal.isShow && successModal.buttonActionKey === 'ON_UPDATE_PROFILE_SUCCESS') {
            dispatch(resetActionKey());
            setEditMode(false);
            dispatch(fetchProfile());
        }
    }, [dispatch, successModal.buttonActionKey, successModal.isShow]);

    return (
        <div className='content dashboard-management'>
            <div className='header-container'>
                <div className='title'>プロフィール詳細</div>
                {(!editMode && profile.success) && (
                    <Button onClick={() => setEditMode(true)} className='edit-profile w-200px' size='large'
                    >プロフィール編集</Button>
                )}
            </div>
            {!editMode ? <DashboardProfile /> : (
                <DashboardEditProfile onCancel={onCancel} onFinish={onFinish} />
            )}
        </div>
    );
};

export default DashboardManagement;
