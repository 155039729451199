import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    getApplicationDetails,
    REQUEST_CHUKIDAN_CONSULTATION,
    requestChukidanConsultation,
    selectApplicationDetails,
} from 'app/slice/applicationDetailsSlice';
import { resetActionKey, selectSuccessModal, setSuccessState, SuccessModalState } from 'app/slice/successSlice';
import checkIcon from 'assets/images/success_check.png';
import ConsultationChecklists from 'components/consultation/details/ConsultationChecklists';
import { scrollToTop } from 'lib/utils';

const NoMatch = () => {
    const { data: application, type, success } = useAppSelector(selectApplicationDetails);
    const successModalState = useAppSelector(selectSuccessModal);
    const dispatch = useAppDispatch();

    const requestConsultation = () => {
        dispatch(requestChukidanConsultation(application.uuid));
    };

    // Render Success Modal and ApplicationState change
    useEffect(() => {
        if (type === REQUEST_CHUKIDAN_CONSULTATION && success) {
            const successModalPayload: SuccessModalState = {
                isShow: true,
                title: '依頼が完了しました',
                message: '中企団オンライン相談窓口より、本日を含め、土日・祝日を除く３日以内に候補日時をご連絡いたしますので、しばらくお待ちください。',
                withButton: true,
                image: checkIcon,
                buttonTitle: '閉じる',
                buttonActionKey: REQUEST_CHUKIDAN_CONSULTATION,
            };
            dispatch(setSuccessState(successModalPayload));
        }
    }, [dispatch, success, type]);

    // Update Application Details on closure of modal
    useEffect(() => {
        if (successModalState.buttonActionKey === REQUEST_CHUKIDAN_CONSULTATION && !successModalState.isShow) {
            dispatch(resetActionKey());
            dispatch(getApplicationDetails(application.uuid));
            scrollToTop('smooth');
        }
    }, [application.uuid, success, type, dispatch, successModalState]);

    return (
        <>
            <ConsultationChecklists application={application} />

            <div className='text-center'>
                <Button onClick={requestConsultation} className='consult' type='primary'>
                    中企団オンライン相談窓口に相談を依頼する
                </Button>
                <div className='consult-note'>
                    <strong>～中企団オンライン相談窓口とは？～</strong>
                    <br />
                    専門家とのマッチングがうまくいかなかった場合にご利用いただける相談窓口です。
                    <br />
                    本サービスの提供元である中小企業福祉事業団の会員社会保険労務士、または会員中小企業診断士が、お客さまのご相談にご対応いたします。
                    <br />
                    <br />
                    ※東京在籍の専門家がご対応します。
                    <br />
                    ※オンライン相談のみのご対応となり、申請手続支援はできかねます。
                </div>
            </div>
        </>
    );
};

export default NoMatch;
