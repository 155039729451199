import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getErrorMessage } from 'api';
import { fetchTopic, fetchTopicList } from 'api/topic';

export const GET_TOPIC_LIST = 'GET_TOPIC_LIST';
export const GET_TOPIC = 'GET_TOPIC';

type TopicState = {
    type: string,
    loading: boolean,
    success: boolean | null,
    data: Topic[],
    topic: Topic,
    pagination: TopicPagination
}

type TopicResponse = {
    id: number,
    uuid: string,
    type: number,
    status: boolean,
    category_id: number,
    category_name: string,
    title: string,
    send_date: string,
    send_date_list: string,
    content: string,
    created_at: string,
}

type Topic = {
    id: number,
    uuid: string,
    type: number,
    status: boolean,
    categoryId: number,
    categoryName: string,
    title: string,
    sendDate: string,
    sendDateList: string,
    content: string,
    createdAt: string,
}

type TopicPagination = {
    currentPage: number,
    lastPage: number,
    perPage: number,
    total: number
}

export type TopicListParams = {
    type: number,
    perPage: number
}

export type TopicParams = {
    type: number,
    uuid: string
}

export const getTopicList = createAsyncThunk(
    'notification/topics',
    async (params: TopicListParams, { dispatch, rejectWithValue }) => {
        try {
            const response = await fetchTopicList(params);
            const { data, success } = response.data;

            if (success) {
                dispatch(setTopicList(data));
                dispatch(setPagination(response.data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const getTopic = createAsyncThunk(
    'notification/topics/:uuid',
    async (params: TopicParams, { dispatch, rejectWithValue }) => {
        try {
            const response = await fetchTopic(params);
            const { data, success } = response.data;

            if (success) {
                dispatch(setTopic(data));
                return true;
            }

            return rejectWithValue('Server error.');
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const topicSlice = createSlice({
    name: 'topic',
    initialState: {
        type: '',
        loading: false,
        success: null,
        data: [],
        topic: {} as Topic,
        pagination: {} as TopicPagination,
    } as TopicState,
    reducers: {
        setTopicList: (state, { payload }) => {
            state.data = payload.map((row: TopicResponse) => ({
                id: row.id,
                uuid: row.uuid,
                type: row.type,
                status: row.status,
                categoryId: row.category_id,
                categoryName: row.category_name,
                title: row.title,
                sendDate: row.send_date,
                sendDateList: row.send_date_list,
                content: row.content,
                createdAt: row.created_at,
            }));
        },
        setTopic: (state, { payload }: { payload: TopicResponse }) => {
            state.topic = {
                id: payload.id,
                uuid: payload.uuid,
                type: payload.type,
                status: payload.status,
                categoryId: payload.category_id,
                categoryName: payload.category_name,
                title: payload.title,
                sendDate: payload.send_date,
                sendDateList: payload.send_date_list,
                content: payload.content,
                createdAt: payload.created_at,
            };
        },
        setPagination: (state, { payload }) => {
            state.pagination.currentPage = payload.current_page;
            state.pagination.lastPage = payload.last_page;
            state.pagination.perPage = payload.per_page;
            state.pagination.total = payload.total;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTopicList.pending, (state) => {
            state.type = GET_TOPIC_LIST;
            state.loading = true;
            state.success = null;
            state.data = [];
            state.pagination = {} as TopicPagination;
        });
        builder.addCase(getTopicList.rejected, (state) => {
            state.type = GET_TOPIC_LIST;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getTopicList.fulfilled, (state) => {
            state.type = GET_TOPIC_LIST;
            state.loading = false;
            state.success = true;
        });
        builder.addCase(getTopic.pending, (state) => {
            state.type = GET_TOPIC;
            state.loading = true;
            state.success = null;
        });
        builder.addCase(getTopic.rejected, (state) => {
            state.type = GET_TOPIC;
            state.loading = false;
            state.success = false;
        });
        builder.addCase(getTopic.fulfilled, (state) => {
            state.type = GET_TOPIC;
            state.loading = false;
            state.success = true;
        });
    },
});

export const { setTopic, setTopicList, setPagination } = topicSlice.actions;
export const selectTopic = (state: RootState) => state.topic;
