import React from 'react';
import { Button, Col, Form, Radio, Row } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    getPolicyApplicationDetails,
    PolicyApplicationDetails,
    Schedule,
    SCHEDULE_POLICY_APP,
    SchedulePolicyConsultationRequest,
    selectPolicyApplicationDetails,
    setPolicyApplicationSchedule,
} from 'app/slice/policyApplicationDetailsSlice';
import Remarks from 'components/dashboard/working_regulation/common/Remarks';
import Topics from 'components/dashboard/working_regulation/common/Topics';

type Props = {
    application: PolicyApplicationDetails
}

const Waiting = (props: Props) => {
    const { application } = props;
    const { Item } = Form;
    const { Group } = Radio;

    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const { type, loading } = useAppSelector(selectPolicyApplicationDetails);

    const onSubmit = async (values: { schedule: Schedule, daidoJoins: boolean }) => {
        await dispatch(setPolicyApplicationSchedule({
            uuid: application.uuid,
            specialist_uuid: values.schedule.uuid,
            number: values.schedule.number,
        } as SchedulePolicyConsultationRequest));

        await dispatch(getPolicyApplicationDetails(application.uuid));
    };

    return (
        <>
            {application.daidoJoins ? (
                <div className='card-container gray ps-md-5 py-md-4'>
                    <div>
                        <CheckOutlined className='light-blue-4' />
                        <span className='ms-2'>大同生命営業担当者の同席を希望します</span>
                    </div>
                </div>
            ) : (
                <div className='card-container gray ps-md-5 py-md-4'>
                    <div>
                        <CloseOutlined className='light-blue-4' />
                        <span className='ms-2'>大同生命営業担当者の同席は希望しません</span>
                    </div>
                </div>
            )}

            {application.topics && <Topics topics={application.topics} />}
            {application.remarks && <Remarks remarks={application.remarks} />}

            <Form
                form={form}
                onFinish={onSubmit}
                layout='vertical'
                autoComplete='off'
            >
                <Row gutter={48}>
                    <Col className='text-gray'>面談時間を選択</Col>
                    <Col>
                        <Item
                            name='schedule'
                            rules={[{ required: true, message: '日程を選択してください' }]}
                        >
                            <Group defaultValue={1}>
                                {application.chukidanSchedules?.map((schedule: Schedule, index: number) => (
                                    <Radio key={index} value={schedule} disabled={schedule.expired}>
                                        <strong>{schedule.content}</strong>
                                    </Radio>
                                ))}
                            </Group>
                        </Item>
                        <div className='helper-text'>
                            ※当専門家を希望するも、上記でご都合が合わない場合は、大同生命の営業担当者にご連絡ください。
                        </div>
                    </Col>
                </Row>

                <div className='text-center mt-5'>
                    <Button type='primary' className='w-200px' htmlType='submit'
                            loading={loading && type === SCHEDULE_POLICY_APP}
                    >
                        依頼する
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default Waiting;
