import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getServices } from 'api/service';
import { getErrorMessage } from 'api';

export type Service = {
    title: string,
    url: string
}

export type ServiceState = {
    loading: boolean,
    success: boolean,
    data: Service[]
}

/**
 * calls login API
 **/
export const fetchServices = createAsyncThunk(
    'services',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            let response = await getServices();
            dispatch(setServices(response.data.data));
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const servicesSlice = createSlice({
    name: 'services',
    initialState: {
        loading: false,
        success: false,
        data: [],
    } as ServiceState,
    reducers: {
        setServices: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchServices.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.data = [];
        });
        builder.addCase(fetchServices.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(fetchServices.rejected, (state) => {
            state.loading = false;
            state.success = false;
        });
    },
});

export const selectServices = (state: RootState) => state.services;
export const { setServices } = servicesSlice.actions;
