import React from 'react';
import { Col, Row } from 'antd';

type Props = {
    remarks: string
}

const Remarks = (props: Props) => {
    const { remarks } = props;

    return (
        <div className='card-container light-blue d-block'>
            <Row>
                <Col md={3} xs={12}>
                    <strong>相談内容詳細</strong>
                </Col>
                <Col>
                    <div className='ms-4'>
                        {remarks || '特になし'}
                    </div>
                </Col>
            </Row>
        </div>
    );

};

export default Remarks;
