import React, { useEffect } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tooltip } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { PolicyApplicationDetails } from 'app/slice/policyApplicationDetailsSlice';
import { getPolicyApplications, selectPolicyApplications } from 'app/slice/policyApplicationListSlice';
import { Link, useSearchParams } from 'react-router-dom';
import { scrollToTop, useParamsState } from 'lib/utils';
import { MeetingStatuses } from 'enums';
import { setSearchQuery } from 'app/slice/searchQuerySlice';

const { Column } = Table;

const WorkingRegulationMeetings = () => {
    // Constants for Application List Display
    const {
        policyApplications,
    } = useAppSelector(selectPolicyApplications);
    const [searchParams] = useSearchParams();

    const dispatch = useAppDispatch();
    const [page] = useParamsState<number>('page', 1, parseInt);

    // Get Applications and Application Statuses
    useEffect(() => {
        dispatch(getPolicyApplications());
    }, [dispatch]);

    // Return scroll to top on page change only
    useEffect(() => {
        scrollToTop('smooth');
    }, [page]);

    useEffect(() => {
        dispatch(setSearchQuery('?' + searchParams.toString()));
    }, [dispatch, searchParams]);

    const renderTooltipContent = (status: string) => {
        switch (status) {
            case MeetingStatuses.APPLIED:
                return '専門家がお客さまのお申し込みを確認している状態です。本日を含め、土日・祝日を除く３日以内に面談の候補日が届きますのでしばらくお待ちください。';
            case MeetingStatuses.WAITING:
                return '専門家から面談の候補日が届いています。お客さまの日時選択をお待ちしている状態です。（本日を含め、土日・祝日を除く３日を過ぎると「回答期限切れ」となります 。）';
            case MeetingStatuses.SCHEDULED:
                return '専門家との面談日時が決定した状態です。面談当日は、詳細画面に掲載されている「ミーティングURL」からアクセスしてください。';
            case MeetingStatuses.FINISHED:
                return '面談が完了した状態です。';
            case MeetingStatuses.CANCELED:
                return '面談はキャンセルとなりました。';
            default:
                return '';
        }
    };

    const renderStatus = (value: any, application: PolicyApplicationDetails) => {
        return (
            <Space>
                <span className='gutter-row consultation-list-card-title'
                      style={{ color: `${application.label?.color}` }}
                >
                    {application.label?.content}
                </span>
                <Tooltip title={renderTooltipContent(application.label?.content)}>
                    <InfoCircleOutlined className='status-info-icon' />
                </Tooltip>
            </Space>
        );
    };

    const renderActionButtons = (value: any, application: PolicyApplicationDetails) => {
        return (
            <div className='action-buttons'>
                <Link to={`/dashboard/working-regulation/meetings/${application.uuid}`}>
                    <Button>詳細</Button>
                </Link>
            </div>
        );
    };

    return (
        <div className='content kisoku-meetings'>
            <div className='header-container'>
                <div className='title'>面談一覧</div>
                <div className='title-helper-text'>
                    就業規則診断オンラインサービスは、本サービス提供元の中小企業福祉事業団のグループ会社「社会保険労務士法人中企団総研」が対応いたします。
                </div>
            </div>
            <Table className='custom-table' dataSource={policyApplications} rowKey='branchId' pagination={false}
                   scroll={{ x: 1000 }}
            >
                <Column title='ID' dataIndex='uuid' key='uuid' />
                <Column title='依頼日' dataIndex='createdAt' key='createdAt' />
                <Column title='タイトル' dataIndex='type' key='type' />
                <Column title='面談日' dataIndex='schedule' key='schedule' />
                <Column title='面談状態' dataIndex='label' key='label' render={renderStatus} />
                <Column className='text-center' width='10%' title='' dataIndex='actionButtons'
                        key='actionButtons' render={renderActionButtons}
                />
            </Table>
        </div>
    );
};

export default WorkingRegulationMeetings;
