import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, Skeleton } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FETCH_PROFILE, ProfileData, selectProfile } from 'app/slice/profileSlice';
import UnsubscribeModal from 'components/dashboard/UnsubscribeModal';
import {
    getEmployeeCountRanges,
    getIndustries,
    getInsurancePlans,
    getPrefectures,
    selectResources,
} from 'app/slice/resourcesSlice';
import { chunk, isArray } from 'lodash';

type Data = {
    name: string,
    value: string | number | string[],
    rowSpan?: number
}

const DashboardProfile = () => {
    const [data, setData] = useState<Data[]>([]);
    const profile = useAppSelector(selectProfile);
    const {
        prefectures,
        industries,
        employeeCountRanges,
    } = useAppSelector(selectResources);
    const dispatch = useAppDispatch();

    const [isUnsubscribeVisible, setIsUnsubscribeVisible] = useState(false);
    const [isResourcesLoading, setIsResourcesLoading] = useState(true);
    const onCloseUnsubscribeModal = () => setIsUnsubscribeVisible(false);
    const onOpenUnsubscribeModal = () => setIsUnsubscribeVisible(true);

    const parseData = useCallback((values: ProfileData) => {
        setData([
            { name: '会社名', value: values.company.name },
            { name: '企業名フリガナ', value: values.company.nameFurigana },
            { name: '担当者さま役職', value: values.company.representativePosition },
            { name: '', value: '' },
            { name: '担当者さま', value: values.profile.lastname + ' ' + values.profile.firstname },
            { name: '担当者さまフリガナ', value: values.profile.lastnameFurigana + values.profile.firstnameFurigana },
            { name: 'TEL', value: values.profile.phone },
            { name: 'メールアドレス', value: values.user.email },
            { name: '〒', value: values.company.postalCode },
            {
                name: '都道府県',
                value: prefectures.find(prefecture => prefecture.code === values.company.prefectureCode)?.name || '',
            },
            { name: '住所', value: values.company.address },
            { name: 'ビル名', value: values.company.buildingName },
            {
                name: '従業員数',
                value: employeeCountRanges.find(employeeCountRange => employeeCountRange.code === values.company.employeeCountRangeCode)?.name || '',
            },
            { name: 'パート/アルバイト数', value: values.company.parttimeEmployeeCount },
            {
                name: '業種',
                value: industries.find(industry => industry.code === values.company.industryCode)?.name || '',
            },
            { name: '就業規則の有無', value: values.company.laborRegulationsCompliant ? '有' : '無' },
            {
                name: '社会保険加入状況',
                value: values.insurancePlans.length > 0 ? values.insurancePlans.map(insurance => insurance.name) : '無',
                rowSpan: 2,
            },
            { name: '顧問社労士の有無', value: values.company.allowSharoushi ? '有' : '無' },
            { name: '顧問診断士の有無', value: values.company.allowShindanshi ? '有' : '無' },
        ]);
    }, [employeeCountRanges, industries, prefectures]);

    useEffect(() => {
        dispatch(getPrefectures());
        dispatch(getIndustries());
        dispatch(getInsurancePlans());
        dispatch(getEmployeeCountRanges());
    }, [dispatch]);

    useEffect(() => {
        if (prefectures.length > 0) {
            setIsResourcesLoading(false);
        }
    }, [prefectures, setIsResourcesLoading]);

    useEffect(() => {
        if (profile.type === FETCH_PROFILE && profile.success && !isResourcesLoading) {
            parseData(profile?.data);
        }
    }, [isResourcesLoading, parseData, profile?.data, profile.success, profile.type]);

    return (
        <div className='dashboard-profile'>
            {(isResourcesLoading || profile.loading) ? (
                <Skeleton active />
            ) : (
                <>
                    <table className='mt-3 w-100'>
                        {chunk(data, 2).map((row, index) => (
                            <tr key={index}>
                                {row.map((column, index2) => column.name === '' ? (
                                    <td key={index2} colSpan={2} className='border-0'></td>
                                ) : (
                                    <Fragment key={index2}>
                                        <td className='px-2 py-1 name' rowSpan={column.rowSpan ?? 1}>{column.name}</td>
                                        <td className='px-2 py-1 value' rowSpan={column.rowSpan ?? 1}>
                                            {isArray(column.value) ? column.value.map((value, index3) => (
                                                <li key={index3}>
                                                    <strong>{value}</strong>
                                                </li>
                                            )) : (
                                                <strong>{column.value}</strong>
                                            )}
                                        </td>
                                    </Fragment>
                                ))}
                            </tr>
                        ))}
                    </table>
                    <hr className='mt-5 mb-3 profile-separator' />
                    <Button className='unsubscribe-button' onClick={onOpenUnsubscribeModal} danger>退会</Button>
                    <UnsubscribeModal isVisible={isUnsubscribeVisible} onClose={onCloseUnsubscribeModal} />
                    <div className='personal-info'>
                        <div className='title'>大同生命営業担当者情報</div>
                        <div className='subtitle'>
                            <span className='me-4'>{profile.data.company?.daidoBranchName}</span>
                            <span className='me-4'>{profile.data.company?.daidoDepartmentName}</span>
                            <span>{profile.data.company?.daidoEmployeeName}</span>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default DashboardProfile;
