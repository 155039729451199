import React from 'react';
import { Button, Card } from 'antd';
import successIcon from 'assets/images/success_check.png';
import { Link } from 'react-router-dom';

const ForgotPasswordSuccess = () => {
    return (
        <Card className='success-modal password-change-success-modal'>
            <div className='title'>パスワード再発行</div>
            <img src={successIcon} alt={'chukidan-icon'}/>
            <div className='message'>新しいパスワードの設定が完了しました。<br/>
                以下のログインボタンよりログインしてください。
            </div>
            <Link to={'/'}><Button type={'primary'}>ログイン</Button></Link>
        </Card>
    );
};

export default ForgotPasswordSuccess;
