import { createAsyncThunk } from '@reduxjs/toolkit';
import { trackClick } from 'api/tracker';

export type TrackRequest = {
    type: number,
    uuid?: string,
    funds: string[]
}

/**
 * GET insurance plans
 **/
export const track = createAsyncThunk(
    'resource/insurancePlans',
    async (trackRequest: TrackRequest) => {
        try {
            await trackClick(trackRequest);
            return true;
        } catch (err) {
            return false;
        }
    },
);
