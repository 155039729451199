import React, { Fragment, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Modal, Radio, Row, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import { Topic, Type } from 'app/slice/policySlice';
import RequestMeetingConfirmationModal from 'components/dashboard/working_regulation/RequestMeetingConfirmationModal';

const { Option } = Select;
const { TextArea } = Input;

type Props = {
    topics: Topic[],
    types: Type[],
    isVisible: boolean,
    onClose: () => void,
}

export type MeetingRequest = {
    daidoJoins: boolean,
    type: number,
    topics: Topic[],
    remarks: string
}

const daidoManagerRules: Rule[] = [
    {
        required: true,
        message: '大同生命営業担当者の同席をご選択ください。',
    },
];

const consultationContentRules: Rule[] = [
    {
        required: true,
        message: '相談内容をご入力ください。',
    },
];

const interviewTypeRules: Rule[] = [
    {
        required: true,
        message: '面談を行う規程をご選択ください。',
    },
];

const consultationContentLabel = () => {
    return (
        <>相談内容詳細 <span className='red'>※面談を円滑に進めるため、ご相談事項を事前にご記入ください</span></>
    );
};

const RequestMeetingModal = (props: Props) => {
    const { topics, types, isVisible } = props;
    const { Item } = Form;
    const { Group } = Radio;

    const [data, setData] = useState<MeetingRequest>();

    const [isRequestMeetingConfirmationVisible, setIsRequestMeetingConfirmationVisible] = useState(false);
    const onSuccessRequestMeetingConfirmationModal = () => {
        onClose();
        setIsRequestMeetingConfirmationVisible(false);
    };
    const [form] = Form.useForm();
    const onCloseRequestMeetingConfirmationModal = () => setIsRequestMeetingConfirmationVisible(false);
    const onOpenRequestMeetingConfirmationModal = () => setIsRequestMeetingConfirmationVisible(true);

    const onFinish = (formData: MeetingRequest) => {
        setData(formData);
        onOpenRequestMeetingConfirmationModal();
    };

    const selectionLabel = (
        <span>
            特にご相談したいことをお聞かせください。<br />
            <span className='helper-text'>チェック項目（任意・複数選択可）</span>
        </span>
    );

    const onClose = () => {
        props.onClose();
        form.resetFields();
    };

    return (
        <>
            <Modal className='request-meeting-modal' visible={isVisible} onCancel={onClose} footer={null}>
                <div className='title mb-2'>オンライン面談依頼</div>
                <div className='text-center helper-text required-mark'>は必須項目</div>
                <Form
                    layout='vertical'
                    onFinish={onFinish}
                    form={form}
                >
                    <Item
                        name='daidoJoins'
                        label='大同生命営業担当者'
                        rules={daidoManagerRules}
                    >
                        <Group>
                            <Radio value={true}>同席を希望します</Radio>
                            <Radio value={false}>同席を希望しません</Radio>
                        </Group>
                    </Item>
                    <div className='helper-text mb-4'>
                        ※大同生命の営業担当者の同席の有無については、事前に営業担当者にご相談の上、ご選択ください。<br />
                        ※都合が合わない場合、同席できないこともありますので予めご了承ください。
                    </div>
                    <Item
                        name='type'
                        label='面談を行う規程'
                        rules={interviewTypeRules}
                    >
                        <Select placeholder='ご選択ください。'>
                            {types?.map((type) => (
                                <Option key={type.code} value={type.code}>{type.name}</Option>
                            ))}
                        </Select>
                    </Item>
                    <Item
                        name='topics'
                        label={selectionLabel}
                    >
                        <Checkbox.Group>
                            {topics?.map((topic, key) => (
                                <Fragment key={key}>
                                    <Checkbox value={topic}>{topic.name}</Checkbox><br />
                                </Fragment>
                            ))}
                        </Checkbox.Group>
                    </Item>
                    <Item
                        name='remarks'
                        label={consultationContentLabel()}
                        rules={consultationContentRules}
                    >
                        <TextArea placeholder='服務規律について、現在の内容で問題が無いか相談したい' rows={4} />
                    </Item>
                    <Item className='action-buttons'>
                        <Row gutter={[10, 10]}>
                            <Col span={12}>
                                <Button className='me-3' htmlType='button' onClick={onClose} block>
                                    戻る
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button type='primary' htmlType='submit' block>
                                    確認
                                </Button>
                            </Col>
                        </Row>
                    </Item>
                </Form>
            </Modal>

            <RequestMeetingConfirmationModal isVisible={isRequestMeetingConfirmationVisible}
                                             onClose={onCloseRequestMeetingConfirmationModal}
                                             onSuccess={onSuccessRequestMeetingConfirmationModal}
                                             request={data}
                                             types={types}
            />
        </>
    );
};

export default RequestMeetingModal;
