import React, { useEffect, useState } from 'react';
import { Button, Form, Table, Tooltip, Upload } from 'antd';
import {
    CloseOutlined,
    DeleteOutlined,
    DownloadOutlined,
    ExportOutlined,
    FileDoneOutlined,
    FilePdfOutlined,
    FileWordOutlined,
    InfoCircleOutlined,
    StopOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { UploadFile } from 'antd/es/upload/interface';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    checkCanCreateCompanyPolicyApplication,
    CompanyPolicy,
    CREATE_POLICY_APP,
    deleteCompanyPolicy,
    DUPLICATE_POLICY,
    GET_POLICY,
    getCompanyPoliciesAndVerifications,
    getCompanyPolicyTopics,
    getCompanyPolicyTypes,
    reset,
    selectCompanyPolicies,
    shareCompanyPolicy,
    UPLOAD_POLICY,
    uploadCompanyPolicy,
} from 'app/slice/policySlice';
import { PolicyStatuses, UploadFileType } from 'enums';
import { isArray, some } from 'lodash';
import UploadProcessingModal from 'components/dashboard/working_regulation/UploadProcessingModal';
import CustomModal from 'components/common/CustomModal';
import checkIcon from 'assets/images/success_check.png';
import questionIcon from 'assets/images/icon_question.png';
import errorIcon from 'assets/images/error.png';
import RequestMeetingModal from 'components/dashboard/working_regulation/RequestMeetingModal';
import { windowOpen } from 'lib/utils';
import UploadedFileList from 'components/dashboard/working_regulation/UploadedFileList';

const { Dragger } = Upload;
const { Column } = Table;

type FileTypeIconsProps = {
    fileType: string;
    data: CompanyPolicy;
}

const FileTypeIcons = (props: FileTypeIconsProps) => {
    const { fileType, data } = props;
    return (
        <>
            {!data.isPolicy ? <FileDoneOutlined style={{color: 'red'}}/> :
                fileType === UploadFileType.PDF_FILE ? <FilePdfOutlined /> :
                    fileType === UploadFileType.DOC_FILE || fileType === UploadFileType.DOCX_FILE ?
                        <FileWordOutlined /> :
                        ''
            }
        </>
    );
};

type ActionButtonProps = {
    data: CompanyPolicy;
    openModal?: (uuid: string) => void;
    downloadFile?: (url: string) => void;
}

const ShareButtons = (props: ActionButtonProps) => {
    const { data, openModal } = props;
    const onOpenShareFileModal = () => {
        if (openModal) {
            openModal(data.policyUuid);
        }
    };
    return (
        <>
            {data.isPolicy && data.shareable &&
            <div className={'kisoku-uploaded-file-table'}>
                {!data.shared && (
                    <Tooltip placement='top' title='診断申込'>
                        <Button className='mx-1' type='primary' icon={<ExportOutlined />}
                                onClick={onOpenShareFileModal}
                        />
                    </Tooltip>
                )}
            </div>
            }
        </>
    );
};

const DownloadButtons = (props: ActionButtonProps) => {
    const { data, downloadFile } = props;
    const onDownloadFileModal = () => {
        if (downloadFile) {
            downloadFile(data.downloadUrl);
        }
    };
    return (
        <>
            {data.isPolicy ?
                <div>
                    <Tooltip placement='top' title='アップロードしたファイルのダウンロード'>
                        <Button className='mx-1' type='primary' icon={<DownloadOutlined />}
                                onClick={onDownloadFileModal}
                        />
                    </Tooltip>
                </div>
                :
                <div>
                    <Tooltip placement='top' title='診断レポートのダウンロード'>
                        <Button className='mx-1' type='primary' icon={<DownloadOutlined />}
                                onClick={onDownloadFileModal}
                        />
                    </Tooltip>
                </div>
            }
        </>
    );
};

const DeleteButtons = (props: ActionButtonProps) => {
    const { data, openModal } = props;
    const onOpenDeleteFileModal = () => {
        if (openModal) {
            openModal(data.uuid);
        }
    };

    return (
        <div>
            {data.isPolicy &&
            <Tooltip placement='top' title='規程削除'>
                <Button className='mx-1' type='primary' danger icon={<DeleteOutlined />}
                        onClick={onOpenDeleteFileModal}
                />
            </Tooltip>
            }
        </div>
    );
};

const WorkingRegulationService = () => {
    const dispatch = useAppDispatch();
    const {
        data,
        topics,
        types,
        loading,
        newPolicyUuid,
        success,
        type,
        allowSubmission,
    } = useAppSelector(selectCompanyPolicies);
    const [isButtonEnabled, setButtonEnabled] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<UploadFile | null>(null);
    const [uploadedFileList, setUploadedFileList] = useState<UploadFile[]>([]);
    const [selectedPolicy, setSelectedPolicy] = useState({} as CompanyPolicy);
    const [submitPossible, setSubmitPossible] = useState(false);

    const [isUploadSuccessVisible, setIsUploadSuccessVisible] = useState(false);
    const onCloseUploadSuccessModal = () => setIsUploadSuccessVisible(false);
    const onOpenUploadSuccessModal = () => setIsUploadSuccessVisible(true);

    const [isDuplicateAlertVisible, setIsDuplicateAlertVisible] = useState(false);
    const onCloseDuplicateAlertModal = () => setIsDuplicateAlertVisible(false);
    const onOpenDuplicateAlertModal = () => setIsDuplicateAlertVisible(true);

    const [isDeleteFileVisible, setIsDeleteFileVisible] = useState(false);
    const onCloseDeleteFileModal = () => setIsDeleteFileVisible(false);
    const onOpenDeleteFileModal = () => setIsDeleteFileVisible(true);

    const [isShareFileVisible, setIsShareFileVisible] = useState(false);
    const onCloseShareFileModal = () => setIsShareFileVisible(false);
    const onOpenShareFileModal = () => setIsShareFileVisible(true);

    const [isRequestMeetingVisible, setIsRequestMeetingVisible] = useState(false);
    const onCloseRequestMeetingModal = () => setIsRequestMeetingVisible(false);
    const onOpenRequestMeetingModal = () => {
        if (allowSubmission) {
            // Make sure Application Creation Error Modal is closed
            setIsRequestMeetingVisible(true);
            onCloseApplicationCreationErrorModal();
        } else {
            // Make sure Request meeting Modal is closed
            onOpenApplicationCreationErrorModal();
            onCloseRequestMeetingModal();
        }
    };

    const [isFileUploadErrorVisible, setIsFileUploadErrorVisible] = useState(false);
    const onCloseFileUploadErrorModal = () => setIsFileUploadErrorVisible(false);
    const onOpenFileUploadErrorModal = () => setIsFileUploadErrorVisible(true);

    const [isApplicationCreationErrorVisible, setIsApplicationCreationErrorVisible] = useState(false);
    const onCloseApplicationCreationErrorModal = () => setIsApplicationCreationErrorVisible(false);
    const onOpenApplicationCreationErrorModal = () => setIsApplicationCreationErrorVisible(true);

    const [form] = Form.useForm();

    const downloadPolicyFile = (url: string) => {
        // In case pop-up is blocked
        window.open(url, '_blank') || window.location.replace(url);
    };

    const downloadVerificationFile = async (url: string) => {
        // In case pop-up is blocked
        await windowOpen(url);

        // Update data again after downloading
        dispatch(getCompanyPoliciesAndVerifications());
    };

    const deletePolicyFile = () => {
        if (selectedPolicy) {
            dispatch(deleteCompanyPolicy(selectedPolicy.uuid));
        }

        onCloseDeleteFileModal();
    };

    const openDeleteFileModal = (uuid: string) => {
        if (data) {
            setSelectedPolicy(
                data.find((data: CompanyPolicy) => {
                    return data.uuid === uuid;
                })!,
            );
            onOpenDeleteFileModal();
        }
    };

    const sharePolicyFile = () => {
        if (selectedPolicy) {
            dispatch(shareCompanyPolicy(selectedPolicy.policyUuid));
        }

        onCloseShareFileModal();
    };

    const shareUploadedPolicyFile = () => {
        if (newPolicyUuid) {
            dispatch(shareCompanyPolicy(newPolicyUuid));
        }

        onCloseUploadSuccessModal();
    };

    const openShareFileModal = (uuid: string) => {
        if (data) {
            setSelectedPolicy(
                data.find((data: CompanyPolicy) => {
                    return data.policyUuid === uuid;
                })!,
            );
            onOpenShareFileModal();
        }
    };

    // Check when it is possible that user action can allow
    // or disallow them from being able to apply for consultation
    useEffect(() => {
        setSubmitPossible(some(data, policy => policy.status === '面談申込可能'));
        dispatch(checkCanCreateCompanyPolicyApplication());
    }, [data, dispatch]);

    const onDraggerChange = (info: UploadChangeParam) => {
        setButtonEnabled(info !== null);
        setUploadedFile(info.file);
        setUploadedFileList(info.fileList);
    };

    useEffect(() => {
        if (uploadedFileList.length === 0) {
            setButtonEnabled(false);
            setUploadedFile(null);
        }
    }, [uploadedFileList]);

    const uploadFile = () => {
        const formData = new FormData();
        for (let [key, value] of Object.entries<any>(form.getFieldsValue())) {
            value = isArray(value) ? value : [value];
            for (let data of value) {
                data = data?.originFileObj ?? data;
                //Add if the value is not undefined or a field array
                if (data !== undefined || (key.includes('[]'))) {
                    // Only include files visilbe in File List
                    if (data.uid && uploadedFileList.find((item) => item.uid === data.uid)) {
                        formData.append(key, data);
                    }
                }
            }
        }
        dispatch(uploadCompanyPolicy(formData));
        //Clear Input values after submit
        setUploadedFileList([]);
    };

    const fileUpload = (e: { fileList: object, file: object }) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

// Get Application Details
    useEffect(() => {
        dispatch(getCompanyPoliciesAndVerifications());
        dispatch(getCompanyPolicyTopics());
        dispatch(getCompanyPolicyTypes());
    }, [dispatch]);

// Update details
    useEffect(() => {
        if (success) {
            if (type !== GET_POLICY) {
                if (type === UPLOAD_POLICY) {
                    onOpenUploadSuccessModal();
                    setUploadedFile(null);
                    setButtonEnabled(false);
                    dispatch(reset());
                }

                dispatch(getCompanyPoliciesAndVerifications());
            }

            setSelectedPolicy({} as CompanyPolicy);
        } else if (success === false && type === DUPLICATE_POLICY) {
            onOpenDuplicateAlertModal();
        } else if (success === false && type === UPLOAD_POLICY) {
            onOpenFileUploadErrorModal();
        } else if (success === false && type === CREATE_POLICY_APP) {
            onOpenApplicationCreationErrorModal();
        }
    }, [dispatch, success, type]);

    const renderTooltipContent = (status: string) => {
        switch (status) {
            case PolicyStatuses.WAITING_FOR_REPORT:
                return '専門家が貴社の規程を診断している状態です。10営業日以内に診断結果レポートをご回答します。';
            case PolicyStatuses.REPORT_DOWNLOADABLE:
                return '診断結果レポートが閲覧可能な状態です。右の「ダウンロードボタン」よりご覧になれます。';
            case PolicyStatuses.CONSULTATION_POSSIBLE:
                return '診断結果レポートの内容について、専門家へご相談ができる状態です。右上の「面談依頼」よりお申し込みいただけます。（面談は6ヶ月以内に1回限りとなります。）';
            case PolicyStatuses.NOT_SHARED:
                return '貴社の規程がアップロードされた状態です。「診断申込」ボタンを押して、規程を専門家にご共有ください。共有後、10営業日以内に診断結果レポートをご回答します。';
            default:
                return '';
        }
    };

    const renderPreviewUrl = (text: string, record: CompanyPolicy) => {
        return (
            <a href={record.previewUrl} target='_blank' rel='noreferrer'>{text}</a>
        );
    };

    const renderStatus = (text: string, record: CompanyPolicy) => {
        return (
            <>
                <span className='me-2'>{record.shareable && record.status}</span>
                {record.status && record.shareable && (
                    <Tooltip title={renderTooltipContent(record.status)}>
                        <InfoCircleOutlined className='status-info-icon' />
                    </Tooltip>
                )}
            </>
        );
    };

    const renderCreatedAt = (text: string, record: CompanyPolicy) => {
        return (
            <>
                <span
                    className='me-2'>{(record.shareable) && record.createdAt}</span>
            </>
        );
    };

    return (
        <>
            <div className='content working-regulation'>
                <div className='title'>診断申込管理</div>
                <div className='my-3 title-note'>
                    <span style={{fontWeight: '900'}}>本サービスの対象となるのは、就業規則・賃金規程・育児介護休業規程のみです。</span>その他の付属規程は対象外となります。あらかじめご了承ください。
                    <br />
                    アップロードしていただきました就業規則、その他各規程のファイルは、サービス提供元である中小企業福祉事業団のグループ企業の社会保険労務士事務所が、
                    <br />
                    アップロードの状況確認、診断レポートの発行、その他お客さまへのご確認のため閲覧いたします。
                </div>
                <div className='my-3 text-center title-primary-lg'>
                    本診断システムは「就業規則」「賃金規程」「育児介護休業規程」を１セットの規程として診断します。
                    <br />
                    各規程のファイルが個別に分かれている場合は、一括アップロードをお願いいたします。
                </div>
                <div className={'my-3 mx-auto instruction-container-one'}>
                    ①以下の「アップロードするファイルを選択してください。」をクリックしてファイルを選択するか、
                    <br />
                    枠内にファイルをドラッグアンドドロップし、診断対象となる規程をアップロードしてください。
                    <br />
                    ※ファイルを複数選択することで、一括アップロードが可能です。
                    <br />
                    ※アップロードできるファイルは、「PDF形式」もしくは「Microsoft Word形式」のみとなります。
                </div>

                <Form form={form} onFinish={uploadFile}>
                    <Form.Item
                        name='policies[]'
                        getValueFromEvent={fileUpload}
                        style={{ marginBottom: '0px' }}
                        className={'mx-auto file-upload-input'}
                    >
                        <Dragger accept='.doc,.docx,.pdf' multiple={true} showUploadList={false}
                                 fileList={uploadedFileList}
                                 onChange={onDraggerChange}>
                            <p className='ant-upload-drag-icon'>
                                <UploadOutlined />
                            </p>
                            <p className='ant-upload-hint'>
                                <div>ファイルを選択してアップロードしてください。</div>
                                <div>最大アップロードサイズ：10MB</div>
                            </p>
                        </Dragger>
                    </Form.Item>
                    <UploadedFileList setUploadedFileList={setUploadedFileList} uploadedFile={uploadedFile}
                                      uploadedFileList={uploadedFileList} />
                    <Form.Item className='text-center'>
                        <Button className='w-200px' type='primary' htmlType='submit' disabled={!isButtonEnabled}
                                style={{ marginTop: '24px' }}>
                            アップロード
                        </Button>
                    </Form.Item>
                    <p className='mx-auto instruction-container-one'>
                        ②アップロード完了の画面で「診断申込」をクリックしていただくか、アップロード後に追加される
                        <br />
                        下記一覧から「診断申込ボタン」<Button className='mx-1 pointer-event-none' type='primary'
                                               icon={<ExportOutlined />} />をクリックしていただくことで診断のお申し込みが完了します。
                        <br />
                        ※<span style={{ textDecoration: 'underline' }}>診断申込が完了すると、「診断申込」ボタンは非表示となります。</span>
                        <br/>
                        ※<span style={{ textDecoration: 'underline' }}>『結果レポート』は１０営業日以内に、下記一覧内にてご納品いたします。</span>
                    </p>
                    {data &&
                    <Table className='custom-table'
                           dataSource={data}
                           pagination={false}
                           loading={loading}
                           rowKey='uuid'
                           rowClassName={((record, index) => record.isBordered ? 'bordered' : 'not-bordered')}
                           scroll={{ x: 1000 }}
                    >
                        <Column width='1%' title='' dataIndex='fileType' key='fileType'
                                render={(fileType, record) => <FileTypeIcons data={record as CompanyPolicy}
                                                                             fileType={fileType} />}
                        />
                        <Column width='30%' title='ファイル名' dataIndex='name' key='name' render={renderPreviewUrl} />
                        <Column width='15%' title='アップロード日' dataIndex='createdAt' key='createdAt'
                                render={renderCreatedAt} />
                        <Column width='15%' title='ステータス' dataIndex='status' key='status' render={renderStatus} />
                        <Column width='15%' title='診断申込' dataIndex='isPolicy' key='isPolicy'
                                render={(isPolicy, record) => <ShareButtons data={record as CompanyPolicy}
                                                                            openModal={openShareFileModal} />} />
                        <Column width='15%' title='ダウンロード' dataIndex='isPolicy' key='isPolicy' align={'center'}
                                render={(isPolicy, record) => <DownloadButtons data={record as CompanyPolicy}
                                                                               downloadFile={isPolicy ? downloadPolicyFile : downloadVerificationFile} />}
                        />
                        <Column width='15%' title='ファイルの削除' dataIndex='isPolicy' key='isPolicy' align={'center'}
                                render={(isPolicy, record) => <DeleteButtons data={record as CompanyPolicy}
                                                                             openModal={openDeleteFileModal} />}
                        />
                    </Table>
                    }
                </Form>
                <div className='mt-4 text-center'>
                    <Button type='primary'
                            disabled={!data || data.length === 0 || !submitPossible}
                            onClick={onOpenRequestMeetingModal}
                    >
                        面談依頼
                    </Button>
                </div>
                <div className='mt-5 mx-auto instruction-container-two'>
                    ・本サービスの面談依頼は、<span style={{ textDecoration: 'underline' }}>『診断レポート』をダウンロードしていただいた後</span>、お申し込みいただけます。
                    <br />
                    <span style={{paddingLeft: 15}}>
                        ※ 診断レポートの「ダウンロードボタン」<Button className='mx-1 pointer-event-none' type='primary'
                                               icon={<DownloadOutlined />} />をクリックすると、ステータスが「面談申込可能」に更新され、上記「面談依頼」ボタンが押せるようになります。
                    </span>
                    <br />
                    ・面談依頼は、診断レポートがアップロードされた日から6ヶ月に以内に１回のみとなります。（１企業につき１回限り）
                    <br />
                    ・面談は、専門家の社会保険労務士が対応いたします。
                </div>
            </div>
            {/*Modal for upload success*/}
            <CustomModal
                isVisible={isUploadSuccessVisible}
                onClose={onCloseUploadSuccessModal}
                title='アップロードが完了しました。'
                icon={checkIcon}
                text='今アップロードした規程の診断を申し込みますか。'
                showCancelButton={true}
                cancelButtonText='今は申し込まない'
                cancelOnClick={onCloseUploadSuccessModal}
                showConfirmButton={true}
                confirmButtonText='診断申込'
                confirmOnClick={shareUploadedPolicyFile}
            />
            {/*Modal for duplicated name alert*/}
            <CustomModal
                isVisible={isDuplicateAlertVisible}
                onClose={onCloseDuplicateAlertModal}
                title='アップロードエラー'
                icon={errorIcon}
                text='同じ名前のファイルが既に存在しているので、アップロードできません。'
                showCancelButton={false}
                showConfirmButton={true}
                confirmButtonText='閉じる'
                confirmOnClick={onCloseDuplicateAlertModal}
            />
            {/*Modal for deleting file*/}
            <CustomModal
                isVisible={isDeleteFileVisible}
                onClose={onCloseDeleteFileModal}
                title='ファイルを削除'
                icon={errorIcon}
                text='ファイルを削除してもよろしいでしょうか。'
                showCancelButton={true}
                cancelButtonText='キャンセル'
                cancelOnClick={onCloseDeleteFileModal}
                showConfirmButton={true}
                confirmButtonText='確認'
                confirmOnClick={deletePolicyFile}
            />
            {/*Modal for sharing file*/}
            <CustomModal
                isVisible={isShareFileVisible}
                onClose={onCloseShareFileModal}
                title='診断申込'
                icon={questionIcon}
                text='アップロードした就業規則の診断を申し込みますか？'
                showCancelButton={true}
                cancelButtonText='今は申し込まない'
                cancelOnClick={onCloseShareFileModal}
                showConfirmButton={true}
                confirmButtonText='申し込む'
                confirmOnClick={sharePolicyFile}
            />
            {/*Modal when a file cannot be uploaded*/}
            <CustomModal
                isVisible={isFileUploadErrorVisible}
                onClose={onCloseFileUploadErrorModal}
                title='アップロード不可能'
                icon={errorIcon}
                text='ファイルのアップロードが出来ません。'
                showCancelButton={false}
                showConfirmButton={true}
                confirmButtonText='閉じる'
                confirmOnClick={onCloseFileUploadErrorModal}
            />
            {/*Modal when an application cannot be created*/}
            <CustomModal
                isVisible={isApplicationCreationErrorVisible}
                onClose={onCloseApplicationCreationErrorModal}
                title='面談の申込はできません'
                icon={errorIcon}
                text='就業規則診断オンラインサービスでは、無料面談は1回のみとなっております。あしからずご了承ください。'
                showCancelButton={false}
                showConfirmButton={true}
                confirmButtonText='閉じる'
                confirmOnClick={onCloseApplicationCreationErrorModal}
            />
            <UploadProcessingModal isVisible={type === UPLOAD_POLICY && loading} />
            <RequestMeetingModal
                topics={topics}
                types={types}
                isVisible={isRequestMeetingVisible}
                onClose={onCloseRequestMeetingModal}
            />
        </>
    );
};

export default WorkingRegulationService;
