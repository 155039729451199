import React, { useCallback, useEffect } from 'react';
import SearchForm from 'components/kouteki/SearchForm';
import SearchResult from 'components/kouteki/SearchResult';
import {
    FundsListRequest,
    getFunds,
    resetChecked,
    resetFunds,
    resetPagination,
    setParams,
} from 'app/slice/fundsListSlice';
import { useAppDispatch } from 'app/hooks';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { pickBy } from 'lodash';

const PublicSupportSearch = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const fetchResult = useCallback((values: FundsListRequest) => {
        dispatch(setParams(values));
        dispatch(resetPagination());
        dispatch(resetChecked());
        dispatch(getFunds());
    }, [dispatch]);

    const searchFormOnSubmit = (values: FundsListRequest) => {
        fetchResult(values);
        navigate({
            search: '?' + createSearchParams(pickBy(values, value => value)),
        }, { replace: true });
    };

    useEffect(() => {
        dispatch(resetFunds());
    }, [dispatch]);

    return (
        <section className='content'>
            <div className='search-form-container search-form-margin-top'>
                <SearchForm onSubmit={searchFormOnSubmit} submitImmediately={true} />
            </div>
            <SearchResult />
        </section>
    );
};

export default PublicSupportSearch;
