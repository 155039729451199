import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPolicyApplicationsApi } from 'api/application';
import { PolicyApplicationDetails } from 'app/slice/policyApplicationDetailsSlice';
import { RootState } from 'app/store';

export type { Label } from 'api/application';

// Pagination Data Structure returned by API call
export type Pagination = {
    count: number,
    total: number,
    perPage: number,
    currentPage: number,
    lastPage: number,
}

/**
 * GET applications list
 **/
export const getPolicyApplications = createAsyncThunk(
    '/company/applications',
    async (_, { dispatch }) => {
        try {
            const response = await getPolicyApplicationsApi();
            dispatch(setPolicyApplications(response.data));
            return response.data;
        } catch (err) {
            return false;
        }
    },
);

/**
 * Create Application Slice
 */
export const policyApplicationListSlice = createSlice({
    name: 'applications',
    initialState: {
        policyApplications: [{}] as PolicyApplicationDetails[],
        pagination: {} as Pagination,
    },
    reducers: {
        setPolicyApplications: (state, { payload }) => {
            state.policyApplications = payload.data;
            state.policyApplications.forEach((application: any) => {
                application.isDeadline = application.is_deadline;
                application.createdAt = application.created_at;
            });

            state.pagination = payload.pagination;
            state.pagination.perPage = payload.pagination.per_page;
            state.pagination.currentPage = payload.pagination.current_page;
            state.pagination.lastPage = payload.pagination.last_page;
        },
    },
});

export const { setPolicyApplications } = policyApplicationListSlice.actions;
export const selectPolicyApplications = (state: RootState) => state.policyApplications;
