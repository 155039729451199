import React from 'react';
import { Breadcrumb as BreadCrumbs } from 'antd';
import { Link } from 'react-router-dom';

type Props = {
    breadcrumb: Breadcrumb[];
    style?: React.CSSProperties
}

export type Breadcrumb = {
    name: string;
    url: string;
}

/**
 * Creates Breadcrumb
 * @param { Props } props
 * @return BreadCrumbs
 * */
const Breadcrumbs = (props: Props) => {
    const { breadcrumb, style } = props;

    return (
        <BreadCrumbs separator='>' style={style}>
            {breadcrumb.map((breadCrumbProps) =>
                <BreadCrumbs.Item key={breadCrumbProps.url}>  
                {breadCrumbProps.url !== '' ? (
                    <Link to={breadCrumbProps.url}>{breadCrumbProps.name}</Link>
                ) : (
                    breadCrumbProps.name
                )}
                </BreadCrumbs.Item>,
            )}
        </BreadCrumbs>
    );
};

export default Breadcrumbs;
