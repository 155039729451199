import { getAxiosClientWithToken } from './index';

export type GetApplicationsRequest = {
    keywords?: string,
    page?: number,
    status?: number,
}

export type ScheduleConsultationRequest = {
    uuid: string,
    specialist_uuid: string,
    number: number,
    daido_joins: boolean,
    emergency_contact_number: string,
}

export type CancelConsultationRequest = {
    uuid: string,
    reason: string,
}

export type Label = {
    content: string,
    color: string,
}

export type Schedule = {
    uuid?: string,
    number: number,
    content: string,
    expired: boolean
}

export type SchedulePolicyConsultationRequest = {
    uuid: string,
    specialist_uuid: string,
    number: number,
}

/**
 * Function to call API and get a list of Applications belonging to the logged-in User
 **/
export const getApplicationsApi = (request: GetApplicationsRequest) => {
    const restClient = getAxiosClientWithToken();

    // Remove keywords key if empty
    if (request.keywords === undefined || request.keywords === '') {
        delete request['keywords'];
    }

    // Remove per_page key if empty or if it has an invalid value
    if (request.page === undefined || request.page <= 0) {
        delete request['page'];
    }

    // Remove status key if empty or invalid
    if (request.status === undefined || request.status === -1) {
        delete request.status;
    }

    return restClient.get(`/company/applications`, { params: request });
};

/**
 * Function to call API and get a list of Application Status Types
 **/
export const getApplicationStatusTypesApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/company/status-types/application`);
};

/**
 * Function to call API and get a specific Application that belongs to the current user
 **/
export const getApplicationDetailsApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/company/applications/${uuid}`);
};

/**
 * Function to call API and set the Consultation Schedule for a given Application
 */
export const scheduleConsultationApi = (request: ScheduleConsultationRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/company/applications/${request.uuid}/schedule`, request);
};

/**
 * Function to call API and set the Consultation Schedule for a given Application
 */
export const cancelConsultationApi = (request: CancelConsultationRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/company/applications/${request.uuid}/cancel`, request);
};

/**
 * Function to call API and get a specific Application that belongs to the current user
 **/
export const getSpecialistProfileApi = (uuid: string, specialist: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/company/applications/${uuid}/specialist/${specialist}`);
};


/**
 * Function to call API and request Chukidan Consultation for the specified Application
 **/
export const requestChukidanConsultationApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/company/applications/${uuid}/request-chukidan`);
};

/**
 * Function to call API and get a list of Company Policy related Applications
 **/
export const getPolicyApplicationsApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/company/policies/applications`);
};

/**
 * Function to call API and get a specific Company Policy Application
 **/
export const getPolicyApplicationDetailsApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/company/policies/applications/${uuid}`);
};

/**
 * Function to call API and set the Consultation Schedule for a given Application
 */
export const schedulePolicyConsultationApi = (request: SchedulePolicyConsultationRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/company/policies/applications/${request.uuid}/schedule`, request);
};

/**
 * Function to call API and set the Consultation Schedule for a given Application
 */
export const noChukidanConsultationRequestApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/company/applications/${uuid}/no-match`);
};
