import { ChangeEventHandler, useEffect, useState } from 'react';
import { Col, Empty, Input, Pagination, Row, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getApplications, getApplicationStatusTypes, selectApplications } from 'app/slice/applicationListSlice';
import ConsultationListCard from 'components/consultation/ConsultationListCard';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useParamsState } from 'lib/utils';
import { setSearchQuery } from 'app/slice/searchQuerySlice';

const ConsultationList = () => {
    // Search constant
    const { Search } = Input;

    // Constants for Application List Display
    const {
        applications,
        applicationStatusTypes,
        pagination,
    } = useAppSelector(selectApplications);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // Filter Function Constants
    const [keywords] = useParamsState('keyword', '');
    const [page, setPage] = useParamsState<number>('page', 1, parseInt);
    const [selectedStatus] = useParamsState<number>('status', -1, parseInt);

    const [keywordChange, setKeywordChange] = useState<string>();

    const onChangeKeyword: ChangeEventHandler = (event: any) => {
        setKeywordChange(event.target.value);
    };

    const onSearch = (value: string) => {
        searchParams.set('page', '1');
        searchParams.set('keyword', value);
        setSearchParams(searchParams);
    };

    const onChangeStatus = (value: number) => {
        searchParams.set('page', '1');
        searchParams.set('status', value.toString());
        setSearchParams(searchParams);
    };

    useEffect(() => {
        setKeywordChange(keywords);
    }, [keywords]);

    useEffect(() => {
        dispatch(setSearchQuery('?' + searchParams.toString()));
    }, [dispatch, searchParams]);

    // Get Applications and Application Statuses
    useEffect(() => {
        dispatch(getApplications({
            keywords: keywords,
            page: page,
            status: selectedStatus,
        }));

        dispatch(getApplicationStatusTypes());
    }, [dispatch, keywords, navigate, page, selectedStatus]);

    // Return scroll to top on page change only
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, [page]);

    return (
        <div className='content consultation-list'>
            <div className='header-container'>
                <div className='title'>面談依頼一覧</div>
                <div className='page-actions'>
                    <Row gutter={[10, 10]}>
                        <Col>
                            <Select className='page-actions-items' value={selectedStatus}
                                    onChange={onChangeStatus}
                            >
                                <Select.Option value={-1}>全て</Select.Option>
                                {applicationStatusTypes.map((row) => (
                                    <Select.Option
                                        key={row.code}
                                        value={row.code}
                                    >{row.name}</Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col>
                            <Search placeholder='検索'
                                    onSearch={onSearch}
                                    value={keywordChange}
                                    onChange={onChangeKeyword}
                                    className='page-actions-items'
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='subtitle'>面談数：{pagination.total}件</div>

            {applications.length === 0 ? (
                <Empty />
            ) : applications.map((row) => (
                row.uuid && <ConsultationListCard application={row} />
            ))}

            <div className='text-center consultation-list-pagination'>
                {(pagination?.total > 0) &&
                    <Pagination className='d-inline float-center' defaultPageSize={pagination.perPage}
                                current={page}
                                total={pagination.total}
                                onChange={setPage}
                                showSizeChanger={false}
                    />
                }
            </div>
        </div>
    );

};

export default ConsultationList;
