import React, { useEffect, useState } from 'react';
import { Button, List, Pagination, Spin } from 'antd';
import EligibilityModal from './EligibilityModal';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FundDataRow, getFunds, selectFundsList, setPage } from 'app/slice/fundsListSlice';
import { getFundRequirements, resetFundRequirements, selectFundRequirements } from 'app/slice/fundRequirementsSlice';
import { FundsLocationState } from 'pages/kouteki/DiagnoseResult';
import { track } from 'app/slice/trackerSlice';
import SearchCard from 'components/kouteki/SearchCard';
import { selectAuth } from 'app/slice/authSlice';
import { SearchType } from 'enums';
import { scrollToTop } from 'lib/utils';


const SearchResult = () => {
    const [isEligibilityModalVisible, setIsEligibilityModalVisible] = useState(false);
    const { success, loading, data, page, perPage, pagination, checked, params } = useAppSelector(selectFundsList);
    const fundRequirements = useAppSelector(selectFundRequirements);
    const { uuid } = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const onCloseEligibilityModal = () => {
        dispatch(resetFundRequirements());
        setIsEligibilityModalVisible(false);
    };
    const onOpenEligibilityModal = () => setIsEligibilityModalVisible(true);

    const onSubmit = () => {
        dispatch(getFundRequirements());
    };

    const onEligibilitySubmit = (state: FundsLocationState) => {
        dispatch(resetFundRequirements());
        dispatch(track({ type: 1, uuid, funds: state.funds.map(fund => fund.code) }));
        navigate('/kouteki/funds/diagnose', { state });
    };

    const onChangePage = (page: number) => {
        dispatch(setPage(page));
        dispatch(getFunds());
        scrollToTop();
    };

    useEffect(() => {
        if (fundRequirements.success) {
            onOpenEligibilityModal();
        }
    }, [fundRequirements.success]);

    const renderItem = (item: FundDataRow) => <SearchCard key={item.code} item={item} />;

    return (
        <div className='search-content'>
            {(loading || success === null) ? (
                <Spin className='d-block my-5' size='large' />
            ) : (
                <>
                    <div className='text-center mt-5'>
                        <div className='page-count'>
                            {perPage * (page - 1) + 1}-{perPage * (page - 1) + data.length}件
                            ／
                            {pagination?.total}件
                        </div>
                        {data.length > 0 ? (
                            params.search_type === SearchType.CATEGORY ? (
                                <p>診断したい公的資金のチェックボックスを選択して、『診断』をクリックしてください。最大5件の同時診断が可能です。また各種詳細ページからも個別に診断できます。</p>
                            ) : (
                                <p>各公的資金の「詳細」ボタンから、詳細情報の確認と診断ができます。</p>
                            )
                        ) : (
                            <p>選択いただいた内容では、該当する公的資金はございませんでした。</p>
                        )}

                    </div>
                    <div className='search-result mb-4'>
                        <List dataSource={data} renderItem={renderItem} />
                    </div>
                </>
            )}
            {(data.length > 0 && params.search_type === SearchType.CATEGORY) && (
                <>
                    <Button onClick={onSubmit} className='submit-button'
                            disabled={checked.length === 0 || fundRequirements.loading}
                    >
                        {fundRequirements.loading ? <Spin size='small' /> : '診断する'}
                    </Button>
                    <span className='ms-3'>※最大5件を同時に診断可</span>

                </>
            )}
            {pagination?.total > 0 && (
                <Pagination className='d-inline float-end' defaultPageSize={perPage}
                            current={page}
                            total={pagination?.total}
                            onChange={onChangePage}
                            showSizeChanger={false}
                />
            )}
            <EligibilityModal isVisible={isEligibilityModalVisible} onClose={onCloseEligibilityModal}
                              onSubmit={onEligibilitySubmit}
            />
        </div>
    );
};

export default SearchResult;
