import React from 'react';
import { Modal } from 'antd';

type Props = {
    isVisible: boolean,
    onClose: () => void,
    hasRequiredError: boolean,
    hasPatternError: boolean,
}

const ValidationErrorModal = (props: Props) => {
    const { isVisible, onClose, hasPatternError, hasRequiredError } = props;

    return (
        <Modal className='validation-error-modal' visible={isVisible} onCancel={onClose} footer={null}>
            {hasRequiredError && (
                <div>
                    ※必須項目が入力されていません。
                    <div className='ms-2'>（エラー箇所に赤文字でメッセージを記載しています）</div>
                </div>
            )}
            {hasPatternError && (
                <div>
                    ※登録できない文字が入力されています。
                    <div className='ms-2'>（エラー箇所に赤文字でメッセージを記載しています）</div>
                </div>
            )}
        </Modal>
    );
};

export default ValidationErrorModal;
