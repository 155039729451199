import React from 'react';
import { Col, Image, Input, Modal, Row } from 'antd';
import avatarDefault from 'assets/images/avatardefault.png';
import { SpecialistProfileData } from 'app/slice/applicationDetailsSlice';

const { TextArea } = Input;

type Props = {
    isVisible: boolean,
    onClose: () => void,
    profileData: SpecialistProfileData
}

const ProfilePreviewModal = (props: Props) => {

    const { isVisible, onClose, profileData } = props;

    return (
        <Modal className='profile-preview-modal' visible={isVisible} footer={null} onCancel={onClose}>
            <div className='title'>プロフィール確認</div>
            <Row>
                <Col className='my-2' md={24}>
                    <div className='label'>プロフィール画像</div>
                    <br />
                    <Image
                        width={100}
                        src={profileData.profileImageUrl || avatarDefault}
                    />
                </Col>
                <Col className='my-2' md={12}>
                    <div className='label'>氏名：</div>
                    {profileData.name}
                </Col>
                <Col className='my-2' md={12}>
                    <div className='label'>氏名（カナ）：</div>
                    {profileData.nameKana}
                </Col>
                <Col className='my-2' md={12}>
                    <div className='label'>都道府県：</div>
                    {profileData.prefecture}
                </Col>
                <Col className='my-2' md={12}>
                    <div className='label'>住所：</div>
                    {profileData.address}
                </Col>
                <Col className='my-2' md={12}>
                    <div className='label'>ビル名：</div>
                    {profileData.buildingName}
                </Col>
                <Col className='my-2' md={24}>
                    <div className='label'>対応可能地域：</div>
                    {profileData.prefectures?.join(', ')}
                </Col>
                <Col className='my-2' md={12}>
                    <div className='label'>その他対応可能地域：</div>
                    {profileData.otherAreas}
                </Col>
                <Col className='my-2' md={12}>
                    <div className='label'>その他の事務所住所：</div>
                    {profileData.otherAddress}
                </Col>
                <Col className='my-2' md={24}>
                    <div className='label'>事務所の強みやPR：</div>
                    <TextArea rows={2} readOnly value={profileData?.officePR} />
                </Col>
                <Col className='my-2' md={24}>
                    <div className='label'>経歴・実績：</div>
                    <TextArea rows={2} readOnly value={profileData?.officeHistory} />
                </Col>
                <Col className='my-2' md={24}>
                    <div className='label'>保有資格：</div>
                    <TextArea rows={2} readOnly value={profileData?.qualifications} />
                </Col>
                <Col className='my-2' md={24}>
                    <div className='label'>得意分野：</div>
                    {profileData.fields?.join(', ')}
                </Col>
                <Col className='my-2' md={24}>
                    <div className='label'>得意業種：</div>
                    {profileData.industries?.join(', ')}
                </Col>
                <Col className='my-2' md={24}>
                    <div className='label'>得意事業規模：</div>
                    {profileData.employeeCountRanges?.join(', ')}
                </Col>
                <Col className='my-2' md={12}>
                    <div className='label'>電話番号：</div>
                    {profileData?.phone}
                </Col>
                <Col className='my-2' md={12}>
                    <div className='label'>Eメール：</div>
                    {profileData?.email}
                </Col>
                <Col className='my-2' md={12}>
                    <div className='label'>Twitter URL：</div>
                    {profileData?.twitterUrl && (
                        <a href={profileData.twitterUrl} target='_blank' rel='noreferrer'>リンク</a>
                    )}
                </Col>
                <Col className='my-2' md={12}>
                    <div className='label'>Facebook URL：</div>
                    {profileData?.facebookUrl && (
                        <a href={profileData.facebookUrl} target='_blank' rel='noreferrer'>リンク</a>
                    )}
                </Col>
                <Col className='my-2' md={24}>
                    <div className='label'>サイト URL：</div>
                    {profileData?.homepageUrl && (
                        <a href={profileData.homepageUrl} target='_blank' rel='noreferrer'>リンク</a>
                    )}
                </Col>
                <Col className='my-2' md={12}>
                    <div className='label'>事業所案内：</div>
                    {profileData?.businessManualUrl && (
                        <a href={profileData.businessManualUrl} target='_blank' rel='noreferrer'>リンク</a>
                    )}
                </Col>
                <Col className='my-2' md={12}>
                    <div className='label'>認証・認定マーク：</div>
                    <br />
                    {profileData.specialistCertificationImages?.map(url =>
                        <Image
                            width={80}
                            src={url}
                        />,
                    )}
                </Col>
            </Row>
        </Modal>
    );

};

export default ProfilePreviewModal;
