import React from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { CancelConsultationRequest, cancelPolicyApplication } from 'app/slice/policyApplicationDetailsSlice';

type Props = {
    uuid: string,
    isVisible: boolean,
    onClose: () => void,
}

const CancelMeetingModal = (props: Props) => {
    const { uuid, isVisible, onClose } = props;
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();

    const onSubmit = (values: { reason: string }) => {
        dispatch(cancelPolicyApplication({
            uuid: uuid,
            reason: values.reason,
        } as CancelConsultationRequest));
    };

    return (
        <Modal className='kisoku-meetings-modal' visible={isVisible} footer={null} onCancel={onClose}>
            <div className='text-center title'>面談をキャンセル</div>
            <Form
                form={form}
                onFinish={onSubmit}
                layout='vertical'
            >
                <Form.Item
                    label='キャンセル理由（必須）'
                    name='reason'
                    rules={[{ required: true, message: 'キャンセル理由をご入力ください。' }]}
                >
                    <Input.TextArea rows={2} />
                </Form.Item>
                <div className='form-buttons w-75 mx-auto'>
                    <Button className='my-1' type='primary' block danger htmlType='submit'>
                        確定
                    </Button>
                    <Button className='my-1' block onClick={onClose}>
                        面談詳細に戻る
                    </Button>
                </div>
            </Form>
        </Modal>
    );

};

export default CancelMeetingModal;
