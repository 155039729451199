import React from 'react';

const PrivacyPolicy = () => {
    return (
        <>
            <strong>個人情報取扱規約</strong>
            <p className='mt-2 mb-4'>
                個人情報取扱規約（以下「本規約」という）は、大同生命保険株式会社（以下「当社」という）および中小企業福祉事業団（以下「当事業団」という）の運営する「公的支援オンラインサービス」および「就業規則診断サービス」（以下「本サービス」という）の利用規約に付随する規約です。<br />
                本サービスの利用に際しては、利用規約と本規約に同意していただく必要があります。なお、本規約に特段の定めが無い限り、本規約中の用語は本サービスの利用規約の定義によります。
            </p>

            <strong>第１条 個人情報の定義 </strong>
            <p className='mt-2 mb-4'>
                本規約における個人情報とは、本サービスの利用者に関する情報のことであり、当該情報に含まれる氏名、生年月日、Eメールアドレス、その他の情報によって当該個人を識別できるもの、また、当該情報のみでは識別できないものの、他の情報と容易に照合することにより、当該個人を識別できるものをいいます。
            </p>

            <strong>第２条 個人情報の提供 </strong>
            <p className='mt-2 mb-4'>
                当社および当事業団は本サービスの利用者から、利用者の委託により、当社から当事業団に、もしくは当事業団から当社に、以下の個人情報を含む情報（以下、単に「個人情報」という）を提供いただきます。なお、当社および当事業団への個人情報の提供は利用者の任意によるものですが、本規約第４条の目的のために必要な個人情報が提供いただけない場合は、本サービスの一部または全部のサービスを利用いただけない場合があります。 <br />
                <div className='mt-3 privacy-policy-table'>
                    <table>
                        <tr>
                            <th>提供時期</th>
                            <th>当社および当事業団に提供いただく個人情報</th>
                        </tr>
                        <tr>
                            <td>利用申込時</td>
                            <td>
                                ・企業名（法人名または個人事業主名） <br />
                                ・企業担当者の以下の情報<br />
                                <div className='ms-3'>
                                    氏名、メールアドレス
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>初回ログイン時</td>
                            <td>
                                ・利用者の以下の情報<br/>
                                <div className='ms-3'>
                                    企業名、郵便番号、住所、電話番号、担当者名、担当者役職、メールアドレス、
                                    業種、従業員数（パート・アルバイト数含む）、社会保険加入状況、
                                    就労規則の有無、顧問社労士の有無、顧問中小企業診断士の有無
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>サービス利用開始後</td>
                            <td>・専門家への相談内容、各種診断状況・ダウンロード状況</td>
                        </tr>
                    </table>
                </div>
            </p>

            <strong>第３条 個人情報の管理</strong>
            <p className='mt-2 mb-4'>
                提供いただいた個人情報は、当社および当事業団のプライバシーポリシーに準拠し、個人情報への不正アクセス、個人情報の紛失、改ざんおよび漏洩等がないように適切に管理します。
            </p>

            <strong>第４条 個人情報の利用目的</strong>
            <p className='mt-2 mb-4'>
                提供いただいた個人情報は、以下の目的で利用します。
                <div className='ms-3'>
                    1)利用者の登録・変更・削除に関する業務<br />
                    2)Webサイトにおける本サービスの提供業務<br />
                    3)本サービス利用状況の集計業務<br />
                    4)本サービスに関するお知らせ<br />
                    5)本サービスの品質向上等に向けた各種アンケート依頼<br />
                    6)当社または当事業団に対するご質問・ご相談・お問い合わせ対応などのカスタマーサービス業務<br />
                    7)緊急時や災害発生時において必要と判断した際の連絡<br />
                    8)利用者から委託された業務<br />
                    9)本規約第５条に定める第三者の利用<br />
                    10)その他利用者に対する本サービス等の適切かつ円滑な遂行
                </div>
            </p>

            <strong>第５条 個人情報の第三者提供 </strong>
            <ol>
                <li>
                    当事業団は、提供いただいた個人情報は、以下のとおり、当社または当事業団以外の第三者に提供します（なお、第三者に提供した内容自体は個人情報に該当しない場合でも、提供先において個人情報データベース等を構成する個人情報となる場合を含みます）。<br />
                    1) 当事業団会員の専門家への提供
                </li>
                <li>
                    当社および当事業団は、前項に規定された場合を除き、利用者の同意なく、提供いただいた個人情報を第三者へ提供することはありません。ただし、以下のいずれかに該当する場合は、この限りではありません。<br />
                    1)法令により開示が要求される場合および法令に基づき提供する場合<br />
                    2)あらかじめご本人に必要事項を明示または通知し、同意を得ている場合<br />
                    3)本サービスを提供するために必要な業務の全部または一部を委託する場合 <br />
                    4)人（法人を含む）の生命、身体、財産等の利益を保護するために必要であって、ご本人の同意を得ることが困難な場合<br />
                    5)合併その他の法律上の事由による承継に伴って個人情報を提供する場合であって、承認前の利用目的の範囲内で、当該個人情報を取り扱う場合<br />
                    6)公衆衛生の向上または児童の健全な育成の推進のために特に必要である場合で、ご本人の同意を得ることが困難な場合<br />
                    7)国もしくは地方公共団体の各機関、またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合<br />
                    8)個人情報漏えいやウェブ上のハッキングその他の当社または当事業団が損害を被る可能性がある事故（不正アクセス禁止法が禁止する一切の行為を含む）を未然に防止し、もしくは発生した事故に関して、迅速な救済を受けるために、当該不正行為をしている可能性が高いと判断した方の個人情報を調査機関に提供する場合<br />
                    9)当社または当事業団が損害賠償義務を負うべき事故が発生した場合であって、当社または当事業団が加入している保険の適用を受けるために、当該保険会社に個人情報を提供する場合
                </li>
            </ol>

            <strong>第６条 法令厳守 </strong>
            <p className='mt-2 mb-4'>
                当社および当事業団は、個人情報に関連する法令・規範を遵守するとともに、環境の変化に合わせ、個人情報の取り扱いの継続的な改善、向上に努めます。
            </p>

            <strong>第７条 個人情報の削除・破棄・処分 </strong>
            <p className='mt-2 mb-4'>
                利用者の退会に伴い、当社および当事業団に提供いただいた個人情報は、当社および当事業団の責任において削除・破棄・処分します。
            </p>

            <strong>第８条 個人情報のお問い合わせ窓口 </strong>
            <p className='mt-2 mb-4'>
                当社および当事業団に提供いただいた個人情報の開示、訂正、削除、利用目的、苦情、相談等のお問い合わせにつきましては、以下の窓口でお受けしております。
                <div className='mt-3 privacy-policy-table'>
                    <table>
                        <tr>
                            <td><strong>お問い合わせ窓口</strong></td>
                            <td>大同生命保険株式会社 コールセンター</td>
                        </tr>
                        <tr>
                            <td><strong>連絡先</strong></td>
                            <td>
                                TEL：0120―789―501（通話料無料）<br/>
                                （受付時間：9時～18時[土・日・祝日・年末年始を除く]）
                            </td>
                        </tr>
                    </table>
                </div>

                <div className='mt-3 privacy-policy-table'>
                    <table>
                        <tr>
                            <td><strong>お問い合わせ窓口</strong></td>
                            <td>中小企業福祉事業団「個人情報ご相談窓口」</td>
                        </tr>
                        <tr>
                            <td><strong>連絡先</strong></td>
                            <td>privacy@chukidan-jp.com</td>
                        </tr>
                    </table>
                </div>
            </p>

            <strong>第９条 管轄裁判所</strong>
            <p className='mt-2 mb-4'>
                本規約に関する紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </p>
        </>
    );
}

export default PrivacyPolicy;
