import React, { useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, Form, Input, Radio, Row, Select } from 'antd';
import { scrollToTop, useQuery, validatePasswordConfirmation } from 'lib/utils';
import classNames from 'classnames';
import {
    getUserRegistrationData,
    HanshaTermsFieldData,
    registration,
    RegistrationCredentials,
    RegistrationFieldData,
    selectRegistration,
} from 'app/slice/registrationSlice';
import { useAppDispatch } from 'app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    getEmployeeCountRanges,
    getIndustries,
    getInsurancePlans,
    getPrefectures,
    selectResources,
} from 'app/slice/resourcesSlice';
import { setSuccessState, SuccessModalState } from 'app/slice/successSlice';
import { Rules } from 'types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { flatten, values } from 'lodash';
import { RuleObject } from 'antd/lib/form';
import ValidationErrorModal from 'components/common/ValidationErrorModal';

const rules: Rules = {
    companyName: [
        { required: true, message: '企業名をご入力ください。' },
        { max: 100, message: '100文字以内ご入力ください。' },
    ],
    companyNameFurigana: [
        { required: true, message: '企業名フリガナをご入力ください。' },
        { pattern: new RegExp(/^([\sァ-ヶー]+)$/), message: '企業名を全角フリガナでご入力ください。' },
        { max: 200, message: '200文字以内ご入力ください。' },
    ],
    lastName: [
        { required: true, message: '担当者さま姓をご入力ください。' },
        { max: 50, message: '50文字以内ご入力ください。' },
    ],
    firstName: [
        { required: true, message: '担当者さま名をご入力ください。' },
        { max: 50, message: '50文字以内ご入力ください。' },
    ],
    lastNameFurigana: [
        { required: true, message: '担当者さまフリガナ（セイ）をご入力ください。' },
        { pattern: new RegExp(/^([\sァ-ヶー]+)$/), message: '担当者さま（セイ）を全角フリガナでご入力ください。' },
        { max: 100, message: '100文字以内ご入力ください。' },
    ],
    firstNameFurigana: [
        { required: true, message: '担当者さまフリガナ（メイ）をご入力ください。' },
        { pattern: new RegExp(/^([\sァ-ヶー]+)$/), message: '担当者さま（メイ）を全角フリガナでご入力ください。' },
        { max: 100, message: '100文字以内ご入力ください。' },
    ],
    tel: [
        { required: true, message: 'TELをご入力ください。' },
        { pattern: new RegExp(/^[0-9]{1,3}[-\s/0-9]*$/g), message: '形式が間違っています。' },
    ],
    email: [
        { required: true, message: 'メールアドレスをご入力ください。' },
        { type: 'email' },
    ],
    postalCode: [
        { required: true, message: '郵便番号をご入力ください。' },
        { pattern: new RegExp(/^[0-9]{3}[-\s]?[0-9]{4}/g), message: '形式が間違っています。' },
    ],
    prefectureCode: [{ required: true, message: '都道府県をご入力ください。' }],
    companyAddress: [
        { required: true, message: '住所をご入力ください。' },
        { max: 200, message: '200文字以内ご入力ください。' },
    ],
    employeeCountRangeCode: [{ required: true, message: '従業員数をご入力ください。' }],
    parttimeEmployeeCount: [
        { required: true, message: 'パート/アルバイト数をご入力ください。' },
        { pattern: new RegExp(/^[0-9]+$/), message: '数字を入力してください。' },
    ],
    laborRegulationsCompliant: [{ required: true, message: '就業規則の有無をご入力ください。' }],
    allowSharoushi: [{ required: true, message: '顧問社労士の有無をご入力ください。' }],
    industryCode: [{ required: true, message: '業種をご入力ください。' }],
    allowShindanshi: [{ required: true, message: '顧問診断士の有無をご入力ください。' }],
    password: [
        { required: true, message: 'パスワードをご入力ください。' },
        { pattern: new RegExp(/^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d).*$/), message: '8桁の英数混合のパスワードをご入力ください。' },
    ],
    passwordConfirmation: [
        { required: true, message: 'パスワードをご入力ください。' },
        validatePasswordConfirmation('user[password]', 'パスワードは一致していません。'),
    ],
    passwordConfirmationInput: [
        { required: true, message: 'パスワードをご入力ください。' },
    ],
};

const Registration = () => {
    const [form] = Form.useForm();
    const [editMode, setEditMode] = useState(true);
    const {
        companyName,
        profileFirstname,
        profileLastname,
        profileFirstnameFurigana,
        profileLastnameFurigana,
        daidoEmployeeName,
        daidoDepartmentName,
        daidoBranchName,
        userEmail,
        nextUrl,
        success,
    } = useSelector(selectRegistration);
    const {
        prefectures,
        industries,
        insurancePlans,
        employeeCountRanges,
    } = useSelector(selectResources);
    const dispatch = useAppDispatch();
    const params = useParams();
    const query = useQuery();
    const navigate = useNavigate();
    const [fields, setFields] = useState<RegistrationFieldData>();
    const [hanshaTermsChecked, setHanshaTermsChecked] = useState<HanshaTermsFieldData>({ hanshaTerm1: false, hanshaTerm2: false });
    const [isValidationErrorModalVisible, setIsValidationErrorModalVisible] = useState(false);
    const [hasRequiredError, setHasRequiredError] = useState(false);
    const [hasPatternError, setHasPatternError] = useState(false);

    const onOpenValidationErrorModal = () => setIsValidationErrorModalVisible(true);
    const onCloseValidationErrorModal = () => setIsValidationErrorModalVisible(false);

    const onCancel = () => {
        setEditMode(true);
        scrollToTop('smooth');
    };

    const onFinish = (formData: RegistrationFieldData) => {
        if (editMode) {
            setEditMode(false);
            scrollToTop('smooth');
        } else {
            dispatch(registration({ nextUrl, formData }));
        }
    };

    const onFinishFailed = () => {
        let requiredError = false;
        let patternError = false;

        const errorFields = form.getFieldsError();
        const ruleValues = flatten(values(rules)) as RuleObject[];
        const requiredMessages = ruleValues.filter(rule => rule.required).map(rule => rule.message);
        const patternMessages = ruleValues.filter(rule => rule.pattern).map(rule => rule.message);

        for (let errorField of errorFields) {
            for (let error of errorField.errors) {
                if (requiredMessages.includes(error)) {
                    requiredError = true;
                } else if (patternMessages.includes(error)) {
                    patternError = true;
                }
            }
        }

        scrollToTop();
        setHasRequiredError(requiredError);
        setHasPatternError(patternError);
        onOpenValidationErrorModal();
    };

    const onChange = (fields: RegistrationFieldData) => {
        setFields(fields);
    };

    const onHanshaTermsChange = (event: CheckboxChangeEvent) => {
        if (event.target.name) {
            setHanshaTermsChecked({ ...hanshaTermsChecked, [event.target.name]: event.target.checked });
        }
    };

    useEffect(() => {
        const registrationCredentials: RegistrationCredentials = {
            uuid: params.uuid ?? '',
            expires: query.get('expires') ?? '',
            signature: query.get('signature') ?? '',
        };

        dispatch(getUserRegistrationData(registrationCredentials));
        dispatch(getPrefectures());
        dispatch(getIndustries());
        dispatch(getInsurancePlans());
        dispatch(getEmployeeCountRanges());
    }, [dispatch, params.uuid, query]);

    useEffect(() => {
        setFields({
            'company[name]': companyName,
            'company[name_furigana]': '',
            'company[representative_position]': '',
            'profile[lastname]': profileLastname,
            'profile[firstname]': profileFirstname,
            'profile[lastname_furigana]': profileLastnameFurigana,
            'profile[firstname_furigana]': profileFirstnameFurigana,
            'profile[phone]': '',
            'user[email]': userEmail,
            'company[postal_code]': '',
            'company[prefecture_code]': '',
            'company[address]': '',
            'company[building_name]': '',
            'company[industry_code]': '',
            'company[parttime_employee_count]': '',
            'company[employee_count_range_code]': '',
            insurance_plans: [],
            'company[labor_regulations_compliant]': 0,
            'company[allow_sharoushi]': 0,
            'company[allow_shindanshi]': 0,
            'user[password]': '',
            'user[password_confirmation]': '',
        });
    }, [
        companyName,
        profileLastname,
        profileFirstname,
        profileLastnameFurigana,
        profileFirstnameFurigana,
        userEmail,
    ]);

    useEffect(() => {
        if (success) {
            const successModalPayload: SuccessModalState = {
                isShow: success,
                title: 'ご登録ありがとうございます。',
                message: 'ご登録が完了しました。この画面を閉じて右上の\n' +
                    'ログインボタンよりログインしてください。',
                withButton: true,
                buttonLink: '/',
                buttonTitle: 'OK',
                image: '',
            };
            dispatch(setSuccessState(successModalPayload));
        }
    }, [dispatch, navigate, success]);

    return (
        <Card className={classNames('registration-card', { preview: !editMode })}>
            <div className='title'>新規登録</div>
            {fields?.['user[email]'] && <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={fields}
                onValuesChange={(_, allFields) => onChange(allFields)}
                autoComplete='off'
                className='m-0 w-auto'
            >
                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item
                            name='company[name]'
                            label='企業名'
                            rules={rules.companyName}
                            extra='※100文字以内'
                        >
                            <Input readOnly={!editMode} placeholder='かんぱに株式会社' />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            name='company[name_furigana]'
                            label='企業名フリガナ（全角）'
                            rules={rules.companyNameFurigana}
                            extra='※200文字以内'
                        >
                            <Input readOnly={!editMode} placeholder='カンパニカブシキガイシャ' />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            name='company[representative_position]'
                            label='担当者さま役職'
                            extra='※50文字以内'
                        >
                            <Input readOnly={!editMode} placeholder='総務課長' />
                        </Form.Item>
                    </Col>
                    <Col md={12} />
                    <Col md={12}>
                        <Row gutter={[16, { xs: 16, md: 0 }]}>
                            <Col md={12}>
                                <Form.Item
                                    name='profile[lastname]'
                                    label='担当者さま'
                                    rules={rules.lastName}
                                    extra='※50文字以内'
                                >
                                    <Input readOnly={!editMode} placeholder='姓' />
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item
                                    name='profile[firstname]'
                                    label=' '
                                    required={false}
                                    rules={rules.firstName}
                                >
                                    <Input readOnly={!editMode} placeholder='名' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12}>
                        <Row gutter={[16, { xs: 16, md: 0 }]}>
                            <Col md={12}>
                                <Form.Item
                                    name='profile[lastname_furigana]'
                                    label='担当者さまフリガナ（全角）'
                                    rules={rules.lastNameFurigana}
                                    extra='※100文字以内'
                                >
                                    <Input readOnly={!editMode} placeholder='セイ' />
                                </Form.Item>
                            </Col>
                            <Col md={12}>
                                <Form.Item
                                    name='profile[firstname_furigana]'
                                    label=' '
                                    required={false}
                                    rules={rules.firstNameFurigana}
                                >
                                    <Input readOnly={!editMode} placeholder='メイ' />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            name='profile[phone]'
                            label='TEL'
                            rules={rules.tel}
                            extra='※「000-000-0000」の形式（半角英数字、半角ハイフン）'
                        >
                            <Input readOnly={!editMode} placeholder='038-567-2345' />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            name='user[email]'
                            label='メールアドレス'
                            rules={rules.email}
                            extra='※半角英数字'
                        >
                            <Input readOnly={true} placeholder='tanakatarou@gmail.com' />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            name='company[postal_code]'
                            label='〒'
                            rules={rules.postalCode}
                            extra='※「000-0000」の形式（半角英数字、半角ハイフン）'
                        >
                            <Input readOnly={!editMode} placeholder='900-0002' />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            name='company[prefecture_code]'
                            label='都道府県'
                            rules={rules.prefectureCode}
                        >
                            <Select disabled={!editMode} placeholder='都道府県'>
                                {prefectures.map((row) => (
                                    <Select.Option key={row.code}
                                                   value={row.code}
                                    >{row.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            name='company[address]'
                            label='住所'
                            rules={rules.companyAddress}
                            extra='※200文字以内'
                        >
                            <Input readOnly={!editMode} placeholder='住所' />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            name='company[building_name]'
                            label='ビル名'
                            extra='※200文字以内'
                        >
                            <Input readOnly={!editMode} placeholder='かんぱにビル' />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            name='company[employee_count_range_code]'
                            label='従業員数'
                            rules={rules.employeeCountRangeCode}
                        >
                            <Select disabled={!editMode} placeholder='1人-100人'>
                                {employeeCountRanges.map((row) => (
                                    <Select.Option key={row.code} value={row.code}>{row.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            name='company[parttime_employee_count]'
                            label='うち、パート/アルバイト数'
                            extra='０人の場合、０を入力してください。'
                            rules={rules.parttimeEmployeeCount}
                        >
                            <Input readOnly={!editMode} placeholder='15' />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            name='insurance_plans[]'
                            label='社会保険加入状況'
                            required
                        >
                            <Checkbox.Group disabled={!editMode}>
                                <Row gutter={[10, 10]}>
                                    {insurancePlans.map((row) => (
                                        <Col span={24} key={row.code}>
                                            <Checkbox value={row.code}>{row.name}</Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            name='company[labor_regulations_compliant]'
                            label='就業規則の有無'
                            rules={rules.laborRegulationsCompliant}
                        >
                            <Radio.Group disabled={!editMode}>
                                <Radio value={1}>有</Radio>
                                <Radio value={0}>無</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name='company[allow_sharoushi]'
                            label='顧問社労士の有無'
                            rules={rules.allowSharoushi}
                        >
                            <Radio.Group disabled={!editMode}>
                                <Radio value={1}>有</Radio>
                                <Radio value={0}>無</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            name='company[industry_code]'
                            label='業種'
                            rules={rules.industryCode}
                        >
                            <Select disabled={!editMode} placeholder='農林漁業'>
                                {industries.map((row) => (
                                    <Select.Option key={row.code} value={row.code}>{row.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            name='company[allow_shindanshi]'
                            label='顧問診断士の有無'
                            rules={rules.allowShindanshi}
                        >
                            <Radio.Group disabled={!editMode}>
                                <Radio value={1}>有</Radio>
                                <Radio value={0}>無</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            name='user[password]'
                            label='パスワード'
                            rules={rules.password}
                        >
                            <Input.Password readOnly={!editMode} />
                        </Form.Item>
                    </Col>
                    {editMode && (
                        <Col md={12}>
                            <Form.Item
                                name='user[password_confirmation]'
                                label='パスワード（確認用）'
                                rules={rules.passwordConfirmation}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                    )}
                    <Form.Item
                        name='user[password_confirmation]'
                        label='パスワード（確認用）'
                        hidden={true}
                        rules={rules.passwordConfirmationInput}
                    >
                        <Input.Password />
                    </Form.Item>
                </Row>

                <hr className='registration-separator' />
                <div className='my-30px mx-0 registration-terms-content'>
                    <div className='daido-info'>
                        <div className='title text-start'>大同生命営業担当者情報</div>
                        <div className='subtitle text-start'>
                            <span className='me-4'>{daidoBranchName}</span>
                            <span className='me-4'>{daidoDepartmentName}</span><br/>
                            <span>{daidoEmployeeName}</span>
                        </div>
                    </div>
                    
                    <div className='registration-terms-checkbox'>
                        <div>
                            <Checkbox onChange={onHanshaTermsChange} name='hanshaTerm1' disabled={!editMode}>
                                <div>
                                    <a href='https://www.daido-chukidan.com/kouteki/terms-of-service' rel='noreferrer' target='_blank' className='text-decoration-underline'>利用規約</a>および 
                                    <a href='https://www.daido-chukidan.com/kouteki/privacy-policy' rel='noreferrer' target='_blank' className='text-decoration-underline'>個人情報取扱規約</a>に同意のうえ申し込みます
                                </div>
                            </Checkbox>
                        </div>
                        <div>
                            <Checkbox onChange={onHanshaTermsChange} name='hanshaTerm2' disabled={!editMode}>
                                <div>私（当社）は反社会的勢力（※）に該当しないこと、反社会的勢力と社会的に</div>
                                <div>非難されるべき関係を有していないことを表明のうえ申し込みます</div>
                                <p className='terms-subtext'>※暴力団、暴力団員（暴力団員でなくなった日から5年を経過しない者を含む）、<br/>
                                    <span className='pl-14px'>暴力団準構成員、暴力団関係企業、総会屋等、社会運動標榜ゴロ、その他の反社会的勢力</span>
                                </p>
                            </Checkbox>
                        </div>
                    </div>
                </div>

                <Form.Item className='text-center'>
                    <div className='mt-4'>
                        {!editMode && (
                            <Button className='me-3 w-200px' onClick={onCancel} htmlType='button'>
                                キャンセル
                            </Button>
                        )}
                        {editMode ? (
                            <Button type='primary' htmlType='submit' className='w-200px' disabled={!(hanshaTermsChecked.hanshaTerm1 && hanshaTermsChecked.hanshaTerm2)}>
                                確認
                            </Button>
                        ) : (
                            <Button type='primary' htmlType='submit' className='w-200px'>
                                登録
                            </Button>
                        )}
                    </div>
                </Form.Item>

                <div className='helper-text text-center'>
                    ※確認ボタンを押さずにブラウザを閉じた場合、途中まで入力した情報は保存されませんので、ご注意ください。<br />
                    ※ご登録のメールアドレスをお忘れの場合は、大同生命の営業担当者にご確認ください。
                </div>
            </Form>}
            <ValidationErrorModal isVisible={isValidationErrorModalVisible}
                                  onClose={onCloseValidationErrorModal}
                                  hasRequiredError={hasRequiredError}
                                  hasPatternError={hasPatternError}
            />
        </Card>
    );
};

export default Registration;
