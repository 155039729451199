import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Modal, Image } from 'antd';
import instructionImage from 'assets/images/instruction_expert_matching.png';
import { selectFundApplication } from 'app/slice/fundApplicationSlice';
import { useAppSelector } from 'app/hooks';
import { EyeOutlined } from '@ant-design/icons';

const previewOption = { mask: <div className='ant-image-mask-info'><EyeOutlined /> 拡大する</div> };

type Props = {
    isVisible: boolean,
    onClose: () => void,
    onSubmit: () => void
}

const InstructionModal = (props: Props) => {
    const { isVisible, onClose } = props;
    const [isChecked, setChecked] = useState(false);
    const { loading } = useAppSelector(selectFundApplication);

    const onChangeCheck = () => {
        setChecked(!isChecked);
    };

    const onSubmit = () => {
        if (isChecked) {
            props.onSubmit();
        }
    };

    useEffect(() => {
        if (!isVisible) {
            setChecked(false);
        }
    }, [isVisible]);

    return (
        <Modal className='instruction-modal' visible={isVisible} onCancel={onClose} footer={null}>
            <div className='title'>専門家相談のご案内</div>
            <p>
                ご相談の要件に対応可能な専門家を、システム上で自動選定します。※選定期間は申込日を含む3営業日。<br/>
                対応可能な専門家が不在の場合、『中企団オンライン相談窓口』をサイト上でご案内いたします。<br/>
                専門家の選定状況は、お客さまの「管理画面（マイページ）」からご確認いただけます。<br/>
                <div className='note'>
                    <span className='light-blue-5'>『中企団オンライン相談窓口』とは</span><br />
                    <div className='ms-3'>
                        専門家とのマッチングがうまくいかなかった場合にご利用いただける相談窓口です。<br/>
                        本サービスの提供元である中小企業福祉事業団の会員社会保険労務士、または会員中小企業診断士が、<br/>
                        お客さまのご相談にご対応いたします。<br/>
                        ※東京在籍の専門家がご対応します。※オンライン相談のみのご対応となり、申請手続支援はできかねます。
                    </div>
                </div>
            </p>
            <Image src={instructionImage} alt='instruction' className='w-100' preview={previewOption} />
            <p className='mt-4'>
                ※ 専門家または相談窓口よりご提示した<strong>面談候補日への回答受付期間は、ご案内から 3 営業日迄</strong>となります。<br />
                <span className='text-indent'
                ><u>回答受付期間を過ぎた場合、自動的に受付終了（キャンセル）となりますのでご注意ください。</u></span><br />
                <span className='text-indent'>メール通知もお送りしておりますが、適宜、マイページにて状況のご確認をお勧めいたします。</span><br />
                ※ 営業日は土日祝日を除きます。
            </p>
            <p className='mt-4'>
                ＜ご確認ください＞<br />
                <span className='text-indent red-3'>本サービスのオンライン面談には、「 Zoom （ズーム）」を使用します。</span><br />
                <span className='text-indent'>Zoom
                    の使用に際し、ご不明点等ございましたら、大同生命営業担当者までお気軽にお問い合わせください。</span>
            </p>
            <div className='text-center mb-3 fw-bold'>
                <Checkbox checked={isChecked} onChange={onChangeCheck}>
                    上記内容を確認の上、専門家相談を申し込む
                </Checkbox>
            </div>
            <div className='btn-group'>
                <Button type='primary' onClick={onSubmit} disabled={!isChecked || loading} loading={loading}>
                    専門家相談を申し込む
                </Button>
                <Button type='default' onClick={onClose}>
                    キャンセル
                </Button>
            </div>
        </Modal>
    );
};

export default InstructionModal;
