import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { getKisokuRegulation } from 'api/kisokuRegulation';
import { getErrorMessage } from 'api';

export type KisokuRegulation = {
    title: string,
    files: {
        name: string,
        url: string,
        diagnosis: boolean
    }[]
}

export type KisokuRegulationState = {
    loading: boolean,
    success: boolean,
    data: KisokuRegulation[]
}

export const fetchKisokuRegulation = createAsyncThunk(
    'kisokuRegulation',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            let response = await getKisokuRegulation();
            dispatch(setKisokuRegulation(response.data.data));
            return response.data.data;
        } catch (error: any) {
            return rejectWithValue(getErrorMessage(error));
        }
    },
);

export const kisokuRegulationSlice = createSlice({
    name: 'kisokuRegulation',
    initialState: {
        loading: false,
        success: false,
        data: [],
    } as KisokuRegulationState,
    reducers: {
        setKisokuRegulation: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchKisokuRegulation.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.data = [];
        });
        builder.addCase(fetchKisokuRegulation.fulfilled, (state) => {
            state.loading = false;
            state.success = true;
        });
        builder.addCase(fetchKisokuRegulation.rejected, (state) => {
            state.loading = false;
            state.success = false;
        });
    },
});

export const selectKisokuRegulation = (state: RootState) => state.kisokuRegulation;
export const { setKisokuRegulation } = kisokuRegulationSlice.actions;
