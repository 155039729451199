import React from 'react';
import { Col, Divider, Form, Row } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { PolicyApplicationDetails } from 'app/slice/policyApplicationDetailsSlice';
import Remarks from 'components/dashboard/working_regulation/common/Remarks';
import Topics from 'components/dashboard/working_regulation/common/Topics';

type Props = {
    application: PolicyApplicationDetails
}

const Scheduled = (props: Props) => {
    const { application } = props;

    return (
        <>
            <div className='card-container gray py-md-4 flex-column'>
                <Row gutter={16}>
                    <Col>
                        <strong>
                            日程: <span className='ms-2 meeting-datetime'>{application.scheduleDatetime}</span>
                        </strong>
                        <div className='helper-text'>※日程変更をご希望の際は、大同生命の営業担当者にご連絡をお願いいたします。</div>
                    </Col>
                    {application.daidoJoins ? (
                        <Col>
                            <div>
                                <CheckOutlined className='light-blue-4' />
                                <span className='ms-2'>大同生命営業担当者の同席を希望します</span>
                            </div>
                        </Col>
                    ) : (
                        <Col>
                            <div>
                                <CloseOutlined className='light-blue-4' />
                                <span className='ms-2'>大同生命営業担当者の同席は希望しません</span>
                            </div>
                        </Col>
                    )}

                </Row>
                <Divider className='mt-0' />
                <Form layout='vertical'>
                    <Row gutter={[0, 4]}>
                        <Col md={4} xs={24}>
                            <strong>ミーティングURL:</strong>
                        </Col>
                        <Col md={20} xs={24}>
                            <a className='link' href={application.joinUrl || ''} target='_blank' rel='noreferrer'>
                                {application.joinUrl || ''}
                            </a>
                        </Col>
                        <Col md={4} xs={24}>
                            <strong>パスワード:</strong>
                        </Col>
                        <Col md={20} xs={24}>
                            {application.password || ''}
                        </Col>
                    </Row>
                </Form>
            </div>

            {application.topics && <Topics topics={application.topics} />}
            {application.remarks && <Remarks remarks={application.remarks} />}
        </>
    );
};

export default Scheduled;
