import React, { useEffect, useMemo } from 'react';
import { Skeleton, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { fetchKisokuRegulation, selectKisokuRegulation } from 'app/slice/kisokuRegulationSlice';
import { selectAuth } from 'app/slice/authSlice';
import { addKisokuTemplateLog } from 'app/slice/kisokuTemplateLogSlice';

const { Column } = Table;

const RegulationsTable = () => {
    const { data, loading } = useAppSelector(selectKisokuRegulation);
    const { token } = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();

    const tableData = useMemo(() => {
        let leftTable = [];
        let rightTable = [];
        let oldTitle = '';

        for (let row of data) {
            for (let file of row.files) {
                const value = {
                    title: row.title,
                    filename: file.name,
                    url: file.url,
                    diagnosis: file.diagnosis,
                    rowSpan: row.title === oldTitle ? 0 : row.files.length,
                }

                if (file.name !== '共通') {
                    leftTable.push(value);
                } else {
                    rightTable.push(value);
                }

                oldTitle = row.title;
            }
        }

        return [leftTable, rightTable];
    }, [data]);

    useEffect(() => {
        if (token) {
            dispatch(fetchKisokuRegulation());
        }
    }, [dispatch, token]);

    const renderTitleCell = (value: string, record: typeof tableData[number][0]) => {
        return {
            children: value,
            props: { rowSpan: record.rowSpan },
        };
    };

    const renderUrl = (value: string, record: typeof tableData[number][0]) => {
        const onClick = () => {
            let filename = decodeURI(record.url.split('/').pop()?.split('.docx').shift() as string);
            dispatch(addKisokuTemplateLog({ filename }));
        }

        return (
            <a href={record.url} target='_blank' rel='noreferrer' onClick={onClick}>{value}</a>
        );
    };

    const renderBoolean = (value: boolean) => {
        return value ? '○' : '×';
    };

    if (loading) return <Skeleton active />;

    return (
        <div className='regulations-table'>
            <Table dataSource={tableData[0]} pagination={false}>
                <Column title='規程名' width='35%' dataIndex='title' render={renderTitleCell} />
                <Column title='業種' dataIndex='filename' render={renderUrl} />
                <Column title='本サービスでの診断' width='30%' dataIndex='diagnosis' render={renderBoolean} className='text-center' />
            </Table>
            <Table dataSource={tableData[1]} pagination={false}>
                <Column title='規程名' width='35%' dataIndex='title' render={renderUrl} />
                <Column title='業種' dataIndex='filename' />
                <Column title='本サービスでの診断' width='30%' dataIndex='diagnosis' render={renderBoolean} className='text-center' />
            </Table>
        </div>
    );
};

export default RegulationsTable;
