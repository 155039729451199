import React, { useCallback, useEffect, useState } from 'react';
import { useIsMobile, useStyle } from 'lib/utils';
import { Layout } from 'antd';
import logo from 'assets/images/daidolife_logo_light.png';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { selectAuth } from 'app/slice/authSlice';
import { useAppSelector } from 'app/hooks';
import Sidebar from 'components/common/Sidebar';
import SystemNotification from 'components/common/SystemNotification';

const { Content, Footer, Header } = Layout;

type Props = {
    notificationCount: number
}

const DashboardLayout = (props: Props) => {
    const { notificationCount } = props;
    useStyle('dashboard');

    const isMobile = useIsMobile();
    const location = useLocation();
    const navigate = useNavigate();
    const { token, name } = useAppSelector(selectAuth);

    const [isCollapsed, setIsCollapsed] = useState(true);
    const onCloseCollapsed = useCallback(() => setIsCollapsed(true), []);

    useEffect(() => {
        if (!token) {
            navigate('/');
        }
    }, [token, navigate]);

    useEffect(() => {
        if (location.pathname === '/dashboard') {
            navigate('/dashboard/management', { replace: true });
        }
    }, [location.pathname, navigate]);

    return (
        <Layout className='dashboard-container'>
            <SystemNotification />
            <Header className='dashboard-navbar'>
                <Link to='/'>
                    <div className='logo'>
                        <img src={logo} alt='logo' />
                        <span>公的支援オンラインサービス／就業規則診断オンラインサービス</span>
                    </div>
                </Link>
                <div className='username'>{name}</div>
            </Header>
            <Link to='/' className='top-page-link d-xs-none text-decoration-underline'>
                トップページ
            </Link>

            {!isMobile ? (
                <Layout className='dashboard-content'>
                    <Sidebar key={+isMobile} notificationCount={notificationCount} />
                    <Layout className='py-0 px-25px'>
                        <Content>
                            <Outlet />
                        </Content>
                    </Layout>
                </Layout>
            ) : (
                <Layout>
                    <Sidebar key={+isMobile} breakpoint='lg' collapsedWidth='0' collapsed={isCollapsed}
                             onCollapse={collapsed => setIsCollapsed(collapsed)}
                             onCloseCollapsed={onCloseCollapsed}
                             notificationCount={notificationCount}
                    />
                    <Layout className='dashboard-content' onClick={onCloseCollapsed}>
                        <Content>
                            <Outlet />
                        </Content>
                    </Layout>
                </Layout>
            )}
            <Footer className='dashboard-footer'>Copyright © 中小企業福祉事業団</Footer>
        </Layout>
    );
};

export default DashboardLayout;
