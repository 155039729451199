import { getAxiosClientWithToken } from './index';

export type CreateCompanyPolicyApplicationRequest = {
    daido_joins: boolean,
    type: number,
    topics: string[],
    remarks: string,
}

/**
 * Function to call API and get a list of Company Rules
 **/
export const getCompanyPoliciesAndVerificationsApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/company/policies`);
};

/**
 * Function to call API and create a new Company Rule
 */
export const uploadCompanyPolicyApi = (data: FormData) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/company/policies`, data, {
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'multipart/form-data',
        }
    } );
};

/**
 * Function to call API and set the Consultation Schedule for a given Application
 */
export const shareCompanyPolicyApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.patch(`/company/policies/${uuid}/share`);
};

/**
 * Function to call API and request Chukidan Consultation for the specified Application
 **/
export const deleteCompanyPolicyApi = (uuid: string) => {
    const restClient = getAxiosClientWithToken();
    return restClient.delete(`/company/policies/${uuid}`);
};

/**
 * Function to call API and get a list of Company Rules
 **/
 export const getCompanyPolicyTypesApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/company/policies/types`);
};

/**
 * Function to call API and get a list of Company Rules
 **/
 export const getCompanyPolicyTopicsApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/company/policies/topics`);
};

/**
 * Function to call API and set the Consultation Schedule for a given Application
 */
 export const createCompanyPolicyApplicationApi = (data: CreateCompanyPolicyApplicationRequest) => {
    const restClient = getAxiosClientWithToken();
    return restClient.post(`/company/policies/applications`, data);
};

/**
 * Function to call API and set the Consultation Schedule for a given Application
 */
export const checkCanCreateCompanyPolicyApplicationApi = () => {
    const restClient = getAxiosClientWithToken();
    return restClient.get(`/company/policies/applications/allowed`);
};