import React, { useEffect } from 'react';
import { Button, Modal } from 'antd';
import notFoundImage from 'assets/images/operator.png';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    NO_CHUKIDAN_CONSULTATION_REQUEST,
    noChukidanConsultationRequest,
    REQUEST_CHUKIDAN_CONSULTATION,
    requestChukidanConsultation,
    selectApplicationDetails,
} from 'app/slice/applicationDetailsSlice';
import { selectFundApplication } from 'app/slice/fundApplicationSlice';
import checkIcon from 'assets/images/success_check.png';
import { setSuccessState, SuccessModalState } from 'app/slice/successSlice';

type Props = {
    visible: boolean,
    onClose: () => void,
}

const MatchFailed = (props: Props) => {
    const { visible, onClose } = props;
    const dispatch = useAppDispatch();
    const { applicationUuid } = useAppSelector(selectFundApplication);
    const { loading, success, type } = useAppSelector(selectApplicationDetails);

    const onRequestConsultation = () => {
        dispatch(requestChukidanConsultation(applicationUuid));
    };

    const onCloseWithoutRequest = () => {
        dispatch(noChukidanConsultationRequest(applicationUuid));
    };

    useEffect(() => {
        if (type === REQUEST_CHUKIDAN_CONSULTATION && success) {
            onClose();
            const successModalPayload: SuccessModalState = {
                isShow: true,
                title: '依頼が完了しました.',
                message: '中企団オンライン相談窓口より、本日を含め、土日・祝日を除く３日以内に候補日時をご連絡いたしますので、しばらくお待ちください。',
                withButton: true,
                image: checkIcon,
                buttonTitle: '閉じる',
            };
            dispatch(setSuccessState(successModalPayload));
        } else if (type === NO_CHUKIDAN_CONSULTATION_REQUEST && success) {
            onClose();
        }
    }, [dispatch, onClose, success, type]);

    return (
        <Modal className='match-failed-modal'
               visible={visible}
               footer={null}
               closable={false}
               maskClosable={false}
               keyboard={false}
               centered={true}
        >
            <div className='mb-3 title' style={{'textAlign': 'left'}}>対応可能な専門家とマッチングしませんでしが、<br/>「中企団オンライン相談窓口」とのご相談が可能です。</div>
            <img style={{'width': 128}} src={notFoundImage} alt='not-found' />
            <Button onClick={onCloseWithoutRequest} type='primary' className='mt-4 w-100' loading={loading}>
                戻る
            </Button>
            <Button onClick={onRequestConsultation} type='primary' className='mt-2 w-100' loading={loading}>
                中企団オンライン相談窓口に依頼する
            </Button>

            <div className='mt-4 text-start helper-text'>
                <strong>～中企団オンライン相談窓口とは？～</strong>
                <br />
                専門家とのマッチングがうまくいかなかった場合にご利用いただける相談窓口です。
                <br />
                本サービスの提供元である中小企業福祉事業団の会員社会保険労務士、または会員中小企業診断士が、お客さまのご相談にご対応いたします。
                <br /><br />
                ※東京在籍の専門家がご対応します。
                <br />
                ※ご対応は、初回のオンライン相談のみとなります。
                <br /><br />
                ＜今後の流れ＞
                <br />
                <table>
                    <tr>
                        <td className='pop-up-table-list'>①</td>
                        <td>「中企団オンライン相談窓口に依頼する」をクリック後、３営業日以内にメールにて候補日時をご連絡します。</td>
                    </tr>
                    <tr>
                        <td className='pop-up-table-list'>②</td>
                        <td>メールが届きましたら、マイページより、提示された候補日時の中から希望日時を選択ください。</td>
                    </tr>
                    <tr>
                        <td className='pop-up-table-list'></td>
                        <td>※希望日時の選択をされない場合は、自動キャンセルとなります。</td>
                    </tr>
                    <tr>
                        <td className='pop-up-table-list'>③</td>
                        <td>その後、面談のZOOMアドレス等をご連絡するメールを送付しますので、面談当日にアクセスください。</td>
                    </tr>
                </table>
            </div>
        </Modal>
    );
};

export default MatchFailed;
