import React from 'react';
import { Button, Card, Col, Input, message, Modal, Row } from 'antd';
import { ApplicationDetails, selectApplicationDetails } from 'app/slice/applicationDetailsSlice';
import SpecialistDetails from './SpecialistDetails';
import { useAppSelector } from 'app/hooks';

type Props = {
    application: ApplicationDetails,
    isVisible: boolean,
    onClose: () => void,
}

const ScheduleConfirmationModal = (props: Props) => {

    const { application, isVisible, onClose } = props;
    const { data } = useAppSelector(selectApplicationDetails);
    const meetingDetails = () => {
        return (
            <div>
                <hr />
                <p>
                    <strong>日程 : <span className='datetime'>{data.scheduleDatetime!}</span></strong>
                </p>

                <Row gutter={20} align='bottom'>
                    <Col md={12} xs={24}>
                        <strong>ミーティングURL</strong>
                        <Row gutter={12}>
                            <Col md={18} xs={16}>
                                <Input value={data.joinUrl ?? ''} disabled />
                            </Col>
                            <Col md={4} xs={8}>
                                <Button onClick={() => {
                                    navigator.clipboard.writeText(data.joinUrl ?? '')
                                             .then(() => {
                                                 message.success('URLをコピーしました。');
                                             });
                                }}
                                >コピー</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} xs={24}>
                        <strong>パスワード</strong>
                        <Input value={data.password ?? ''} disabled />
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <Modal className='consultation-confirmation-modal' closable={false} visible={isVisible} footer={null}>
            <div className='title'>依頼完了</div>
            <div className='modal-note'>以下の内容で面談予約を完了いたしました。</div>
            <Card className='consultation-contents'>
                <strong>内容：</strong>
                {application.funds?.map((fund) => (
                    <div key={fund.code} className='Consultation-list-card-title'>{fund.title}</div>
                ))}
            </Card>
            <SpecialistDetails
                application={application}
                meetingDetails={meetingDetails()}
                isKoteki={true}
            />
            <div className='text-center'>
                <div className='modal-note'>
                    ※面談当日は上記の URL 、または面談管理の面談詳細画面よりミーティング URL へアクセスしてください。
                </div>
                <Button onClick={onClose} type='primary' className='w-200px'>OK</Button>
            </div>
        </Modal>
    );

};

export default ScheduleConfirmationModal;
